import { useState } from "react";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import { userApi } from "../../../api/userApi";
import { FormContainer } from "../../../components/Form/Container";
import { Form } from "../../../components/Form/Form";
import { PrimaryTitle } from "../../../components/PrimaryTitle/PrimaryTitle";
import { InputContainer } from "../../../components/InputContainer";
import { styled } from "@mui/system";
import { Eye as EyeIcon } from "../../../components/icons/Eye";

const StyledButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 10px;
  max-width: 260px;
  width: 100%;

  @media (max-width: 850px) {
    margin: 6px 0 0 0;
  }
`;

const StyledEyeWrapper = styled("div")`
  position: absolute;
  right: 6px;
  bottom: 7px;
  cursor: pointer;
  height: 22px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &::after {
    position: absolute;
    content: "";
    right: 1px;
    bottom: 10px;
    height: 1px;
    transform: rotate(45deg);
    width: 26px;
    background: var(--black);
    transition: opacity 0.3s;
    opacity: ${(props) => (props.pressed ? "1" : "0")};
  }
`;

export const LoginForm = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    setIsButtonLoading(true);
    setError(null);
    e.preventDefault();
    try {
      const data = await userApi.login({ email: formValues.email, password: formValues.password });
      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      navigate(ROUTES.main);
    } catch (error) {
      setError(error.error);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const passwordInputTypes = {
    text: "text",
    password: "password",
  };

  const [passwordInputType, setPasswordInputType] = useState(passwordInputTypes.password);

  const handleInputTypeChange = () => {
    setPasswordInputType(
      passwordInputType === passwordInputTypes.password ? passwordInputTypes.text : passwordInputTypes.password
    );
  };

  return (
    <FormContainer>
      <Form>
        <PrimaryTitle value={t("form.login")} />
        <InputContainer title={t("form.emailOrPhone.title")} error={error}>
          <Input
            type="email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            placeholder={t("form.emailOrPhone.placeholder")}
          />
        </InputContainer>
        <InputContainer title={t("form.password")}>
          <Input
            type={passwordInputType}
            name="password"
            value={formValues.password}
            onChange={handleInputChange}
            placeholder={t("form.password")}
          />
          <StyledEyeWrapper pressed={passwordInputType === passwordInputTypes.text} onClick={handleInputTypeChange}>
            <EyeIcon size={24} />
          </StyledEyeWrapper>
        </InputContainer>
        <StyledButtonContainer>
          <Button isLoading={isButtonLoading} title={t("form.login")} onClick={handleSubmit} isFilled />
          <Button title={t("button.cancel")} onClick={() => navigate(ROUTES.index)} />
        </StyledButtonContainer>
        <Link to={ROUTES.reset} style={{ color: "#185fe9" }}>
          {t("form.forgotPassword")}
        </Link>
        <div>
          {t("form.dontHaveAnAccount")}&nbsp;
          <Link to={ROUTES.signUp} style={{ color: "#185fe9" }}>
            {t("form.register")}
          </Link>
        </div>
      </Form>
    </FormContainer>
  );
};

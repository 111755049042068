export const Logout = ({ fill = "black", size = 28 }) => {
  return (
    <svg
      style={{ width: `${size}px`, height: `${size}px`, flexShrink: 0 }}
      xmlns="http://www.w3.org/2000/svg"
      id="_Шар_1"
      data-name="Шар 1"
      viewBox="0 0 167.07 177.04"
    >
      <path
        strokeWidth={0}
        fill={fill}
        d="m106.59,162.86c0,.1-.08.18-.19.18H14.19c-.1,0-.19-.08-.19-.18V14.18c0-.1.09-.18.19-.18h92.21c.11,0,.19.08.19.18v16.59h14V14.18c0-7.83-6.35-14.18-14.19-14.18H14.19C6.35,0,0,6.35,0,14.18v148.68c0,7.83,6.35,14.18,14.19,14.18h92.21c7.84,0,14.19-6.35,14.19-14.18v-16.59h-14v16.59Z"
      />
      <polygon
        strokeWidth={0}
        fill={fill}
        points="163.14 84.59 156.73 78.19 122.01 43.46 120.59 44.88 111.67 53.79 120.59 62.71 120.59 62.71 139.1 81.22 120.59 81.22 106.59 81.22 65.31 81.21 55.16 81.21 55.15 95.82 65.31 95.82 106.59 95.82 120.59 95.82 139.1 95.82 120.59 114.33 120.59 114.33 111.67 123.25 120.59 132.16 122.01 133.58 156.73 98.86 163.14 92.45 167.07 88.52 163.14 84.59"
      />
    </svg>
  );
};

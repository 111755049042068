import { useEffect, useState } from "react";
import { coursesApi } from "../../../api/coursesApi";
import { useNavigate } from "react-router-dom";

import { useCourseContext } from "../../../context/CourseContext";
import { useAuth } from "../../../context/AuthContext";
import { CreateLessonForm } from "../../Forms/CreateLessonForm";
import { useLessonContext } from "../../../context/LessonContext";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { Plus } from "../../../components/icons/Plus";
import { CreateCourseForm } from "../../Forms/CreateCourseForm";
import { EditableField } from "../../../components/EditableField";
import { Delete as IconDelete } from "../../../components/icons/Delete";
import { setSelectedCourse, setSelectedLesson } from "../../../slices/mainSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { COURSE_STATUSES, LANGUAGES, ROUTES, USER_ROLES } from "../../../constants/constants";
import { ConfirmationForm } from "../../Forms/ConfirmationForm";
import { Select } from "../../../components/Select";
import { StyledCourseItemImageContainer, StyledCoursesRow } from "../AvailableCourses/AvailableCourses";
import { Button } from "../../../components/Button";

import { AuthorManagementForm } from "../../Forms/AuthorManagementForm";
import { CourseTranslationForm, CourseTranslationManagementForm } from "../../Forms/CourseTranslationManagementForm";
import { Loader } from "../../../components/Loader";
import { InputContainer } from "../../../components/InputContainer";
import { Edit } from "../../../components/icons/Edit";
import { CourseItemEditor } from "../Editors/CourseItemEditor";

export const StyledCourseItem = styled("div")`
  padding: 10px;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0 0 10px 2px var(--content-block-shadow-color);
  position: relative;
  &:not(:last-child) {
    align-self: flex-start;
  }
  width: calc(33% - 20px);
  @media (max-width: 1100px) {
    width: calc(50% - 30px);
  }
  @media (max-width: 800px) {
    width: 100%;
  }
  height: auto;
`;

const StyledPlusButton = styled("div")`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const CoursesEditor = () => {
  const { courses, setCourses } = useCourseContext();
  const { getLessonData } = useLessonContext();
  const [isCreateCourseFormVisible, toggleCreateCourseFormVisibility] = useState(false);
  const [isCreateLessonFormVisible, toggleCreateLessonFormVisibility] = useState(false);
  const [isCourseTranslationManagementFormVisible, toggleCourseTranslationManagementFormVisibility] = useState(false);
  const { hasPriviledgedPersonEnabledEditorMode } = useAuth();
  const selectedCourse = useSelector((state) => state.selectedCourse);
  const [isConfirmationFormVisible, toggleConfirmationFormVisibility] = useState(false);
  const [courseIdToDelete, setCourseIdToDelete] = useState(null);
  const [isCourseEditorVisible, setIsCourseEditorVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAdmin } = useAuth();
  const userRole = useSelector((state) => state.userRole);

  const [courseLanguages, setCourseLanguages] = useState({});

  const [isAuthorManagementFormVisible, toggleAuthorManagementFormVisibility] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { getCourseData } = useCourseContext();

  const handleCourseDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setCourseIdToDelete(id);
    toggleConfirmationFormVisibility(true);
  };

  const fetchCourseTranslations = async () => {
    setIsLoading(true);
    try {
      let newCourseLanguagesObject = {};

      const translationPromises = courses.map(async (course) => {
        const newCourseTranslations = await coursesApi.getCourseTranslations({ course_id: course.id });
        newCourseLanguagesObject[course.id] = newCourseTranslations.map((translation) => translation.lang);
      });

      await Promise.all(translationPromises);

      console.log(newCourseLanguagesObject);
      setCourseLanguages(newCourseLanguagesObject);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (courses.length) {
      fetchCourseTranslations();
    }
  }, [courses]);

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (selectedCourse === courseIdToDelete) {
        dispatch(setSelectedCourse(null));
        dispatch(setSelectedLesson(null));
        navigate(ROUTES.study);
      }
      await coursesApi.deleteCourse(courseIdToDelete);
      await getCourseData();
      toggleConfirmationFormVisibility(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCourseChange = async ({ name, description, course_id }) => {
    const courseTranslations = await coursesApi.getCourseTranslations({ course_id });
    const targetTranslation = courseTranslations.find((translation) => translation.lang === i18n.language);
    console.log(courseTranslations);
    try {
      await coursesApi.updateCourseTranslation({
        translation_id: targetTranslation.id,
        course_id,
        name: name || targetTranslation.name,
        description: description || targetTranslation.description,
        lang: i18n.language,
        userprofile_id: [user.profile.id],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const courseStatuses = [
    { label: COURSE_STATUSES.draft, value: COURSE_STATUSES.draft },
    { label: COURSE_STATUSES.completed, value: COURSE_STATUSES.completed },
  ];

  const handleCourseStatusChange = async ({ course_id, status }) => {
    try {
      await coursesApi.updateCourseStatus({ course_id, status });
      const newCourses = [...courses];
      newCourses.find((course) => course.id === course_id).status = status;
      setCourses(newCourses);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCourseSelect = async (e, { course_id }) => {
    e.stopPropagation();
    e.preventDefault();

    const newLessons = await coursesApi.getLessons({ course_id });
    console.log(newLessons);
    const first_lesson_id = newLessons[0]?.id;
    if (first_lesson_id) {
      navigate(ROUTES.study + "/" + course_id + "/" + first_lesson_id + "/theory");
      dispatch(setSelectedCourse(course_id));
      dispatch(setSelectedLesson(first_lesson_id));
    }
    toggleCreateLessonFormVisibility(true);
  };

  const handleOpenAuthorManagement = ({ e, course_id }) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSelectedCourse(course_id));
    toggleAuthorManagementFormVisibility(true);
  };

  const handleOpenCourseTranslationManagement = ({ e, course }) => {
    if (courseLanguages[course.id].length === Object.keys(LANGUAGES).length) {
      alert(t("form.courseHasAlreadyBeenTranslatedIntoAllLanguages"));
      return;
    }

    if (!course.description?.length) {
      alert(t("form.courseDescriptionShouldNotBeEmpty"));
      return;
    }
    if (course.status !== COURSE_STATUSES.completed) {
      alert(t("form.courseStatusShouldBeSetToCompletedToManageTranslations"));
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSelectedCourse(course.id));
    toggleCourseTranslationManagementFormVisibility(true);
  };

  return (
    <>
      {isCreateLessonFormVisible && hasPriviledgedPersonEnabledEditorMode() && (
        <CreateLessonForm
          toggleCreateLessonFormVisibility={toggleCreateLessonFormVisibility}
          getLessonData={getLessonData}
        />
      )}
      {isConfirmationFormVisible && (
        <ConfirmationForm
          handleSubmit={handleDeleteSubmit}
          isLoading={isLoading}
          toggleConfirmationFormVisibility={toggleConfirmationFormVisibility}
        />
      )}
      {isCreateCourseFormVisible && (
        <CreateCourseForm
          getCourseData={getCourseData}
          toggleCreateCourseFormVisibility={toggleCreateCourseFormVisibility}
        />
      )}
      {isAuthorManagementFormVisible && (
        <AuthorManagementForm toggleAuthorManagementFormVisibility={toggleAuthorManagementFormVisibility} />
      )}
      {isCourseTranslationManagementFormVisible && (
        <CourseTranslationManagementForm
          toggleCourseTranslationManagementFormVisibility={toggleCourseTranslationManagementFormVisibility}
        />
      )}
      <StyledCoursesRow>
        {courses.map((course) => (
          <CourseItemEditor
            key={course.id}
            course={course}
            handleCourseChange={handleCourseChange}
            isAdmin={isAdmin}
            courseLanguages={courseLanguages}
            courseStatuses={courseStatuses}
            handleCourseStatusChange={handleCourseStatusChange}
            handleCourseDelete={handleCourseDelete}
            handleOpenAuthorManagement={handleOpenAuthorManagement}
            handleOpenCourseTranslationManagement={handleOpenCourseTranslationManagement}
            handleCourseSelect={handleCourseSelect}
          />
        ))}
        <StyledCourseItem>
          <StyledPlusButton onClick={() => toggleCreateCourseFormVisibility(true)}>
            <Plus size={30} />
          </StyledPlusButton>
        </StyledCourseItem>
      </StyledCoursesRow>
    </>
  );
};

import { BUTTON_STATUSES } from "../../../../constants/constants";
import React, { useRef, useState } from "react";
import { TextEditor } from "../../Editors/TextEditor";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Button";
import { styled } from "@mui/system";
import { coursesApi } from "../../../../api/coursesApi";

const StyledButtonsContainer = styled("div")`
  display: flex;
  margin-top: 12px;
  align-self: flex-start;
  width: 100%;
  justify-content: space-between;
`;

export const HomeworkEditor = ({
  editorContent,
  setEditorContent,
  currentHomeworkId,
  currentHomeworkTranslation,
  selectedCourse,
  selectedLesson,
}) => {
  const [statusText, setStatusText] = useState(`button.${BUTTON_STATUSES.save}`);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const editorRef = useRef();
  const { t, i18n } = useTranslation();
  const current_lang = i18n.language;

  const handleSave = async () => {
    setIsButtonLoading(true);
    try {
      const translation_id = currentHomeworkTranslation?.id;
      const translation_title = currentHomeworkTranslation?.title;
      await coursesApi.updateHomeworkTranslation({
        course_id: selectedCourse,
        lesson_id: selectedLesson,
        independent_work_id: currentHomeworkId,
        independent_work_translation_id: translation_id,
        lang: current_lang,
        title: translation_title,
        text: JSON.stringify(editorRef.current.getData()),
      });
      setStatusText(`button.${BUTTON_STATUSES.saved}`);
      setTimeout(() => {
        setStatusText(`button.${BUTTON_STATUSES.save}`);
      }, 2000);
      setEditorContent(editorRef.current.getData());
    } catch (err) {
      console.log(err);
      setStatusText(`button.${BUTTON_STATUSES.error}`);
      setTimeout(() => {
        setStatusText(`button.${BUTTON_STATUSES.save}`);
      }, 2000);
    } finally {
      setIsButtonLoading(false);
    }
  };

  return (
    <>
      <TextEditor
        handleSave={handleSave}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
        ref={editorRef}
      />
      <StyledButtonsContainer>
        <Button isLoading={isButtonLoading} title={t(statusText)} isFilled onClick={handleSave} />
      </StyledButtonsContainer>
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const mainSlice = createSlice({
  name: "main",
  initialState: {
    isEditorModeEnabled: false,
    isNavMenuPinned: false,
    isTextbookPinned: false,
    userRole: null,
  },
  reducers: {
    setEditorStatus: (state, action) => {
      state.isEditorModeEnabled = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setSelectedCourse: (state, action) => {
      state.selectedCourse = action.payload;
    },
    setSelectedLesson: (state, action) => {
      state.selectedLesson = action.payload;
    },
    setCourseEducationId: (state, action) => {
      state.courseEducationId = action.payload;
    },
    setLessonEducationId: (state, action) => {
      state.lessonEducationId = action.payload;
    },
    setIsNavMenuPinned: (state, action) => {
      state.isNavMenuPinned = action.payload;
    },
    setIsTextbookPinned: (state, action) => {
      state.isTextbookPinned = action.payload;
    },
  },
});

export const {
  setEditorStatus,
  setSelectedCourse,
  setSelectedLesson,
  setCourseEducationId,
  setLessonEducationId,
  setIsNavMenuPinned,
  setIsTextbookPinned,
  setUserRole,
} = mainSlice.actions;

export const persistedReducer = persistReducer(persistConfig, mainSlice.reducer);

import { useRef, useState } from "react";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { coursesApi } from "../../../api/coursesApi";
import { FormContainer } from "../../../components/Form/Container";
import { Form } from "../../../components/Form/Form";
import { PrimaryTitle } from "../../../components/PrimaryTitle";
import { Flex } from "../../../components/Flex/Flex";
import { Error } from "../../../components/Error";
import { useAuth } from "../../../context/AuthContext";
import "../Forms.css";
import { useLessonContext } from "../../../context/LessonContext";
import { styled } from "@mui/system";
import { Textarea } from "../../../components/Textarea";
import { Select } from "../../../components/Select";
import ukrainianFlag from "../../../assets/images/icons/flags/ukrainian.png";
import englishFlag from "../../../assets/images/icons/flags/english.png";
import { LANGUAGES } from "../../../constants/constants";

const StyledInputContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  textarea {
    min-height: 60px;
  }
`;

export const CreateCourseForm = ({ toggleCreateCourseFormVisibility, getCourseData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const current_lang = i18n.language;
  const formRef = useRef();
  const [courseLanguage, setCourseLanguage] = useState(i18n.language);

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const newCourse = await coursesApi.createCourse({
        name: formValues.name,
        description: formValues.description,
        lang: courseLanguage,
      });
      await getCourseData();
      await coursesApi.createLesson({
        course_id: newCourse.id,
        title: t("lesson.new"),
        sequence_id: 1,
        text: JSON.stringify(t("changeThisText")),
        lang: courseLanguage,
      });
      toggleCreateCourseFormVisibility(false); // Close the form here
    } catch (err) {
      if (err?.response?.data?.translation) {
        setErrors(err?.response?.data?.translation);
      }
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      toggleCreateCourseFormVisibility(false);
    }
  };


  const changeLang = (value) => {
    setCourseLanguage(value);
  };

  const languagesArray = [
    { value: LANGUAGES.uk, label: "Українська", icon: ukrainianFlag },
    { value: LANGUAGES.en, label: "English", icon: englishFlag },
  ];

  return (
    <FormContainer onClick={handleClickOutside} isPopup>
      <Form ref={formRef}>
        <PrimaryTitle value={t("form.courseName")} />
        <StyledInputContainer>
          <Input
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            // style={{border: error && "2px solid red"}}
            placeholder={t("course.name")}
          />
          {errors.name && <Error>{errors.name}</Error>}
        </StyledInputContainer>
        <StyledInputContainer>
          <Textarea
            name="description"
            value={formValues.description}
            onChange={handleInputChange}
            // style={{border: error && "2px solid red"}}
            placeholder={t("course.description")}
          />
          {errors.description && <Error>{errors.description}</Error>}
        </StyledInputContainer>
        <StyledInputContainer title={t("settings.siteLanguage")}>
          <Select
            options={languagesArray}
            defaultValue={languagesArray.find((item) => item.value === courseLanguage)}
            onClick={changeLang}
          />
        </StyledInputContainer>
        <Flex gap={16}>
          <Button isLoading={isLoading} title={t("button.create")} onClick={handleSubmit} isFilled />
          <Button title={t("button.cancel")} onClick={() => toggleCreateCourseFormVisibility(false)} />
        </Flex>
      </Form>
    </FormContainer>
  );
};

export const Etherium = ({ fill = "black", size = 50 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        flexShrink: 0,
      }}
      viewBox="0 0 87 87"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 43.5C0.875 19.9594 19.9594 0.875 43.5 0.875C67.0406 0.875 86.125 19.9594 86.125 43.5C86.125 67.0406 67.0406 86.125 43.5 86.125C19.9594 86.125 0.875 67.0406 0.875 43.5ZM46.6543 14.1236C46.2958 13.6213 45.8226 13.2119 45.2739 12.9294C44.7253 12.6469 44.1171 12.4995 43.5 12.4995C42.8829 12.4995 42.2747 12.6469 41.7261 12.9294C41.1774 13.2119 40.7042 13.6213 40.3457 14.1236L20.9707 41.2486C20.5017 41.9056 20.2496 42.6927 20.2496 43.5C20.2496 44.3073 20.5017 45.0944 20.9707 45.7514L40.3457 72.8764C40.7042 73.3787 41.1774 73.7881 41.7261 74.0706C42.2747 74.3531 42.8829 74.5005 43.5 74.5005C44.1171 74.5005 44.7253 74.3531 45.2739 74.0706C45.8226 73.7881 46.2958 73.3787 46.6543 72.8764L66.0292 45.7514C66.4983 45.0944 66.7504 44.3073 66.7504 43.5C66.7504 42.6927 66.4983 41.9056 66.0292 41.2486L46.6543 14.1236ZM43.5 47.0766L34.5604 43.5L43.5 39.9234L52.4396 43.5L43.5 47.0766ZM44.9376 54.846L52.0366 52.0095L43.5 63.9561L34.9634 52.0095L42.0624 54.846C42.9853 55.2147 44.0147 55.2147 44.9376 54.846ZM43.5 23.04L34.9634 34.9905L42.0624 32.154C42.9853 31.7853 44.0147 31.7853 44.9376 32.154L52.0366 34.9905L43.5 23.04Z"
        fill={fill}
      />
    </svg>
  );
};

export const Plus = ({size = 24, fill = "black"}) => {
  return (
    <svg style={{
      width: `${size}px`,
      height: `${size}px`
    }} id="_Шар_1" data-name="Шар 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.83 159.83">
      <path strokeWidth={0} fill={fill}
            d="m145.83,0H14.01C6.27,0,0,6.27,0,14v131.82c0,7.74,6.27,14.01,14.01,14.01h131.82c7.73,0,14-6.27,14-14.01V14c0-7.73-6.27-14-14-14Zm0,145.83H14s.01-131.83.01-131.83h131.82v131.83Z"/>
      <polygon strokeWidth={0} fill={fill}
               points="72.92 125.03 86.92 125.03 86.92 86.91 125.04 86.91 125.04 72.91 86.92 72.91 86.92 34.79 72.92 34.79 72.92 72.91 34.8 72.91 34.8 86.91 72.92 86.91 72.92 125.03"/>
    </svg>
  )
}
import { styled } from "@mui/system";

const StyledError = styled("p")`
  color: red;
  font-size: 0.8rem;
  width: 100%;
  background: rgb(181, 0, 0, 0.2);
  padding: 5px 8px;
  border-radius: calc(var(--border-radius) / 2);
  font-weight: 500;
`;

export const Error = ({ children }) => {
  return <StyledError>{children}</StyledError>;
};

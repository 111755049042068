import { styled } from "@mui/system";

const StyledCenteredContainer = styled('div')`
  display: flex;
  min-height: 100vh;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const CenteredContainer = ({children}) => {
  return <StyledCenteredContainer>{children}</StyledCenteredContainer>;
};

export const Pin = ({ fill = "black", size = 30 }) => {
  return (
    <svg
      style={{ width: `${size}px`, height: `${size}px`, flexShrink: 0, cursor: "pointer" }}
      id="_Шар_1"
      data-name="Шар 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 159.83 159.83"
    >
      <path
        strokeWidth={0}
        fill={fill}
        d="m79.91,0C35.77,0,0,35.77,0,79.91s35.77,79.92,79.91,79.92,79.92-35.79,79.92-79.92S124.05,0,79.91,0Zm0,145.83c-36.34,0-65.91-29.57-65.91-65.92S43.57,14,79.91,14s65.92,29.57,65.92,65.91-29.57,65.92-65.92,65.92Z"
      />
      <path
        strokeWidth={0}
        fill={fill}
        d="m83.29,40.6l8.02,8.02-12.09,12.09c-12.05-4.49-26.14-1.9-35.83,7.78l19.06,19.06-23.77,31.29,32.51-22.56,20.14,20.14c9.69-9.69,12.28-23.78,7.78-35.83l12.09-12.09,8.02,8.02,10.43-10.43-35.94-35.94-10.43,10.43Z"
      />
    </svg>
  );
};

import React, { useEffect, useState } from "react";
import { Button } from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import { Delete } from "../../../../components/icons/Delete";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/system";
import { PlusButton } from "../../../../components/PlusButton";
import { coursesApi } from "../../../../api/coursesApi";
import { Dropdown as IconDropdown } from "../../../../components/icons/Dropdown";
import { ConfirmationForm } from "../../../Forms/ConfirmationForm";
import { AnswersBlock } from "../../AnswersBlock";
import { EditableField } from "../../../../components/EditableField";

const StyledContainer = styled("div")`
  padding: 24px;
  border-radius: var(--border-radius);
  background: var(--white);
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 5px 2px var(--content-block-shadow-color);
  user-select: none;
  transition: 0.3s;

  &:hover {
    box-shadow: ${(props) =>
      !props.isAnswersListVisible &&
      "0 0 5px 2px var(--content-block-shadow-color), inset 0 0" + " 4px 1px var(--input-shadow-color)"};
    background: ${(props) => !props.isAnswersListVisible && "#f1f4f8"};
  }
`;

const StyledLine = styled("div")`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

const StyledButtons = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  margin-top: 5px;
`;

const StyledTitle = styled("div")`
  font-weight: 500;
  padding: 6px 0;
  margin-bottom: 8px;
  width: 100%;
`;

const StyledText = styled("div")`
  padding: 7px 0;
  width: 100%;
  cursor: pointer;
`;

const StyledButtonsContainer = styled("div")`
  margin-top: 12px;
  display: flex;
  gap: 16px;
`;

const StyledError = styled("div")`
  color: red;
  margin-top: 2px;
  margin-bottom: 5px;
  font-size: 14px;
`;

const StyledDropdownIconContainer = styled("div")`
  transform: ${(props) => (props.expanded ? "rotate(0deg)" : "rotate(180deg)")};
  width: 20px;
  height: 20px;
`;

const StyledDeleteButtonContainer = styled("div")`
  position: relative;
  z-index: 5;
`;

export const QuestionEditor = ({
  answers,
  setAnswers,
  question_id,
  initialAnswers,
  initialQuestion,
  course_id,
  lesson_id,
  questions,
  setQuestions,
}) => {
  const [question, setQuestion] = useState(initialQuestion);
  const [editorAnswers, setEditorAnswers] = useState(answers[question_id]);

  const { t, i18n } = useTranslation();
  const current_lang = i18n.language;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isAnswersListVisible, setIsAnswersListVisible] = useState(false);
  const [formType, setFormType] = useState(null);
  const [isConfirmDeleteFormVisible, toggleConfirmDeleteFormVisibility] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [selectedAnswerId, setSelectedAnswerId] = useState(null);

  const handleQuestionTextChange = (value) => {
    setQuestion(value);
  };

  const handleAnswerTextChange = (value, id, is_new) => {
    const newAnswers = [...editorAnswers];
    const index = newAnswers.findIndex((answer) => answer.id === id);
    newAnswers[index].text = value;
    if (!is_new) {
      newAnswers[index].is_edited = true;
    }
    setEditorAnswers(newAnswers);
  };

  const handleCheckboxClick = (id) => {
    const newAnswers = [...editorAnswers];
    const index = newAnswers.findIndex((answer) => answer.id === id);
    newAnswers[index].is_correct = !newAnswers[index].is_correct;
    if (!newAnswers[index].is_edited) {
      newAnswers[index].is_edited = true;
    }
    setEditorAnswers(newAnswers);
  };

  const handleSave = async () => {
    if (editorAnswers.length >= 2) {
      setIsButtonLoading(true);
      try {
        if (initialQuestion !== question) {
          const questionTranslations = await coursesApi.getQuestionTranslations({
            course_id,
            lesson_id,
            question_id,
          });
          const targetQuestionTranslationId = questionTranslations?.find(
            (translation) => translation.lang === current_lang
          ).id;
          console.log(questionTranslations);
          await coursesApi.updateQuestionTranslation({
            lesson_id,
            course_id,
            translation_id: targetQuestionTranslationId,
            text: question,
            question_id,
            lang: current_lang,
          });
        }
        const newAnswers = editorAnswers.filter((answer) => answer.is_new);
        const newAnswerPromises = newAnswers.map(async (answer) => {
          await coursesApi.addAnswer({
            course_id,
            lesson_id,
            question_id,
            lang: current_lang,
            text: answer.text,
            is_correct: answer.is_correct,
          });
        });
        await Promise.all(newAnswerPromises);
        const updatedAnswers = editorAnswers.filter((answer) => answer.is_edited);
        console.log(updatedAnswers);
        const updatedAnswerPromises = updatedAnswers.map(async (answer) => {
          const answerTranslations = await coursesApi.getAnswerTranslations({
            course_id,
            lesson_id,
            question_id,
            answer_id: answer.id,
          });
          const targetAnswerTranslationId = answerTranslations?.find(
            (translation) => translation.lang === current_lang
          ).id;
          console.log(targetAnswerTranslationId);

          await coursesApi.updateAnswerTranslation({
            course_id,
            lesson_id,
            question_id,
            answer_id: answer.id,
            translation_id: targetAnswerTranslationId,
            text: answer.text,
            lang: current_lang,
          });
          await coursesApi.updateAnswer({
            course_id,
            lesson_id,
            question_id,
            answer_id: answer.id,
            is_correct: answer.is_correct,
          });
        });
        await Promise.all(updatedAnswerPromises);
        setAnswers((prev) => ({ ...prev, [question_id]: editorAnswers }));
        setError(null);
        setIsAnswersListVisible(false);
      } catch (err) {
        console.log(err);
      } finally {
        setIsButtonLoading(false);
      }
    } else {
      setError(t(`lesson.tests.theAmountOfAnswersShouldBeEqualOrMoreThanTwo`));
    }
  };

  const TYPES = {
    answer: "answer",
    question: "question",
  };

  const handleDelete = (event, { type, question_id, answer_id }) => {
    event.preventDefault();
    event.stopPropagation();
    setFormType(type);
    if (type === TYPES.answer) {
      setSelectedQuestionId(question_id);
      setSelectedAnswerId(answer_id);
    } else if (type === TYPES.question) {
      setSelectedQuestionId(question_id);
    }
    toggleConfirmDeleteFormVisibility(true);
  };

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    try {
      if (formType === TYPES.question) {
        await coursesApi.deleteQuestion({
          course_id: course_id,
          lesson_id: lesson_id,
          question_id: selectedQuestionId,
        });
        const newQuestions = questions.filter((question) => question.id !== selectedQuestionId);
        setQuestions(newQuestions);
      } else if (formType === TYPES.answer) {
        const current_answer = editorAnswers.find((answer) => answer.id === selectedAnswerId);
        if (!current_answer.is_new) {
          await coursesApi.deleteAnswer({
            course_id,
            lesson_id,
            question_id: selectedQuestionId,
            answer_id: selectedAnswerId,
          });
        }
        const newAnswers = editorAnswers.filter((answer) => answer.id !== selectedAnswerId);
        setAnswers(newAnswers);
      }
      toggleConfirmDeleteFormVisibility(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const handleAddAnswer = () => {
    setEditorAnswers((prev) => [...prev, { text: "new answer", is_correct: false, id: uuidv4(), is_new: true }]);
  };

  return (
    <>
      {isConfirmDeleteFormVisible && (
        <ConfirmationForm
          handleSubmit={handleDeleteSubmit}
          toggleConfirmationFormVisibility={toggleConfirmDeleteFormVisibility}
          isLoading={isButtonLoading}
        />
      )}
      <StyledContainer isAnswersListVisible={isAnswersListVisible}>
        <StyledLine>
          {isAnswersListVisible ? (
            <StyledTitle onClick={() => setIsAnswersListVisible(false)}>
              {t(`lesson.tests.question.title`)}:
            </StyledTitle>
          ) : (
            <StyledText onClick={() => setIsAnswersListVisible(true)}>{question}</StyledText>
          )}
          <StyledButtons>
            <StyledDropdownIconContainer
              expanded={isAnswersListVisible}
              onClick={() => setIsAnswersListVisible(!isAnswersListVisible)}
            >
              <IconDropdown />
            </StyledDropdownIconContainer>

            <StyledDeleteButtonContainer>
              <Delete onClick={(event) => handleDelete(event, { question_id, type: TYPES.question })} />
            </StyledDeleteButtonContainer>
          </StyledButtons>
        </StyledLine>
        {isAnswersListVisible && (
          <>
            <EditableField onTextChange={handleQuestionTextChange} initialText={question} />
            <StyledTitle>{t(`lesson.tests.answer.answers`)}:</StyledTitle>
            {editorAnswers?.map((answer) => (
              <AnswersBlock
                key={answer.id}
                handleCheckboxClick={() => handleCheckboxClick(answer.id)}
                handleAnswerTextChange={(value) => handleAnswerTextChange(value, answer.id, answer.is_new)}
                answer={answer}
                handleAnswerDelete={(event) =>
                  handleDelete(event, {
                    question_id,
                    answer_id: answer.id,
                    type: TYPES.answer,
                  })
                }
              />
            ))}
            <PlusButton title={t("lesson.tests.answer.add")} onClick={handleAddAnswer} />
            {error && <StyledError>{error}</StyledError>}
            <StyledButtonsContainer>
              <Button onClick={handleSave} title={t(`button.save`)} isLoading={isButtonLoading} isFilled />
              <Button onClick={() => setIsAnswersListVisible(false)} title={t(`button.cancel`)} />
            </StyledButtonsContainer>
          </>
        )}
      </StyledContainer>
    </>
  );
};

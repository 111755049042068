import { styled } from "@mui/system";

const StyledButton = styled("button")`
  color: ${(props) => (props.isFilled ? "var(--white)" : "var(--black)")};
  padding: 15px 30px;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  min-width: ${(props) => (props.minWidthUnset ? "unset" : "200px")};
  transition:
    box-shadow 0.2s,
    background 0.2s;
  background: ${(props) => (props.isFilled ? "var(--blue-dark)" : "var(--white)")};
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 10px 2px var(--content-block-shadow-color);

  @media (max-width: 1100px) {
    padding: 12px 25px;
    min-width: ${(props) => (props.minWidthUnset ? "unset" : "170px")};
    font-size: 0.9rem;
  }

  @media (max-width: 850px) {
    min-width: unset;
  }

  &:not(:disabled):hover {
    box-shadow: ${(props) =>
      props.isFilled
        ? "0 0 2px 2px var(--content-block-shadow-color), inset 0 0 2px 1px var(--white)"
        : "0 0 2px 2px var(--content-block-shadow-color), inset 0 0 2px 1px var(--input-shadow-color)"};
  }

  &:disabled {
    color: var(--black);
    background: #cedbe6;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Loader = styled("div")`
  position: absolute;
  top: 0;
  left: -30px;
  border: 2px solid ${(props) => (props.isFilled ? "var(--blue-dark)" : "var(--white)")};
  border-bottom: 2px solid transparent;
  opacity: ${(props) => (props.isLoading ? "1" : "0")};
  transform: ${(props) => (props.isLoading ? "scale(1)" : "scale(0)")};
  transition:
    opacity 0.3s,
    transform 0.3s;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spinButtonLoader 1s linear infinite;
  @media (max-width: 850px) {
    display: none;
  }

  @keyframes spinButtonLoader {
    0% {
      transform: rotate(0deg) scale(1);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
`;

const StyledButtonText = styled("div")`
  position: relative;
`;

export const Button = ({ onClick, title, isLoading, isFilled, disabled, minWidthUnset }) => {
  return (
    <StyledButton isFilled={isFilled} onClick={onClick} disabled={disabled} minWidthUnset={minWidthUnset}>
      <StyledButtonText>
        {title}
        {isLoading && <Loader isLoading={isLoading} />}
      </StyledButtonText>
    </StyledButton>
  );
};

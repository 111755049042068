export const TetherQR = ({ size = 50 }) => {
  return (
    <svg
      style={{
        width: `${size}px`,
        height: `${size}px`,
        flexShrink: 0,
      }}
      viewBox="0 0 328 328"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0" y="0" width="328" height="328" fill="#ffffff" />
      <defs>
        <rect id="p" width="8" height="8" />
      </defs>
      <g fill="#000000">
        <use href="#p" x="32" y="32" />
        <use href="#p" x="32" y="40" />
        <use href="#p" x="32" y="48" />
        <use href="#p" x="32" y="56" />
        <use href="#p" x="32" y="64" />
        <use href="#p" x="32" y="72" />
        <use href="#p" x="32" y="80" />
        <use href="#p" x="32" y="120" />
        <use href="#p" x="32" y="128" />
        <use href="#p" x="32" y="152" />
        <use href="#p" x="32" y="160" />
        <use href="#p" x="32" y="168" />
        <use href="#p" x="32" y="176" />
        <use href="#p" x="32" y="184" />
        <use href="#p" x="32" y="192" />
        <use href="#p" x="32" y="200" />
        <use href="#p" x="32" y="216" />
        <use href="#p" x="32" y="240" />
        <use href="#p" x="32" y="248" />
        <use href="#p" x="32" y="256" />
        <use href="#p" x="32" y="264" />
        <use href="#p" x="32" y="272" />
        <use href="#p" x="32" y="280" />
        <use href="#p" x="32" y="288" />
        <use href="#p" x="40" y="32" />
        <use href="#p" x="40" y="80" />
        <use href="#p" x="40" y="104" />
        <use href="#p" x="40" y="112" />
        <use href="#p" x="40" y="152" />
        <use href="#p" x="40" y="160" />
        <use href="#p" x="40" y="184" />
        <use href="#p" x="40" y="216" />
        <use href="#p" x="40" y="240" />
        <use href="#p" x="40" y="288" />
        <use href="#p" x="48" y="32" />
        <use href="#p" x="48" y="48" />
        <use href="#p" x="48" y="56" />
        <use href="#p" x="48" y="64" />
        <use href="#p" x="48" y="80" />
        <use href="#p" x="48" y="96" />
        <use href="#p" x="48" y="104" />
        <use href="#p" x="48" y="112" />
        <use href="#p" x="48" y="128" />
        <use href="#p" x="48" y="144" />
        <use href="#p" x="48" y="152" />
        <use href="#p" x="48" y="184" />
        <use href="#p" x="48" y="192" />
        <use href="#p" x="48" y="208" />
        <use href="#p" x="48" y="240" />
        <use href="#p" x="48" y="256" />
        <use href="#p" x="48" y="264" />
        <use href="#p" x="48" y="272" />
        <use href="#p" x="48" y="288" />
        <use href="#p" x="56" y="32" />
        <use href="#p" x="56" y="48" />
        <use href="#p" x="56" y="56" />
        <use href="#p" x="56" y="64" />
        <use href="#p" x="56" y="80" />
        <use href="#p" x="56" y="104" />
        <use href="#p" x="56" y="120" />
        <use href="#p" x="56" y="136" />
        <use href="#p" x="56" y="168" />
        <use href="#p" x="56" y="176" />
        <use href="#p" x="56" y="184" />
        <use href="#p" x="56" y="192" />
        <use href="#p" x="56" y="208" />
        <use href="#p" x="56" y="240" />
        <use href="#p" x="56" y="256" />
        <use href="#p" x="56" y="264" />
        <use href="#p" x="56" y="272" />
        <use href="#p" x="56" y="288" />
        <use href="#p" x="64" y="32" />
        <use href="#p" x="64" y="48" />
        <use href="#p" x="64" y="56" />
        <use href="#p" x="64" y="64" />
        <use href="#p" x="64" y="80" />
        <use href="#p" x="64" y="104" />
        <use href="#p" x="64" y="120" />
        <use href="#p" x="64" y="128" />
        <use href="#p" x="64" y="152" />
        <use href="#p" x="64" y="160" />
        <use href="#p" x="64" y="168" />
        <use href="#p" x="64" y="184" />
        <use href="#p" x="64" y="200" />
        <use href="#p" x="64" y="224" />
        <use href="#p" x="64" y="240" />
        <use href="#p" x="64" y="256" />
        <use href="#p" x="64" y="264" />
        <use href="#p" x="64" y="272" />
        <use href="#p" x="64" y="288" />
        <use href="#p" x="72" y="32" />
        <use href="#p" x="72" y="80" />
        <use href="#p" x="72" y="96" />
        <use href="#p" x="72" y="104" />
        <use href="#p" x="72" y="120" />
        <use href="#p" x="72" y="136" />
        <use href="#p" x="72" y="160" />
        <use href="#p" x="72" y="168" />
        <use href="#p" x="72" y="176" />
        <use href="#p" x="72" y="192" />
        <use href="#p" x="72" y="240" />
        <use href="#p" x="72" y="288" />
        <use href="#p" x="80" y="32" />
        <use href="#p" x="80" y="40" />
        <use href="#p" x="80" y="48" />
        <use href="#p" x="80" y="56" />
        <use href="#p" x="80" y="64" />
        <use href="#p" x="80" y="72" />
        <use href="#p" x="80" y="80" />
        <use href="#p" x="80" y="96" />
        <use href="#p" x="80" y="112" />
        <use href="#p" x="80" y="128" />
        <use href="#p" x="80" y="144" />
        <use href="#p" x="80" y="160" />
        <use href="#p" x="80" y="176" />
        <use href="#p" x="80" y="192" />
        <use href="#p" x="80" y="208" />
        <use href="#p" x="80" y="224" />
        <use href="#p" x="80" y="240" />
        <use href="#p" x="80" y="248" />
        <use href="#p" x="80" y="256" />
        <use href="#p" x="80" y="264" />
        <use href="#p" x="80" y="272" />
        <use href="#p" x="80" y="280" />
        <use href="#p" x="80" y="288" />
        <use href="#p" x="88" y="96" />
        <use href="#p" x="88" y="112" />
        <use href="#p" x="88" y="120" />
        <use href="#p" x="88" y="128" />
        <use href="#p" x="88" y="144" />
        <use href="#p" x="88" y="152" />
        <use href="#p" x="88" y="176" />
        <use href="#p" x="88" y="184" />
        <use href="#p" x="88" y="192" />
        <use href="#p" x="88" y="208" />
        <use href="#p" x="88" y="216" />
        <use href="#p" x="88" y="224" />
        <use href="#p" x="96" y="40" />
        <use href="#p" x="96" y="48" />
        <use href="#p" x="96" y="56" />
        <use href="#p" x="96" y="64" />
        <use href="#p" x="96" y="72" />
        <use href="#p" x="96" y="80" />
        <use href="#p" x="96" y="96" />
        <use href="#p" x="96" y="112" />
        <use href="#p" x="96" y="120" />
        <use href="#p" x="96" y="128" />
        <use href="#p" x="96" y="136" />
        <use href="#p" x="96" y="152" />
        <use href="#p" x="96" y="168" />
        <use href="#p" x="96" y="176" />
        <use href="#p" x="96" y="184" />
        <use href="#p" x="96" y="192" />
        <use href="#p" x="96" y="200" />
        <use href="#p" x="96" y="232" />
        <use href="#p" x="96" y="240" />
        <use href="#p" x="96" y="248" />
        <use href="#p" x="96" y="272" />
        <use href="#p" x="104" y="32" />
        <use href="#p" x="104" y="40" />
        <use href="#p" x="104" y="48" />
        <use href="#p" x="104" y="72" />
        <use href="#p" x="104" y="88" />
        <use href="#p" x="104" y="96" />
        <use href="#p" x="104" y="120" />
        <use href="#p" x="104" y="128" />
        <use href="#p" x="104" y="144" />
        <use href="#p" x="104" y="152" />
        <use href="#p" x="104" y="160" />
        <use href="#p" x="104" y="176" />
        <use href="#p" x="104" y="184" />
        <use href="#p" x="104" y="200" />
        <use href="#p" x="104" y="224" />
        <use href="#p" x="104" y="232" />
        <use href="#p" x="104" y="256" />
        <use href="#p" x="104" y="280" />
        <use href="#p" x="104" y="288" />
        <use href="#p" x="112" y="32" />
        <use href="#p" x="112" y="40" />
        <use href="#p" x="112" y="80" />
        <use href="#p" x="112" y="96" />
        <use href="#p" x="112" y="112" />
        <use href="#p" x="112" y="120" />
        <use href="#p" x="112" y="128" />
        <use href="#p" x="112" y="136" />
        <use href="#p" x="112" y="144" />
        <use href="#p" x="112" y="168" />
        <use href="#p" x="112" y="176" />
        <use href="#p" x="112" y="200" />
        <use href="#p" x="112" y="208" />
        <use href="#p" x="112" y="216" />
        <use href="#p" x="112" y="256" />
        <use href="#p" x="112" y="272" />
        <use href="#p" x="112" y="288" />
        <use href="#p" x="120" y="40" />
        <use href="#p" x="120" y="56" />
        <use href="#p" x="120" y="88" />
        <use href="#p" x="120" y="128" />
        <use href="#p" x="120" y="136" />
        <use href="#p" x="120" y="152" />
        <use href="#p" x="120" y="160" />
        <use href="#p" x="120" y="168" />
        <use href="#p" x="120" y="208" />
        <use href="#p" x="120" y="232" />
        <use href="#p" x="120" y="248" />
        <use href="#p" x="120" y="256" />
        <use href="#p" x="120" y="264" />
        <use href="#p" x="120" y="272" />
        <use href="#p" x="128" y="32" />
        <use href="#p" x="128" y="40" />
        <use href="#p" x="128" y="48" />
        <use href="#p" x="128" y="72" />
        <use href="#p" x="128" y="80" />
        <use href="#p" x="128" y="104" />
        <use href="#p" x="128" y="112" />
        <use href="#p" x="128" y="144" />
        <use href="#p" x="128" y="152" />
        <use href="#p" x="128" y="160" />
        <use href="#p" x="128" y="184" />
        <use href="#p" x="128" y="200" />
        <use href="#p" x="128" y="208" />
        <use href="#p" x="128" y="216" />
        <use href="#p" x="128" y="248" />
        <use href="#p" x="128" y="264" />
        <use href="#p" x="128" y="288" />
        <use href="#p" x="136" y="32" />
        <use href="#p" x="136" y="48" />
        <use href="#p" x="136" y="56" />
        <use href="#p" x="136" y="64" />
        <use href="#p" x="136" y="72" />
        <use href="#p" x="136" y="96" />
        <use href="#p" x="136" y="104" />
        <use href="#p" x="136" y="112" />
        <use href="#p" x="136" y="120" />
        <use href="#p" x="136" y="128" />
        <use href="#p" x="136" y="152" />
        <use href="#p" x="136" y="160" />
        <use href="#p" x="136" y="192" />
        <use href="#p" x="136" y="200" />
        <use href="#p" x="136" y="216" />
        <use href="#p" x="136" y="232" />
        <use href="#p" x="136" y="248" />
        <use href="#p" x="136" y="256" />
        <use href="#p" x="136" y="264" />
        <use href="#p" x="144" y="32" />
        <use href="#p" x="144" y="40" />
        <use href="#p" x="144" y="48" />
        <use href="#p" x="144" y="64" />
        <use href="#p" x="144" y="80" />
        <use href="#p" x="144" y="88" />
        <use href="#p" x="144" y="120" />
        <use href="#p" x="144" y="128" />
        <use href="#p" x="144" y="152" />
        <use href="#p" x="144" y="160" />
        <use href="#p" x="144" y="168" />
        <use href="#p" x="144" y="224" />
        <use href="#p" x="144" y="232" />
        <use href="#p" x="144" y="248" />
        <use href="#p" x="144" y="256" />
        <use href="#p" x="144" y="264" />
        <use href="#p" x="144" y="272" />
        <use href="#p" x="144" y="288" />
        <use href="#p" x="152" y="32" />
        <use href="#p" x="152" y="88" />
        <use href="#p" x="152" y="96" />
        <use href="#p" x="152" y="112" />
        <use href="#p" x="152" y="120" />
        <use href="#p" x="152" y="136" />
        <use href="#p" x="152" y="144" />
        <use href="#p" x="152" y="160" />
        <use href="#p" x="152" y="168" />
        <use href="#p" x="152" y="184" />
        <use href="#p" x="152" y="192" />
        <use href="#p" x="152" y="216" />
        <use href="#p" x="152" y="232" />
        <use href="#p" x="152" y="248" />
        <use href="#p" x="152" y="264" />
        <use href="#p" x="152" y="272" />
        <use href="#p" x="152" y="280" />
        <use href="#p" x="152" y="288" />
        <use href="#p" x="160" y="32" />
        <use href="#p" x="160" y="48" />
        <use href="#p" x="160" y="56" />
        <use href="#p" x="160" y="72" />
        <use href="#p" x="160" y="80" />
        <use href="#p" x="160" y="112" />
        <use href="#p" x="160" y="152" />
        <use href="#p" x="160" y="176" />
        <use href="#p" x="160" y="184" />
        <use href="#p" x="160" y="192" />
        <use href="#p" x="160" y="200" />
        <use href="#p" x="160" y="208" />
        <use href="#p" x="160" y="216" />
        <use href="#p" x="160" y="240" />
        <use href="#p" x="160" y="248" />
        <use href="#p" x="160" y="256" />
        <use href="#p" x="160" y="288" />
        <use href="#p" x="168" y="32" />
        <use href="#p" x="168" y="40" />
        <use href="#p" x="168" y="64" />
        <use href="#p" x="168" y="72" />
        <use href="#p" x="168" y="96" />
        <use href="#p" x="168" y="104" />
        <use href="#p" x="168" y="112" />
        <use href="#p" x="168" y="136" />
        <use href="#p" x="168" y="144" />
        <use href="#p" x="168" y="152" />
        <use href="#p" x="168" y="160" />
        <use href="#p" x="168" y="200" />
        <use href="#p" x="168" y="224" />
        <use href="#p" x="168" y="232" />
        <use href="#p" x="168" y="240" />
        <use href="#p" x="168" y="256" />
        <use href="#p" x="168" y="264" />
        <use href="#p" x="168" y="280" />
        <use href="#p" x="168" y="288" />
        <use href="#p" x="176" y="40" />
        <use href="#p" x="176" y="72" />
        <use href="#p" x="176" y="80" />
        <use href="#p" x="176" y="96" />
        <use href="#p" x="176" y="104" />
        <use href="#p" x="176" y="112" />
        <use href="#p" x="176" y="144" />
        <use href="#p" x="176" y="184" />
        <use href="#p" x="176" y="192" />
        <use href="#p" x="176" y="200" />
        <use href="#p" x="176" y="216" />
        <use href="#p" x="176" y="224" />
        <use href="#p" x="176" y="232" />
        <use href="#p" x="176" y="240" />
        <use href="#p" x="176" y="248" />
        <use href="#p" x="176" y="264" />
        <use href="#p" x="184" y="40" />
        <use href="#p" x="184" y="48" />
        <use href="#p" x="184" y="56" />
        <use href="#p" x="184" y="64" />
        <use href="#p" x="184" y="144" />
        <use href="#p" x="184" y="176" />
        <use href="#p" x="184" y="184" />
        <use href="#p" x="184" y="200" />
        <use href="#p" x="184" y="208" />
        <use href="#p" x="184" y="216" />
        <use href="#p" x="184" y="224" />
        <use href="#p" x="184" y="232" />
        <use href="#p" x="184" y="264" />
        <use href="#p" x="184" y="280" />
        <use href="#p" x="192" y="48" />
        <use href="#p" x="192" y="64" />
        <use href="#p" x="192" y="72" />
        <use href="#p" x="192" y="80" />
        <use href="#p" x="192" y="104" />
        <use href="#p" x="192" y="112" />
        <use href="#p" x="192" y="120" />
        <use href="#p" x="192" y="128" />
        <use href="#p" x="192" y="152" />
        <use href="#p" x="192" y="160" />
        <use href="#p" x="192" y="168" />
        <use href="#p" x="192" y="184" />
        <use href="#p" x="192" y="192" />
        <use href="#p" x="192" y="200" />
        <use href="#p" x="192" y="208" />
        <use href="#p" x="192" y="216" />
        <use href="#p" x="192" y="232" />
        <use href="#p" x="192" y="240" />
        <use href="#p" x="192" y="248" />
        <use href="#p" x="192" y="256" />
        <use href="#p" x="192" y="272" />
        <use href="#p" x="192" y="288" />
        <use href="#p" x="200" y="32" />
        <use href="#p" x="200" y="48" />
        <use href="#p" x="200" y="64" />
        <use href="#p" x="200" y="96" />
        <use href="#p" x="200" y="112" />
        <use href="#p" x="200" y="128" />
        <use href="#p" x="200" y="168" />
        <use href="#p" x="200" y="192" />
        <use href="#p" x="200" y="232" />
        <use href="#p" x="200" y="240" />
        <use href="#p" x="200" y="256" />
        <use href="#p" x="200" y="272" />
        <use href="#p" x="200" y="280" />
        <use href="#p" x="200" y="288" />
        <use href="#p" x="208" y="32" />
        <use href="#p" x="208" y="40" />
        <use href="#p" x="208" y="48" />
        <use href="#p" x="208" y="56" />
        <use href="#p" x="208" y="64" />
        <use href="#p" x="208" y="72" />
        <use href="#p" x="208" y="80" />
        <use href="#p" x="208" y="88" />
        <use href="#p" x="208" y="112" />
        <use href="#p" x="208" y="120" />
        <use href="#p" x="208" y="144" />
        <use href="#p" x="208" y="176" />
        <use href="#p" x="208" y="240" />
        <use href="#p" x="208" y="256" />
        <use href="#p" x="208" y="272" />
        <use href="#p" x="216" y="48" />
        <use href="#p" x="216" y="56" />
        <use href="#p" x="216" y="96" />
        <use href="#p" x="216" y="112" />
        <use href="#p" x="216" y="120" />
        <use href="#p" x="216" y="136" />
        <use href="#p" x="216" y="144" />
        <use href="#p" x="216" y="152" />
        <use href="#p" x="216" y="160" />
        <use href="#p" x="216" y="176" />
        <use href="#p" x="216" y="184" />
        <use href="#p" x="216" y="192" />
        <use href="#p" x="216" y="200" />
        <use href="#p" x="216" y="208" />
        <use href="#p" x="216" y="216" />
        <use href="#p" x="216" y="248" />
        <use href="#p" x="216" y="256" />
        <use href="#p" x="216" y="264" />
        <use href="#p" x="216" y="272" />
        <use href="#p" x="216" y="280" />
        <use href="#p" x="224" y="48" />
        <use href="#p" x="224" y="56" />
        <use href="#p" x="224" y="64" />
        <use href="#p" x="224" y="80" />
        <use href="#p" x="224" y="88" />
        <use href="#p" x="224" y="96" />
        <use href="#p" x="224" y="104" />
        <use href="#p" x="224" y="112" />
        <use href="#p" x="224" y="160" />
        <use href="#p" x="224" y="168" />
        <use href="#p" x="224" y="184" />
        <use href="#p" x="224" y="192" />
        <use href="#p" x="224" y="200" />
        <use href="#p" x="224" y="208" />
        <use href="#p" x="224" y="224" />
        <use href="#p" x="224" y="232" />
        <use href="#p" x="224" y="240" />
        <use href="#p" x="224" y="248" />
        <use href="#p" x="224" y="256" />
        <use href="#p" x="224" y="288" />
        <use href="#p" x="232" y="96" />
        <use href="#p" x="232" y="136" />
        <use href="#p" x="232" y="144" />
        <use href="#p" x="232" y="168" />
        <use href="#p" x="232" y="184" />
        <use href="#p" x="232" y="200" />
        <use href="#p" x="232" y="208" />
        <use href="#p" x="232" y="224" />
        <use href="#p" x="232" y="256" />
        <use href="#p" x="232" y="264" />
        <use href="#p" x="232" y="272" />
        <use href="#p" x="232" y="288" />
        <use href="#p" x="240" y="32" />
        <use href="#p" x="240" y="40" />
        <use href="#p" x="240" y="48" />
        <use href="#p" x="240" y="56" />
        <use href="#p" x="240" y="64" />
        <use href="#p" x="240" y="72" />
        <use href="#p" x="240" y="80" />
        <use href="#p" x="240" y="112" />
        <use href="#p" x="240" y="128" />
        <use href="#p" x="240" y="152" />
        <use href="#p" x="240" y="160" />
        <use href="#p" x="240" y="192" />
        <use href="#p" x="240" y="200" />
        <use href="#p" x="240" y="208" />
        <use href="#p" x="240" y="216" />
        <use href="#p" x="240" y="224" />
        <use href="#p" x="240" y="240" />
        <use href="#p" x="240" y="256" />
        <use href="#p" x="240" y="272" />
        <use href="#p" x="240" y="280" />
        <use href="#p" x="240" y="288" />
        <use href="#p" x="248" y="32" />
        <use href="#p" x="248" y="80" />
        <use href="#p" x="248" y="96" />
        <use href="#p" x="248" y="104" />
        <use href="#p" x="248" y="128" />
        <use href="#p" x="248" y="136" />
        <use href="#p" x="248" y="144" />
        <use href="#p" x="248" y="160" />
        <use href="#p" x="248" y="168" />
        <use href="#p" x="248" y="176" />
        <use href="#p" x="248" y="192" />
        <use href="#p" x="248" y="200" />
        <use href="#p" x="248" y="216" />
        <use href="#p" x="248" y="224" />
        <use href="#p" x="248" y="256" />
        <use href="#p" x="248" y="264" />
        <use href="#p" x="248" y="280" />
        <use href="#p" x="256" y="32" />
        <use href="#p" x="256" y="48" />
        <use href="#p" x="256" y="56" />
        <use href="#p" x="256" y="64" />
        <use href="#p" x="256" y="80" />
        <use href="#p" x="256" y="96" />
        <use href="#p" x="256" y="112" />
        <use href="#p" x="256" y="120" />
        <use href="#p" x="256" y="176" />
        <use href="#p" x="256" y="192" />
        <use href="#p" x="256" y="208" />
        <use href="#p" x="256" y="224" />
        <use href="#p" x="256" y="232" />
        <use href="#p" x="256" y="240" />
        <use href="#p" x="256" y="248" />
        <use href="#p" x="256" y="256" />
        <use href="#p" x="256" y="264" />
        <use href="#p" x="256" y="272" />
        <use href="#p" x="256" y="288" />
        <use href="#p" x="264" y="32" />
        <use href="#p" x="264" y="48" />
        <use href="#p" x="264" y="56" />
        <use href="#p" x="264" y="64" />
        <use href="#p" x="264" y="80" />
        <use href="#p" x="264" y="96" />
        <use href="#p" x="264" y="104" />
        <use href="#p" x="264" y="112" />
        <use href="#p" x="264" y="120" />
        <use href="#p" x="264" y="128" />
        <use href="#p" x="264" y="136" />
        <use href="#p" x="264" y="144" />
        <use href="#p" x="264" y="168" />
        <use href="#p" x="264" y="176" />
        <use href="#p" x="264" y="184" />
        <use href="#p" x="264" y="192" />
        <use href="#p" x="264" y="200" />
        <use href="#p" x="264" y="216" />
        <use href="#p" x="264" y="232" />
        <use href="#p" x="264" y="240" />
        <use href="#p" x="264" y="248" />
        <use href="#p" x="264" y="264" />
        <use href="#p" x="264" y="280" />
        <use href="#p" x="272" y="32" />
        <use href="#p" x="272" y="48" />
        <use href="#p" x="272" y="56" />
        <use href="#p" x="272" y="64" />
        <use href="#p" x="272" y="80" />
        <use href="#p" x="272" y="96" />
        <use href="#p" x="272" y="104" />
        <use href="#p" x="272" y="176" />
        <use href="#p" x="272" y="192" />
        <use href="#p" x="272" y="208" />
        <use href="#p" x="272" y="224" />
        <use href="#p" x="280" y="32" />
        <use href="#p" x="280" y="80" />
        <use href="#p" x="280" y="96" />
        <use href="#p" x="280" y="128" />
        <use href="#p" x="280" y="136" />
        <use href="#p" x="280" y="160" />
        <use href="#p" x="280" y="184" />
        <use href="#p" x="280" y="192" />
        <use href="#p" x="280" y="216" />
        <use href="#p" x="280" y="272" />
        <use href="#p" x="288" y="32" />
        <use href="#p" x="288" y="40" />
        <use href="#p" x="288" y="48" />
        <use href="#p" x="288" y="56" />
        <use href="#p" x="288" y="64" />
        <use href="#p" x="288" y="72" />
        <use href="#p" x="288" y="80" />
        <use href="#p" x="288" y="104" />
        <use href="#p" x="288" y="112" />
        <use href="#p" x="288" y="120" />
        <use href="#p" x="288" y="144" />
        <use href="#p" x="288" y="152" />
        <use href="#p" x="288" y="160" />
        <use href="#p" x="288" y="168" />
        <use href="#p" x="288" y="176" />
        <use href="#p" x="288" y="192" />
        <use href="#p" x="288" y="200" />
        <use href="#p" x="288" y="208" />
        <use href="#p" x="288" y="224" />
        <use href="#p" x="288" y="240" />
        <use href="#p" x="288" y="248" />
        <use href="#p" x="288" y="256" />
        <use href="#p" x="288" y="264" />
        <use href="#p" x="288" y="272" />
        <use href="#p" x="288" y="288" />
      </g>
      <g></g>
    </svg>
  );
};

import { Button } from "../../../components/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import { useTranslation } from "react-i18next";
import { ContentBlock } from "../../../components/ContentBlock";
import { Title } from "../../../components/Title";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { coursesApi } from "../../../api/coursesApi";
import { Loader } from "../../../components/Loader";
import { studyApi } from "../../../api/studyApi";

const StyledProgressRow = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  height: auto;
`;

const StyledProgressIconContainer = styled("div")`
  flex-shrink: 0;
  width: 36px;
  height: 36px;
`;

const StyledColumn = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const StyledRow = styled("div")`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: 1200px) {
    margin: 60px 0;
    flex-wrap: wrap;
  }

  @media (max-width: 1400px) {
    gap: 20px;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const StyledAchievementItem = styled("div")`
  max-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  border-radius: var(--border-radius);
  box-shadow: var(--input-shadow);
  flex-shrink: 0;
`;

const StyledItemImageContainer = styled("div")`
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  border-radius: 4px;
  overflow: hidden;

  &.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StyledAchievementTitle = styled("div")`
  text-align: center;
  margin-top: 8px;
`;

const StyledProgressInfo = styled("div")`
  width: 100%;
`;

const StyledBlogRow = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const StyledBlogRowItem = styled("div")`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  border-radius: var(--border-radius);
  padding: 8px;
  box-shadow: var(--input-shadow);
  /* background: white; */
`;

const StyledBlogTitle = styled("div")`
  margin-right: auto;
`;

const StyledAchievementRow = styled("div")`
  display: flex;
  gap: 8px;
  width: 100%;
  overflow-y: auto;
`;

const StyledProgressItem = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: var(--border-radius);
  padding: 16px;
  box-shadow: var(--input-shadow);
`;

const StyledProgressBarWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

const StyledProgressBar = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 0 1px var(--input-shadow-color);
  position: relative;
  height: 30px;
  &::before {
    position: absolute;
    content: "";
    width: ${(props) => props.score * 100}%;
    height: 100%;
    opacity: 0.6;
    background: #03ab00;
    left: 0;
    top: 0;
    border-radius: 2px;
  }
`;

const StyledProgressPercent = styled("div")`
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  right: 5px;
  top: 5px;
`;

export const Board = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const [progressInfo, setProgressInfo] = useState();

  const tempImageUrl = "https://picsum.photos/seed/picsum/70";

  const articles = [
    {
      image: tempImageUrl,
      title: "Заголовок однієї зі статей",
    },
    {
      image: tempImageUrl,
      title: "Заголовок однієї зі статей",
    },
    {
      image: tempImageUrl,
      title: "Заголовок однієї зі статей",
    },
  ];

  const achievements = [
    {
      image: tempImageUrl,
      title: "Раннє пташеня",
    },
    {
      image: tempImageUrl,
      title: "Раннє пташеня",
    },
    {
      image: tempImageUrl,
      title: "Раннє пташеня",
    },
    {
      image: tempImageUrl,
      title: "Монстр дебага",
    },
    {
      image: tempImageUrl,
      title: "А шо так можно?",
    },
  ];

  const getCurrentProgress = async () => {
    setIsLoading(true);
    try {
      const courseEducationList = await studyApi.getCourseEducationList();
      console.log(courseEducationList);
      if (courseEducationList.length > 0) {
        const courseEducation = courseEducationList.find((course) => course.student_score !== 1);
        if (courseEducation) {
          const course_info = await coursesApi.getCourse(courseEducation.course_id);
          const lesson_info = await coursesApi.getLesson({
            course_id: courseEducation.course_id,
            lesson_id: courseEducation.current_lesson,
          });
          setProgressInfo({
            course_name: course_info.name,
            course_id: course_info.id,
            lesson_name: lesson_info.title,
            lesson_id: lesson_info.id,
            score: Math.round(courseEducation.student_score),
            course_length: courseEducation.course_length,
          });
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentProgress();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <StyledRow>
      <StyledColumn>
        <ContentBlock>
          <Title value={t("home.achievements")} />
          <StyledAchievementRow>
            {achievements?.map((achievement, idx) => (
              <StyledAchievementItem key={idx}>
                <StyledItemImageContainer>
                  <img src={achievement.image} alt="article_image" />
                </StyledItemImageContainer>
                <StyledAchievementTitle>{achievement.title}</StyledAchievementTitle>
              </StyledAchievementItem>
            ))}
          </StyledAchievementRow>
          <Link to="/">{t("button.more")}...</Link>
        </ContentBlock>
        {progressInfo && (
          <ContentBlock>
            <StyledProgressItem key={progressInfo.courseId}>
              <StyledProgressRow>
                <StyledProgressBarWrapper>
                  <StyledProgressBar score={progressInfo.score}>
                    <StyledProgressPercent>{progressInfo.score * 100}%</StyledProgressPercent>
                  </StyledProgressBar>
                </StyledProgressBarWrapper>
              </StyledProgressRow>
              <StyledProgressRow>
                <StyledProgressInfo>
                  <div>
                    <b>{t("course.title")}:</b> {progressInfo.course_name}
                  </div>
                  <div>
                    <b>{t("lesson.title")}:</b> {progressInfo.lesson_name}
                  </div>
                </StyledProgressInfo>
                <Link to={ROUTES.study + "/" + progressInfo.course_id + "/" + progressInfo.lesson_id + "/theory"}>
                  <Button title={t("button.continue")} isFilled />
                </Link>
              </StyledProgressRow>
            </StyledProgressItem>
          </ContentBlock>
        )}
      </StyledColumn>

      <StyledColumn>
        <ContentBlock>
          <Title value={t("home.blog")} />
          {/*<div className="blog__title board__title">{t("home.blog")}</div>*/}
          <StyledBlogRow>
            {articles?.map((article, idx) => (
              <StyledBlogRowItem key={idx}>
                <StyledItemImageContainer>
                  <img src={article.image} alt="article_image" />
                </StyledItemImageContainer>
                <StyledBlogTitle>{article.title}</StyledBlogTitle>
                <Button title={t("button.read")} isFilled />
              </StyledBlogRowItem>
            ))}
          </StyledBlogRow>
          <Link to="/">{t("button.more")}...</Link>
        </ContentBlock>
      </StyledColumn>
    </StyledRow>
  );
};

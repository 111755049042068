export const HomeFilled = ({ size = 28, fill = "black", onClick }) => {
  return (
    <svg
      style={{ width: `${size}px`, height: `${size}px` }}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      id="_Шар_1"
      data-name="Шар 1"
      viewBox="0 0 168.39 175.62"
    >
      <path
        fill={fill}
        strokeWidth={0}
        d="M168.39,71.33v100.77c0,1.94-1.58,3.52-3.52,3.52h-58.09c-1.94,0-3.52-1.58-3.52-3.52v-68.85c0-1.95-1.58-3.52-3.52-3.52h-31.09c-1.94,0-3.52,1.57-3.52,3.52v68.85c0,1.94-1.58,3.52-3.52,3.52H3.52c-1.94,0-3.52-1.58-3.52-3.52v-100.76c0-1.04.46-2.03,1.26-2.7L82.06.82c1.31-1.1,3.23-1.1,4.53,0l18.55,15.62,42.89,36.11,19.11,16.09c.79.67,1.25,1.66,1.25,2.69Z"
      />
    </svg>
  );
};

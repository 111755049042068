export const Delete = ({fill = "black", size = 22, onClick}) => {
  return (
    <svg style={{
      height: `${size}px`,
      width: `${size}px`,
      cursor: "pointer"
    }} onClick={onClick} xmlns="http://www.w3.org/2000/svg" id="_Шар_1" data-name="Шар 1" viewBox="0 0 326.69 364.55">
      <path fill={fill} strokeWidth={0}
            d="m29.72,69.71l26.57,266.21c0,15.82,12.82,28.64,28.64,28.64h158.44c15.8,0,28.62-12.82,28.62-28.64l26.57-266.21H29.72Zm213.65,266.23l-158.46-.02-22.49-237.59h203.43l-22.49,237.61Z"/>
      <path fill={fill} strokeWidth={0}
            d="m164.14,0c-46.97,0-85.04,31.21-85.04,69.71h28.62c0-22.26,25.84-41.09,56.42-41.09s56.42,18.83,56.42,41.09h28.62C249.18,31.21,211.12,0,164.14,0Z"/>
      <rect fill={fill} strokeWidth={0} x="0" y="69.71" width="326.69" height="28.8" rx="14.4" ry="14.4"/>
      <g>
        <rect fill={fill} strokeWidth={0} x="84.82" y="198.4" width="157.04" height="28.8"
              transform="translate(-102.63 177.83) rotate(-45)"/>
        <rect fill={fill} strokeWidth={0} x="84.82" y="198.4" width="157.04" height="28.8"
              transform="translate(128.37 478.79) rotate(-135)"/>
      </g>
    </svg>
  )
}

import axiosInstanceWithInterceptor from "./axiosInstanceWithInterceptor";

export const faqApi = {
  getCategories: async () => {
    return await axiosInstanceWithInterceptor.get(`/faq/category/`);
  },

  getCategory: async ({ category_id }) => {
    return await axiosInstanceWithInterceptor.get(`/faq/category/${category_id}/`);
  },

  getCategoryTranslations: async ({ category_id }) => {
    return await axiosInstanceWithInterceptor.get(`/faq/category/${category_id}/translations/`);
  },

  getFAQs: async ({ category_id }) => {
    return await axiosInstanceWithInterceptor.get(`/faq/category/${category_id}/faq/`);
  },

  addFAQ: async ({ category_id, lang, question, answer }) => {
    const payload = {
      category_id,
      translation: {
        question,
        answer,
        lang,
      },
    };
    return await axiosInstanceWithInterceptor.post(`/faq/category/${category_id}/faq/`, payload);
  },

  getFAQTranslations: async ({ category_id, faq_id }) => {
    return await axiosInstanceWithInterceptor.get(`/faq/category/${category_id}/faq/${faq_id}/translations/`);
  },

  updateFAQTranslation: async ({ translation_id, category_id, faq_id, question, answer, lang }) => {
    const payload = {
      category_id,
      translation_id,
      lang,
      question,
      answer,
    };
    return await axiosInstanceWithInterceptor.put(
      `/faq/category/${category_id}/faq/${faq_id}/translations/${translation_id}/`,
      payload
    );
  },
};

import { Link, NavLink, useLocation, useMatch } from "react-router-dom";
import { styled } from "@mui/system";

const StyledNavLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  color: var(--black);
  padding: 6px 16px;
  margin: 0 6px;
  width: calc(100% - 12px);
  height: 42px;
  transition: 0.3s;
  border-radius: 5px;
  user-select: none;
  //border: 1px solid transparent;
  background: ${(props) => props.active && "rgba(255, 255, 255, 0.6)"};

  // &.active {
  //   //border: 1px solid rgba(0, 0, 0, 0.6);
  // }

  &:hover {
    background: rgba(255, 255, 255, 0.4);
    //border: 1px solid rgba(0, 0, 0, 0.4);
  }

  &.active svg {
    stroke: black;
    strokewidth: 0.3px;
    stroke-linejoin: round;
    paint-order: stroke;
  }
`;

const NavMenuIconContainer = styled("div")`
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  overflow: hidden;
`;

const NavMenuLinkTitle = styled("div")`
  pointer-events: none;
  white-space: nowrap;
  opacity: ${(props) => (props.isOpened ? "1" : "0")};
  transition: 0.2s;
  font-weight: 500;
`;

export const NavMenuLink = ({ onClick, to, children, title, icon, isOpened, active }) => {
  let activeIcon;
  if (icon.active) {
    activeIcon = active ? icon.active : icon.inactive;
  } else {
    activeIcon = icon;
  }

  return (
    <StyledNavLink to={to} onClick={onClick}>
      {children}
      {activeIcon && <NavMenuIconContainer>{activeIcon}</NavMenuIconContainer>}
      <NavMenuLinkTitle isOpened={isOpened}>{title}</NavMenuLinkTitle>
    </StyledNavLink>
  );
};

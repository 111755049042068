import { styled } from "@mui/system";

const StyledTitle = styled('h2')`
  color: var(--black);
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
`;

export const Title = ({value}) => {
  return <StyledTitle>{value}</StyledTitle>;
};

import axiosInstance from "./axiosInstance";
import axiosInstanceWithInterceptor from "./axiosInstanceWithInterceptor";
import { removeItem } from "../utils/storage";

export const userApi = {
  register: async ({ username, email, password, password_confirm, language_default }) => {
    const payload = {
      username,
      email,
      password,
      password_confirm,
      language_default,
    };
    try {
      const response = await axiosInstance.post(`/users/sign-up/`, payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  login: async ({ email, password }) => {
    const payload = {
      email,
      password,
    };

    try {
      const response = await axiosInstance.post(`/users/login/`, payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  logout: async () => {
    removeItem("accessToken");
    removeItem("refreshToken");
  },

  deleteUser: async () => {
    return await axiosInstanceWithInterceptor.delete("/users/update/");
  },

  getUserInfo: async () => {
    return await axiosInstanceWithInterceptor.get("/users/update/");
  },

  updateUserInfo: async ({
    first_name,
    last_name,
    age,
    birthday,
    sex,
    username,
    link_github,
    link_linkedin,
    language_default,
  }) => {
    const payload = {
      first_name,
      last_name,
      username,
      profile: {
        age,
        birthday,
        sex,
        link_github,
        link_linkedin,
        language_default,
      },
    };

    return await axiosInstanceWithInterceptor.put("/users/update/", payload);
  },

  requestResetCode: async ({ email }) => {
    const payload = {
      email: email,
    };
    return await axiosInstance.post("/users/reset-password-request/", payload);
  },

  resetPassword: async ({ uid, token }) => {
    return await axiosInstance.post(`/users/reset-password/${uid}/${token}`);
  },
};

export const Cross = ({size = 16}) => {
    return (
        <svg style={{
            width: `${size}px`,
            height: `${size}px`
        }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill="none">
            <g clipPath="url(#clip0_1601_751)">
                <path
                    d="M12.6479 0.578941L7.99993 5.22719C6.45068 3.67844 4.90093 2.12819 3.35143 0.578941C1.55893 -1.21356 -1.21957 1.56594 0.571434 3.35894C2.12118 4.90719 3.67143 6.45769 5.21943 8.00694C3.67073 9.5569 2.12139 11.1062 0.571434 12.6549C-1.21957 14.4469 1.55918 17.2257 3.35143 15.4349C4.90068 13.8852 6.45043 12.3352 7.99968 10.7864L12.6477 15.4349C14.4402 17.2269 17.2194 14.4472 15.4277 12.6549C13.8779 11.1052 12.3287 9.55594 10.7784 8.00644C12.3284 6.45669 13.8777 4.90694 15.4277 3.35744C17.2197 1.56594 14.4404 -1.21356 12.6477 0.579941"
                    fill="#EC1C24"/>
            </g>
            <defs>
                <clipPath id="clip0_1601_751">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};


export const SettingsFilled = ({ fill = "black", size = 28 }) => {
  return (
    <svg
      style={{ width: `${size}px`, height: `${size}px` }}
      id="_Шар_1"
      data-name="Шар 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 173.83 173.83"
    >
      <path
        strokeWidth={0}
        fill={fill}
        d="M166.21,69.78h-13.79l-1.14-3.23c-.76-2.17-1.67-4.33-2.68-6.42l-1.49-3.1,9.76-9.75c4.35-4.36,4.35-11.45,0-15.81l-10.96-10.95c-4.36-4.35-11.45-4.35-15.81,0l-9.75,9.75-3.08-1.47c-2.13-1.01-4.29-1.9-6.45-2.67l-3.23-1.14v-13.8c0-6.17-5.01-11.18-11.18-11.18h-15.5c-6.16,0-11.17,5.01-11.17,11.18v13.81l-3.24,1.14c-2.15.75-4.32,1.65-6.42,2.66l-3.09,1.47-9.75-9.75c-4.36-4.35-11.45-4.35-15.81,0l-10.95,10.95c-4.35,4.36-4.35,11.45,0,15.81l9.74,9.75-1.47,3.08c-1.01,2.13-1.9,4.29-2.67,6.45l-1.14,3.23h-13.77c-6.17,0-11.18,5.01-11.18,11.17v15.49c0,6.17,5.01,11.18,11.18,11.18h13.77l1.14,3.23c.79,2.21,1.68,4.37,2.68,6.44l1.49,3.09-9.76,9.76c-4.35,4.36-4.35,11.45,0,15.81l10.95,10.96c2.11,2.11,4.91,3.27,7.9,3.27,1.5,0,2.95-.29,4.28-.84,1.35-.56,2.57-1.38,3.63-2.43l9.75-9.75,3.09,1.47c2.09,1,4.26,1.9,6.45,2.68l3.22,1.15v13.76c0,6.17,5.01,11.18,11.17,11.18h15.5c6.17,0,11.18-5.01,11.18-11.18v-13.76l3.21-1.15c2.23-.8,4.4-1.7,6.46-2.68l3.09-1.47,9.75,9.75c2.11,2.11,4.92,3.27,7.9,3.27s5.8-1.16,7.91-3.27l10.96-10.96c4.35-4.36,4.35-11.45,0-15.81l-9.75-9.75,1.47-3.08c1.04-2.2,1.94-4.37,2.67-6.45l1.14-3.24h13.8c6.17,0,11.18-5.01,11.18-11.18v-15.49c0-6.16-5.01-11.17-11.18-11.17ZM112.43,91.71v.11c-.55,4.16-2.21,8.15-4.82,11.54-1.19,1.59-2.68,3.07-4.37,4.36-3.24,2.52-7.17,4.15-11.31,4.7l-.22.04c-.96.13-1.98.19-3,.19s-2.06-.06-3.01-.19h-.02c-4.29-.56-8.28-2.22-11.63-4.81-1.65-1.26-3.08-2.7-4.37-4.39-2.54-3.3-4.19-7.3-4.74-11.54-.14-.93-.2-1.96-.2-3.02,0-1,.06-1.98.19-2.93l.02-.18c.53-4.15,2.18-8.15,4.79-11.51,1.19-1.58,2.68-3.07,4.37-4.37,3.33-2.56,7.32-4.22,11.56-4.76,1.81-.26,4.18-.28,6.1.01l.14.02c4.13.54,8.07,2.2,11.41,4.77,1.66,1.27,3.1,2.72,4.38,4.42,2.53,3.26,4.18,7.18,4.7,11.3l.02.16c.14.98.22,2,.22,3.07s-.06,2.06-.2,3.01Z"
      />
    </svg>
  );
};

import { NavLink } from "react-router-dom";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";

const StyledTabContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
`;

const StyledTabHeader = styled("div")`
  position: relative;
`;

const StyledTabTitle = styled("div")`
  font-size: 24px;
  font-weight: 700;
  position: relative;
  margin: 40px 0 20px;
  width: 100%;
  text-align: ${(props) => props.align || "left"};
`;

const StyledTabsRow = styled("div")`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align || "flex-start"};
  position: relative;
  margin: 12px 0 4px;
  gap: 4px;
`;

const StyledTab = styled(NavLink)`
  font-weight: 500;
  position: relative;
  padding: 8px 15px;
  border-radius: 5px;
  transition: background 0.2s;
  background: var(--base-color);
  opacity: 0.5;
  white-space: nowrap;

  &:hover {
    background: var(--white);
  }

  &.active {
    color: var(--black);
    background: var(--white);
    opacity: 1;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      bottom: -5px;
      height: 8px;
      left: 0;
      width: 100%;
      background: var(--white);
      z-index: 2;
    }
  }
`;

const StyledTabContent = styled("div")`
  margin-bottom: 70px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Tabs = ({ children, tabsContent, title, align }) => {
  return (
    <StyledTabContainer>
      <StyledTabHeader>
        <StyledTabTitle align={align}>{title}</StyledTabTitle>
        <StyledTabsRow align={align}>
          {tabsContent?.map((tab, idx) => {
            if (tab.enabled !== false)
              return (
                <StyledTab end to={tab.link} key={idx}>
                  {tab.title}
                </StyledTab>
              );
          })}
        </StyledTabsRow>
      </StyledTabHeader>
      <StyledTabContent>{children}</StyledTabContent>
    </StyledTabContainer>
  );
};

import { FormContainer } from "../../../components/Form/Container";
import { Form } from "../../../components/Form/Form";
import { PrimaryTitle } from "../../../components/PrimaryTitle";
import { InputContainer } from "../../../components/InputContainer";
import { Input } from "../../../components/Input";
import { useState } from "react";
import { Button } from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import { userApi } from "../../../api/userApi";
import { Flex } from "../../../components/Flex";

const StyledText = styled("div")`
  text-align: center;
  margin-top: -15px;
  max-width: 270px;
  width: 100%;
`;

export const ResetPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [isCodeRequested, setIsCodeRequested] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const MESSAGES = {
    title: isCodeRequested ? "passwordChanged" : "resetPassword",
    text: isCodeRequested ? "toChangeYourPasswordFollowTheLinkInTheEmail" : "enterYourEmailAndWeWillSendYouNewPassword",
    button: isCodeRequested ? "login" : "sendCode",
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    if (isCodeRequested) {
      navigate(ROUTES.login);
    }
    setIsButtonLoading(true);
    try {
      await userApi.requestResetCode({ email });
      setIsCodeRequested(true);
    } catch (err) {
      setError(err.response.data.error);
    } finally {
      setIsButtonLoading(false);
    }
  };
  return (
    <FormContainer>
      <Form>
        <PrimaryTitle value={t(`form.${MESSAGES.title}`)} />
        <StyledText>{t(`form.${MESSAGES.text}`)}</StyledText>
        {!isCodeRequested && (
          <InputContainer error={error} fullWidth>
            <Input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </InputContainer>
        )}
        <Flex gap={16}>
          <Button
            title={t(`button.${MESSAGES.button}`)}
            onClick={handleButtonClick}
            isLoading={isButtonLoading}
            isFilled
          />
          <Button title={t(`button.cancel`)} onClick={() => navigate(ROUTES.index)} />
        </Flex>
      </Form>
    </FormContainer>
  );
};

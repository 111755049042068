import { useDispatch, useSelector } from "react-redux";
import { setEditorStatus, setIsNavMenuPinned, setUserRole } from "../../slices/mainSlice";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGES, ROUTES, USER_ROLES } from "../../constants/constants";
import { useAuth } from "../../context/AuthContext";
import ukrainianFlag from "../../assets/images/icons/flags/ukrainian.png";
import englishFlag from "../../assets/images/icons/flags/english.png";
import { Select } from "../../components/Select";
import { InputContainer } from "../../components/InputContainer";
import { Title } from "../../components/Title";
import { FormContainer } from "../../components/Form/Container";
import { styled } from "@mui/system";
import { userApi } from "../../api/userApi";
import { useLocation, useNavigate } from "react-router-dom";

const SettingsForm = styled("div")`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  background: #f9f8f8;
  padding: 15px;
  border: 4px solid var(--white);
  box-shadow: var(--black-shadow);
  background: var(--base-color);
`;

export const Settings = ({ toggleSettingsVisibility, wasNavMenuPinned }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { user, isAdmin, isAuthor } = useAuth();
  const settingsRef = useRef();
  const userRole = useSelector((state) => state.userRole);
  const [availableRoles, setAvailableRoles] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const changeLang = async (value) => {
    await userApi.updateUserInfo({ username: user.username, language_default: value });
    i18n.changeLanguage(value);
    window.location.reload();
  };

  const handleModeChange = (value) => {
    if (
      (userRole === USER_ROLES.author || userRole === USER_ROLES.admin) &&
      value === USER_ROLES.student &&
      location.pathname.includes(ROUTES.study)
    ) {
      navigate(ROUTES.study);
    }
    dispatch(setEditorStatus(value === USER_ROLES.author || value === USER_ROLES.admin));
    dispatch(setUserRole(value));
  };

  const handleClickOutside = (event) => {
    if (settingsRef.current && !settingsRef.current.contains(event.target)) {
      if (!wasNavMenuPinned) {
        dispatch(setIsNavMenuPinned(false));
      }
      toggleSettingsVisibility(false);
    }
  };
  const languagesArray = [
    { value: LANGUAGES.uk, label: "Українська", icon: ukrainianFlag },
    { value: LANGUAGES.en, label: "English", icon: englishFlag },
  ];

  useEffect(() => {
    let newAvailableRoles = [{ value: USER_ROLES.student, label: t("settings.role.student") }];
    console.log(user.profile.role);
    if (isAuthor()) {
      newAvailableRoles.push({
        value: USER_ROLES.author,
        label: t("settings.role.author"),
      });
    }
    if (isAdmin()) {
      newAvailableRoles.push(
        {
          value: USER_ROLES.author,
          label: t("settings.role.author"),
        },
        {
          value: USER_ROLES.admin,
          label: t("settings.role.admin"),
        }
      );
    }
    console.log(newAvailableRoles);
    setAvailableRoles(newAvailableRoles);
  }, [user]);

  return (
    <FormContainer onClick={handleClickOutside} isPopup>
      <SettingsForm ref={settingsRef}>
        <Title value={t("settings.title")} />
        {(isAuthor() || isAdmin()) && (
          <InputContainer title={t("settings.role.title")}>
            <Select
              options={availableRoles}
              defaultValue={availableRoles.find((item) => item.value === userRole)}
              onClick={handleModeChange}
            />
          </InputContainer>
        )}
        <InputContainer title={t("settings.siteLanguage")}>
          <Select
            options={languagesArray}
            defaultValue={languagesArray.find((item) => item.value === i18n.language)}
            onClick={changeLang}
          />
        </InputContainer>
      </SettingsForm>

      {/* <div className="settings__line">
            <label className="settings__switch">
              <input
                type="checkbox"
                className="settings__switch__input"
                onChange={toggleTheme}
                checked={theme === "dark"}
              />
              <span className="settings__switch__input__slider"></span>
            </label>

            <p className="settings__line__name">
              {t("theme")}: {theme === "light" ? t("themeLight") : t("themeDark")}
            </p>
          </div> */}
    </FormContainer>
  );
};

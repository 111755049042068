import { Tabs } from "../../../components/Tabs";
import { Route, Routes } from "react-router-dom";
import { Additional } from "../Additional";
import { AccountManagement } from "../AccountManagement";
import { ROUTES } from "../../../constants/constants";
import { useTranslation } from "react-i18next";
import { PersonalInfo } from "../PersonalInfo";
import { useAuth } from "../../../context/AuthContext";

export const AccountInfo = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const tabsContent = [
    {
      link: ROUTES.accountGeneral,
      title: t("account.main"),
    },
    {
      link: ROUTES.accountAdditional,
      title: t("account.additional"),
    },
    {
      link: ROUTES.accountManagement,
      title: t("account.management"),
    },
  ];

  function areObjectsSimilar(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  return (
    <Tabs align="center" tabsContent={tabsContent} title={t("account.title")}>
      <Routes>
        <Route index path="/" element={<PersonalInfo areObjectsSimilar={areObjectsSimilar} user={user} />} />
        <Route path="/additional" element={<Additional areObjectsSimilar={areObjectsSimilar} user={user} />} />
        <Route path="/management" element={<AccountManagement />} />
      </Routes>
    </Tabs>
  );
};

import React, { useState } from "react";
import { styled } from "@mui/system";
import { Textarea } from "../Textarea";
import { useAuth } from "../../context/AuthContext";

const StyledText = styled("div")`
  padding: 6px 8px;
  font-size: ${(props) => props.fontSize + "px" || "1rem"};
  display: inline-block;
  transition: 0.3s;
  //box-shadow: rgba(0, 0, 0, 0.01) 0 0 4px;
  //border: 1px solid rgba(154, 174, 189, 0.5);;
  border-radius: 4px;
  cursor: text;

  @media (max-width: 850px) {
    font-size: ${(props) => props.fontSize * 0.9 + "px" || "0.9rem"};
    &::placeholder {
      color: #9a98a0;
      font-size: ${(props) => props.fontSize * 0.9 + "px" || "0.9rem"};
    }
  }

  &:hover {
    box-shadow: var(--black-shadow);
    //border: 1px solid var(--input-shadow);
    border-radius: 4px;
    cursor: text;
  }
`;

export const EditableField = ({ initialText, onTextChange, fontSize = 16, placeholder }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const { hasPriviledgedPersonEnabledEditorMode } = useAuth();

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const handleInputBlur = () => {
    onTextChange(text);
    setIsEditing(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleInputBlur();
    }
  };

  return hasPriviledgedPersonEnabledEditorMode() ? (
    <Textarea
      fontSize={fontSize}
      value={text}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
    />
  ) : (
    <StyledText fontSize={fontSize} onClick={handleTextClick}>
      {text}
    </StyledText>
  );
};

import { styled } from "@mui/system";
import { useState } from "react";
import { Dropdown as IconDropdown } from "../../../../components/icons/Dropdown";
import { EditableField } from "../../../../components/EditableField";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Button";
import { faqApi } from "../../../../api/faqApi";
import { Error } from "../../../../components/Error";

const StyledFAQItem = styled("div")`
  margin: 0 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  box-shadow: 0 0 5px 2px var(--content-block-shadow-color);
  background: var(--white);
  padding: 16px;
  border-radius: var(--border-radius);
`;

const StyledQuestion = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.isExpanded ? "default" : "pointer")};
  gap: 20px;
  width: 100%;
  strong {
    padding: 6px 0;
  }
`;

const StyledAnswer = styled("div")`
  width: 100%;
  padding: 0;
  display: ${(props) => (props.isExpanded ? "block" : "none")};
`;

const StyledFieldWrapper = styled("div")`
  width: 100%;
`;

const StyledIconDropdownWrapper = styled("div")`
  padding: 0;
  transform: ${(props) => (props.isExpanded ? "rotate(0deg)" : "rotate(180deg)")};

  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled("div")`
  font-weight: 500;
  padding: 6px 0;
`;

const StyledLine = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 6px;
  align-items: center;
`;
const StyledButtonContainer = styled("div")`
  margin-top: 20px;
  display: flex;
  gap: 16px;
`;

export const FAQItemEditor = ({ translation, faq_id, category_id }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t, i18n } = useTranslation();

  const handleCancel = () => {
    setIsExpanded(false);
  };

  const [errors, setErrors] = useState({});

  const [newQuestion, setNewQuestion] = useState(translation?.question);
  const [newAnswer, setNewAnswer] = useState(translation?.answer);

  const handleQuestionChange = (value) => {
    setNewQuestion(value);
  };

  const handleAnswerChange = (value) => {
    setNewAnswer(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(newQuestion);
    if (!newQuestion) {
      setErrors({ ...errors, question: "Питання не може бути порожнім" });
      return;
    } else {
      setErrors({ ...errors, question: "" });
    }

    if (!newAnswer) {
      setErrors({ ...errors, answer: "Відповідь не може бути порожньою" });
      return;
    } else {
      setErrors({ ...errors, answer: "" });
    }

    try {
      const newFAQ = await faqApi.updateFAQTranslation({
        translation_id: translation.id,
        category_id,
        faq_id,
        question: newQuestion,
        answer: newAnswer,
        lang: i18n.language,
      });
      console.log(newFAQ);

      setErrors({});
      setIsExpanded(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledFAQItem>
      {isExpanded ? (
        <>
          <StyledQuestion isExpanded={isExpanded}>
            <StyledFieldWrapper>
              <StyledLine>
                <StyledText>{t(`faq.question.title`)}:</StyledText>
                <StyledIconDropdownWrapper isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
                  <IconDropdown />
                </StyledIconDropdownWrapper>
              </StyledLine>
              <EditableField initialText={newQuestion} onTextChange={handleQuestionChange} />
              {errors?.question && <Error>{errors.question}</Error>}
            </StyledFieldWrapper>
          </StyledQuestion>

          <StyledAnswer isExpanded={isExpanded}>
            <StyledFieldWrapper>
              <StyledText>{t(`faq.answer.title`)}:</StyledText>
              <EditableField initialText={newAnswer} onTextChange={handleAnswerChange} />
              {errors?.answer && <Error>{errors.answer}</Error>}
            </StyledFieldWrapper>
          </StyledAnswer>
          <StyledButtonContainer>
            <Button isFilled title={t("button.save")} onClick={handleSubmit} />
            <Button title={t("button.cancel")} onClick={handleCancel} />
          </StyledButtonContainer>
        </>
      ) : (
        <>
          <StyledQuestion onClick={() => setIsExpanded(!isExpanded)}>
            <strong>{newQuestion}</strong>
            <StyledIconDropdownWrapper isExpanded={isExpanded}>
              <IconDropdown />
            </StyledIconDropdownWrapper>
          </StyledQuestion>

          <StyledAnswer isExpanded={isExpanded}>{newAnswer}</StyledAnswer>
        </>
      )}
    </StyledFAQItem>
  );
};

export const Dropdown = ({fill = "black", size = 20, onClick}) => {
  return (

    <svg style={{
      height: `${size}px`,
      width: `${size}px`,
      cursor: "pointer",
    }} id="_Шар_1" data-name="Шар 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.83 159.83">
      <path fill={fill} strokeWidth={0}
            d="m79.91,0C35.77,0,0,35.78,0,79.92s35.77,79.91,79.91,79.91,79.92-35.78,79.92-79.91S124.05,0,79.91,0Zm0,145.83c-36.34,0-65.91-29.57-65.91-65.91S43.57,14,79.91,14s65.92,29.57,65.92,65.92-29.57,65.91-65.92,65.91Z"/>
      <polygon fill={fill} strokeWidth={0}
               points="90.47 58.9 80.13 48.56 69.8 58.9 35.07 93.62 45.4 103.96 80.13 69.23 114.86 103.96 125.19 93.62 90.47 58.9 90.47 58.9"/>
    </svg>
  )
};

import React, { forwardRef, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { CreateLessonForm } from "../../../feature/Forms/CreateLessonForm";
import { ConfirmationForm } from "../../../feature/Forms/ConfirmationForm";
import { useLessonContext } from "../../../context/LessonContext";
import { useAuth } from "../../../context/AuthContext";
import { ROUTES } from "../../../constants/constants";
import { setSelectedLesson } from "../../../slices/mainSlice";
import { coursesApi } from "../../../api/coursesApi";

import { PlusButton } from "../../PlusButton";
import { Delete as IconDelete } from "../../icons/Delete";

import { Pin as IconPin } from "../../icons/Pin";
import { PinFilled as IconPinFilled } from "../../icons/PinFilled";

import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { Textbook as IconTextbook } from "../../icons/Textbook";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  StyledTextbook,
  StyledTextbookIconContainer,
  StyledTextbookContent,
  StyledNavLink,
  StyledTextbookHeaderTitle,
  StyledTextbookHeader,
  StyledNavLinkTitle,
  StyledPinContainer,
} from "../Textbook";

const StyledPlusButtonContainer = styled("div")`
  margin: 0 6px;
  width: calc(100% - 12px);
  padding: 0 10px;
`;

const StyledList = styled("div")`
  width: 100%;
`;

export const TextbookEditor = forwardRef(
  ({ isTextbookOpened, setIsTextbookOpened, isTextbookPinned, setIsTextbookPinned, isDesktop }, ref) => {
    const dispatch = useDispatch();
    const [isCreateLessonFormVisible, toggleCreateLessonFormVisibility] = useState(false);
    const [isConfirmationFormVisible, toggleConfirmationFormVisibility] = useState(false);
    const [isTextbookHovered, setIsTextbookHovered] = useState(false);

    const selectedCourse = useSelector((state) => state.selectedCourse);
    const selectedLesson = useSelector((state) => state.selectedLesson);
    const navigate = useNavigate();
    const { lessons, getLessonData, setLessons } = useLessonContext();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { t } = useTranslation();
    const [lessonIdToDelete, setLessonIdToDelete] = useState(null);

    const handleDeleteSubmit = async (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsButtonLoading(true);
      try {
        const index = lessons.findIndex((lesson) => lesson.id === lessonIdToDelete);
        if (lessons[index - 1]?.id) {
          navigate(ROUTES.study + "/" + selectedCourse + "/" + lessons[index - 1].id + "/theory");
          dispatch(setSelectedLesson(lessons[index - 1].id));
        } else if (lessons[index + 1]?.id) {
          navigate(ROUTES.study + "/" + selectedCourse + "/" + lessons[index + 1].id + "/theory");
          dispatch(setSelectedLesson(lessons[index + 1].id));
        } else {
          navigate(ROUTES.study);
          dispatch(setSelectedLesson(null));
        }
        await coursesApi.deleteLesson({ course_id: selectedCourse, lesson_id: lessonIdToDelete });
        await getLessonData();
        toggleConfirmationFormVisibility(false);
      } catch (err) {
        console.log(err);
      } finally {
        setIsButtonLoading(false);
      }
    };

    const handleDelete = (event, lesson_id) => {
      event.preventDefault();
      event.stopPropagation();
      if (lessonIdToDelete !== lesson_id) {
        setLessonIdToDelete(lesson_id);
      }

      toggleConfirmationFormVisibility(true);
    };

    const handleLessonSelect = (event, lesson_id) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(ROUTES.study + "/" + selectedCourse + "/" + lesson_id + "/theory");
      if (selectedLesson !== lesson_id) {
        dispatch(setSelectedLesson(lesson_id));
      }
    };

    const handleOnDragEnd = async (result) => {
      if (!result.destination || result.source.index === result.destination.index) return;
      const items = Array.from(lessons);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLessons(items);

      try {
        await coursesApi.changeOrder({
          course_id: selectedCourse,
          lesson_id: reorderedItem.id,
          new_sequence_id: result.destination.index + 1,
        });
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <>
        {isCreateLessonFormVisible && (
          <CreateLessonForm
            toggleCreateLessonFormVisibility={toggleCreateLessonFormVisibility}
            getLessonData={getLessonData}
            setIsLoading={setIsButtonLoading}
            isLoading={isButtonLoading}
          />
        )}
        {isConfirmationFormVisible && (
          <ConfirmationForm
            handleSubmit={handleDeleteSubmit}
            toggleConfirmationFormVisibility={toggleConfirmationFormVisibility}
            isLoading={isButtonLoading}
          />
        )}
        <StyledTextbook
          isOpened={isDesktop ? isTextbookHovered || isTextbookPinned : isTextbookOpened}
          ref={ref}
          onMouseOver={() => isDesktop && setIsTextbookHovered(true)}
          onMouseOut={() => isDesktop && setIsTextbookHovered(false)}
        >
          <StyledTextbookContent>
            <StyledTextbookIconContainer
              isOpened={isDesktop ? isTextbookHovered || isTextbookPinned : isTextbookOpened}
              onClick={() => !isDesktop && setIsTextbookOpened(!isTextbookOpened)}
            >
              <IconTextbook />
            </StyledTextbookIconContainer>
            <StyledTextbookHeader isOpened={isDesktop ? isTextbookHovered || isTextbookPinned : isTextbookOpened}>
              <StyledTextbookHeaderTitle>{t("textbook.title")}</StyledTextbookHeaderTitle>
              <StyledPinContainer
                onClick={() => {
                  if (isDesktop) {
                    dispatch(setIsTextbookPinned(!isTextbookPinned));
                  } else {
                    setIsTextbookOpened(!isTextbookOpened);
                  }
                }}
              >
                {isTextbookPinned ? <IconPinFilled /> : <IconPin />}
              </StyledPinContainer>
            </StyledTextbookHeader>

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="lessons">
                {(provided) => (
                  <StyledList {...provided.droppableProps} ref={provided.innerRef}>
                    {lessons.map((lesson, index) => {
                      return (
                        <Draggable key={lesson.id} draggableId={lesson.id} index={index}>
                          {(provided, snapshot) => (
                            <StyledNavLink
                              ref={provided.innerRef}
                              onClick={(event) => handleLessonSelect(event, lesson.id, lesson.title)}
                              to={ROUTES.study + "/" + selectedCourse + "/" + lesson.id}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              highlighted={snapshot.isDragging ? "true" : undefined}
                            >
                              <StyledNavLinkTitle
                                isOpened={isDesktop ? isTextbookHovered || isTextbookPinned : isTextbookOpened}
                              >
                                {lesson.title}
                              </StyledNavLinkTitle>
                              <IconDelete onClick={(event) => handleDelete(event, lesson.id, lesson.title)} />
                            </StyledNavLink>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </StyledList>
                )}
              </Droppable>
            </DragDropContext>
            <StyledPlusButtonContainer>
              <PlusButton onClick={() => toggleCreateLessonFormVisibility(true)} title={t("lesson.create")} />
            </StyledPlusButtonContainer>
          </StyledTextbookContent>
        </StyledTextbook>
      </>
    );
  }
);

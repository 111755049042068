import { Link } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { PrimaryTitle } from "../../../components/PrimaryTitle";
import { styled } from "@mui/system";
import { CoursesEditor } from "../CoursesEditor";
import { CoursesContent } from "../CoursesContent";

const StyledCoursesContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 80px 0;
  width: 100%;
`;

export const StyledCoursesRow = styled("div")`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  width: 100%;
  @media (max-width: 1100px) {
    gap: 20px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const StyledCourseItemImageContainer = styled("div")`
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
  }
`;

export const AvailableCourses = () => {
  const { hasPriviledgedPersonEnabledEditorMode } = useAuth();
  const { t } = useTranslation();

  return (
    <StyledCoursesContainer>
      <PrimaryTitle value={t("course.availableCourses")} />
      {hasPriviledgedPersonEnabledEditorMode() ? <CoursesEditor /> : <CoursesContent />}
    </StyledCoursesContainer>
  );
};

import { Link, useNavigate } from "react-router-dom";
import { useCourseContext } from "../../../context/CourseContext";
import { useDispatch } from "react-redux";
import {
  setCourseEducationId,
  setLessonEducationId,
  setSelectedCourse,
  setSelectedLesson,
} from "../../../slices/mainSlice";
import { coursesApi } from "../../../api/coursesApi";
import { COURSE_STATUSES, ROUTES } from "../../../constants/constants";
import { useLessonContext } from "../../../context/LessonContext";
import { StyledCourseItemImageContainer, StyledCoursesRow } from "../AvailableCourses/AvailableCourses";
import { styled } from "@mui/material";

export const StyledCourseItem = styled(Link)`
  padding: 10px;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0 0 10px 2px var(--content-block-shadow-color);
  position: relative;
  &:not(:last-child) {
    align-self: flex-start;
  }
  width: calc(33% - 20px);
  @media (max-width: 1100px) {
    width: calc(50% - 30px);
  }
  @media (max-width: 800px) {
    width: 100%;
  }
  height: auto;
`;

const StyledCourseName = styled("div")`
  margin: 8px 0;
  font-weight: 500;
  font-size: 1.2rem;
`;

const StyledCourseDescription = styled("div")`
  font-size: 0.9rem;
`;

export const CoursesContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courses, getCourseEducationId } = useCourseContext();
  const { getLessonEducationId } = useLessonContext();

  const handleCourseSelect = async (e, course_id) => {
    e.stopPropagation();
    e.preventDefault();
    const newCourseEducationId = await getCourseEducationId({ course_id });
    dispatch(setCourseEducationId(newCourseEducationId));
    const newLessons = await coursesApi.getLessons({ course_id });
    const first_lesson_id = newLessons[0]?.id;
    if (first_lesson_id) {
      const { newLessonEducationId } = await getLessonEducationId({
        lesson_id: first_lesson_id,
        course_education_id: newCourseEducationId,
      });
      dispatch(setLessonEducationId(newLessonEducationId));
      navigate(ROUTES.study + "/" + course_id + "/" + first_lesson_id + "/theory");
      dispatch(setSelectedCourse(course_id));
      dispatch(setSelectedLesson(first_lesson_id));
    }
  };

  return (
    <StyledCoursesRow>
      {courses
        .filter((course) => course.status !== COURSE_STATUSES.draft)
        .map((course) => (
          <StyledCourseItem onClick={(e) => handleCourseSelect(e, course.id)} key={course.id}>
            <StyledCourseItemImageContainer>
              <img src={"https://picsum.photos/seed/picsum/300"} alt="" className="course__item__image" />
            </StyledCourseItemImageContainer>
            <StyledCourseName>
              <strong>{course.name}</strong>
            </StyledCourseName>
            <StyledCourseDescription>{course.description}</StyledCourseDescription>
          </StyledCourseItem>
        ))}
    </StyledCoursesRow>
  );
};

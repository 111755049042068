export const Logo = ({ size = 50, fill = "black", isTextVisible }) => {
  return (
    <svg
      style={{
        width: size,
        height: size,
      }}
      viewBox="0 0 329 326"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M189.16 325.16H246.25V137.39L189.16 80.29H57.09L0 137.39H189.16V325.16Z" fill="black" />
      <path d="M57.09 268.07V162.05H0V268.07L57.09 325.16H160.07H163.89H164.52V268.07H57.09Z" fill="black" />
      <path d="M137.68 0H109.52V101.08H137.68V0Z" fill="black" />
      <path d="M189.09 29.29H160.93V102.98H189.09V29.29Z" fill="black" />
      <path d="M85.3399 29.29H57.1799V102.98H85.3399V29.29Z" fill="black" />
      <path d="M328.94 217.27V189.11H227.86V217.27H328.94Z" fill="black" />
      <path d="M299.65 268.67V240.51H225.96V268.67H299.65Z" fill="black" />
      <path d="M299.65 164.93V136.77H225.96V164.93H299.65Z" fill="black" />
    </svg>
  );
};

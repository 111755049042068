import { IntroContent } from "../../feature/Intro/IntroContent";
import { IntroFooter } from "../../feature/Intro/IntroFooter";
import { IntroHeader } from "../../feature/Intro/IntroHeader";
import { Reviews } from "../../feature/Intro/Reviews";
import { IntroContainer } from "../../feature/Intro/IntroContainer";

export const Intro = () => {
  return (
    <IntroContainer>
      <IntroHeader/>
      <IntroContent/>
      <Reviews/>
      <IntroFooter/>
    </IntroContainer>
  );
};

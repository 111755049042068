export const Checkmark = ({size = 16}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" style={{
            width: `${size}px`,
            height: `${size}px`
        }} viewBox="0 0 24 25" fill="none">
            <g clipPath="url(#clip0_1601_708)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M19.4954 4.13312C19.7169 3.99974 19.9814 3.9573 20.2335 4.01473C20.4855 4.07215 20.7056 4.22497 20.8474 4.44112L21.8374 5.95112C21.9667 6.14847 22.0221 6.3852 21.9937 6.61944C21.9654 6.85368 21.8551 7.07034 21.6824 7.23112L21.6794 7.23512L21.6654 7.24812L21.6084 7.30112L21.3834 7.51612C20.1384 8.72372 18.9311 9.96965 17.7634 11.2521C15.5664 13.6681 12.9574 16.8301 11.2014 19.8981C10.7114 20.7541 9.5144 20.9381 8.8044 20.1991L2.3194 13.4611C2.22647 13.3645 2.1539 13.2502 2.10602 13.125C2.05813 12.9998 2.03591 12.8663 2.04067 12.7323C2.04543 12.5984 2.07707 12.4667 2.13371 12.3452C2.19036 12.2237 2.27085 12.1149 2.3704 12.0251L4.3304 10.2571C4.50264 10.1018 4.7235 10.0112 4.95518 10.0008C5.18685 9.99044 5.41494 10.0609 5.6004 10.2001L8.9094 12.6811C14.0774 7.58412 17.0094 5.62812 19.4954 4.13312Z"
                      fill="#5BCA00"/>
            </g>
            <defs>
                <clipPath id="clip0_1601_708">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};


import { EditableField } from "../../../../components/EditableField";
import { Delete as IconDelete } from "../../../../components/icons/Delete";
import { InputContainer } from "../../../../components/InputContainer";
import { coursesApi } from "../../../../api/coursesApi";
import { Select } from "../../../../components/Select";
import { Button } from "../../../../components/Button";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { COURSE_STATUSES, LANGUAGES, ROUTES, USER_ROLES } from "../../../../constants/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLessonContext } from "../../../../context/LessonContext";
import { useAuth } from "../../../../context/AuthContext";
import { useState } from "react";
import { useSelector } from "react-redux";
import { setSelectedCourse, setSelectedLesson } from "../../../../slices/mainSlice";
import { CreateLessonForm } from "../../../Forms/CreateLessonForm";
import { ConfirmationForm } from "../../../Forms/ConfirmationForm";
import { AuthorManagementForm } from "../../../Forms/AuthorManagementForm";
import { CourseTranslationManagementForm } from "../../../Forms/CourseTranslationManagementForm";
import { StyledCourseItemImageContainer, StyledCoursesRow } from "../../AvailableCourses/AvailableCourses";
import { Edit } from "../../../../components/icons/Edit";
import { StyledCourseItem } from "../../CoursesEditor/CoursesEditor";

const StyledEditButtonContainer = styled("div")`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDeleteButtonContainer = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButtonContainer = styled("div")`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  width: 100%;
  button {
    width: 100%;
  }
`;

const StyledLabel = styled("strong")`
  font-weight: 500;
`;

const StyledCourseStatus = styled("div")`
  margin-top: 0.5rem;
`;

const StyledCourseName = styled("div")`
  margin: 8px 0;
  font-weight: 500;
  font-size: 1.2rem;
  cursor: pointer;
`;

const StyledCourseDescription = styled("div")`
  font-size: 0.9rem;
`;

const StyledEditorCourseName = styled("div")`
  margin-top: 1.8rem;
`;

const StyledEditorCourseDescription = styled("div")`
  margin-top: 0.5rem;

  textarea {
    min-height: 60px;
  }
`;

const StyledCourseLanguagesList = styled("div")`
  display: flex;
  gap: 8px;
`;

const StyledCourseLanguagesListItem = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  box-shadow: var(--input-shadow);
  border-radius: var(--border-radius);
  gap: 8px;
`;

export const CourseItemEditor = ({
  course,
  handleCourseChange,
  isAdmin,
  courseStatuses,
  handleCourseStatusChange,
  handleCourseDelete,
  handleOpenAuthorManagement,
  handleOpenCourseTranslationManagement,
  courseLanguages,
  handleCourseSelect,
}) => {
  const userRole = useSelector((state) => state.userRole);
  const { t, i18n } = useTranslation();
  const [isCourseEditorVisible, setIsCourseEditorVisible] = useState(false);

  return (
    <StyledCourseItem key={course.id}>
      {!isCourseEditorVisible ? (
        <>
          <StyledCourseItemImageContainer>
            <img
              src={"https://picsum.photos/seed/picsum/300"}
              alt=""
              width="400"
              height="400"
              className="course__item__image"
            />
          </StyledCourseItemImageContainer>
          <StyledCourseName onClick={(e) => handleCourseSelect(e, { course_id: course.id })}>
            <strong>{course.name}</strong>
          </StyledCourseName>
          <StyledCourseDescription>{course.description}</StyledCourseDescription>
          <StyledEditButtonContainer onClick={() => setIsCourseEditorVisible(!isCourseEditorVisible)}>
            {/* <IconDelete size={24} /> */}
            <Edit size={22} />
          </StyledEditButtonContainer>
        </>
      ) : (
        <>
          <StyledEditorCourseName>
            <StyledLabel>{t("course.name")}:</StyledLabel>
            <EditableField
              placeholder={t("course.enterName")}
              fontSize={16}
              onTextChange={(name) => handleCourseChange({ name, course_id: course.id })}
              initialText={course.name}
            />
          </StyledEditorCourseName>
          <StyledEditorCourseDescription>
            <StyledLabel>{t("course.description")}:</StyledLabel>
            <EditableField
              placeholder={t("course.enterDescription")}
              fontSize={16}
              onTextChange={(description) => handleCourseChange({ description, course_id: course.id })}
              initialText={course.description}
            />
          </StyledEditorCourseDescription>
          <InputContainer>
            <StyledLabel>{t("course.languages")}:</StyledLabel>
            <StyledCourseLanguagesList>
              {courseLanguages[course.id]?.map((language, idx) => (
                <StyledCourseLanguagesListItem key={idx}>{language}</StyledCourseLanguagesListItem>
              ))}
            </StyledCourseLanguagesList>
          </InputContainer>
          <StyledEditButtonContainer onClick={() => setIsCourseEditorVisible(!isCourseEditorVisible)}>
            {/* <IconDelete size={24} /> */}
            <Edit size={22} />
          </StyledEditButtonContainer>
          {isAdmin() && userRole === USER_ROLES.admin && (
            <>
              <StyledCourseStatus>
                <StyledLabel>{t("course.status")}:</StyledLabel>
                <Select
                  options={courseStatuses}
                  defaultValue={courseStatuses.find((item) => item.value === course.status)}
                  onClick={(status) => handleCourseStatusChange({ status, course_id: course.id })}
                />
              </StyledCourseStatus>
              <StyledDeleteButtonContainer onClick={(e) => handleCourseDelete(e, course.id)}>
                <IconDelete size={24} />
              </StyledDeleteButtonContainer>
              <StyledButtonContainer>
                <Button
                  title={t("button.manageAuthors")}
                  isFilled
                  minWidthUnset
                  onClick={(e) => handleOpenAuthorManagement({ e, course_id: course.id })}
                />
                <Button
                  title={t("button.translate")}
                  isFilled
                  minWidthUnset
                  onClick={(e) => handleOpenCourseTranslationManagement({ e, course })}
                />
              </StyledButtonContainer>
            </>
          )}
        </>
      )}
    </StyledCourseItem>
  );
};

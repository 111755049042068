export const Facebook = ({ size = 30 }) => {
  return (
    <svg
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 15.5388C31.5 6.9614 24.612 0 16.125 0C7.638 0 0.75 6.9614 0.75 15.5388C0.75 23.0596 6.039 29.3218 13.05 30.7669V20.2005H9.975V15.5388H13.05V11.6541C13.05 8.65514 15.4639 6.21554 18.4312 6.21554H22.275V10.8772H19.2C18.3544 10.8772 17.6625 11.5764 17.6625 12.4311V15.5388H22.275V20.2005H17.6625V31C25.4269 30.2231 31.5 23.6035 31.5 15.5388Z"
        fill="black"
      />
    </svg>
  );
};

export const Donate = ({ fill = "black", size = 28 }) => {
  return (
    <svg
      style={{
        height: `${size}px`,
        width: `${size}px`,
        flexShrink: 0,
      }}
      id="_Шар_1"
      data-name="Шар 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 184.82 159.31"
    >
      <path
        fill={fill}
        strokeWidth={0}
        className="cls-1"
        d="m174.87,52.7c-9.64-7.52-21.76-12-34.9-12-31.35,0-56.85,25.5-56.85,56.85,0,18.03,8.43,34.12,21.56,44.54l-17.22,17.22L15.6,87.46l-.72-.73C-4.96,66.9-4.96,34.73,14.88,14.88,24.81,4.97,37.81,0,50.8,0s26,4.97,35.92,14.88l.74.74.72-.74C98.1,4.97,111.11,0,124.1,0s26.01,4.97,35.93,14.88c10.4,10.41,15.35,24.2,14.84,37.82Z"
      />
      <path
        fill={fill}
        strokeWidth={0}
        className="cls-1"
        d="m172.4,66.58c-8.16-8.55-19.67-13.88-32.43-13.88-24.77,0-44.85,20.08-44.85,44.85,0,14.74,7.11,27.82,18.1,36,7.46,5.56,16.72,8.85,26.75,8.85,24.77,0,44.85-20.08,44.85-44.85,0-12.01-4.72-22.93-12.42-30.97Zm-27.39,55.19l-.55.19v7.52h-8.34v-7.32l-.67-.12c-2.93-.54-5.39-1.72-7.28-3.44-2.56-2.33-4.06-5.64-4.28-9.64h11.02c.1.85.31,1.61.62,2.28.85,1.85,2.46,2.96,4.5,2.96,2.77,0,5.02-2.32,5.02-5.17,0-2.04-.92-3.56-2.52-4.8-1.45-1.14-3.45-2.04-5.85-2.9-11.42-4.09-12.38-9.31-12.38-14.3,0-7,3.89-11.84,11.23-13.99l.59-.17v-7.26h8.34v7.3l.63.14c5.88,1.37,9.35,5.88,9.61,12.43h-9.84c-.31-2.43-1.95-4.66-4.83-4.66s-4.8,2.13-4.8,4.95c0,4.15,4.38,6.19,8.68,7.64,2.45.83,4.55,1.89,6.29,3.15,3.81,2.77,5.85,6.54,5.85,11,0,6.45-4.23,11.9-11.04,14.21Z"
      />
    </svg>
  );
};

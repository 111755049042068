export const Account = ({fill = "black", size = 28}) => {
  return (
    <svg style={{width: `${size}px`, height: `${size}px`}} id="_Шар_1" data-name="Шар 1"
         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.83 177.39">

      <path fill={fill} strokeWidth={0}
            d="m30.44,127.83c14.57,9.74,31.71,14.99,49.48,14.99s34.91-5.25,49.48-14.99c7.83,10.11,13.1,22.36,15.29,35.55H15.15c2.19-13.19,7.45-25.45,15.29-35.55m100.49-18.99c-13.42,12.4-31.35,19.98-51.01,19.98s-37.6-7.58-51.01-19.98C11.25,125.18,0,149.82,0,177.39h159.83c0-27.57-11.25-52.21-28.9-68.54h0Z"/>
      <path fill={fill} strokeWidth={0}
            d="m79.92,14c21.82,0,39.58,17.75,39.58,39.58,0,12.4-5.65,23.84-15.5,31.4-6.96,5.34-15.28,8.17-24.08,8.17s-17.13-2.82-24.08-8.18c-9.85-7.56-15.5-19-15.5-31.39,0-21.82,17.75-39.58,39.58-39.58m0-14C50.33,0,26.34,23.98,26.34,53.58c0,17.32,8.22,32.71,20.98,42.5,9.02,6.95,20.33,11.07,32.6,11.07s23.58-4.13,32.61-11.07c12.76-9.79,20.97-25.18,20.97-42.5C133.49,23.98,109.51,0,79.92,0h0Z"/>
    </svg>
  )
}
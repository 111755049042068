import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { useLessonContext } from "../../context/LessonContext";
import { useAuth } from "../../context/AuthContext";

import { styled } from "@mui/system";
import { TextbookEditor } from "./TextbookEditor";
import { TextbookContent } from "./TextbookContent";
import { setIsTextbookPinned } from "../../slices/mainSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const StyledTextbook = styled("div")`
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  transform: translateX(${(props) => (props.isOpened ? "0" : "300px")});
  width: 300px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 500;
  flex-shrink: 0;
  transition: 0.3s;
  background: var(--base-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
  z-index: 10002;
`;

export const StyledTextbookContent = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background: var(--base-color);
`;

export const StyledTextbookIconContainer = styled("div")`
  position: fixed;
  top: 10px;
  left: -60px;
  width: 60px;
  height: 60px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  border-radius: 2px;
  z-index: -1;
  transform: ${(props) => (props.isOpened ? "translateX(100%)" : "translateX(0)")};
`;

export const StyledTextbookHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
  padding: 10px 10px 6px;
  transition: 0.3s;
`;

export const StyledTextbookHeaderTitle = styled("div")`
  font-weight: 700;
  font-size: 18px;
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 5px 10px;
  margin: 0 6px 4px;
  width: calc(100% - 12px);
  transition: background 0.3s;
  border-radius: 5px;
  flex-wrap: nowrap;
  background: ${(props) => props.highlighted && "rgba(255, 255, 255, 0.4)"};

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  &.active {
    background: rgba(255, 255, 255, 0.6);
  }
`;

export const StyledNavLinkTitle = styled("div")`
  pointer-events: none;
  width: calc(100% - 40px);
  transition: 0.3s;
  opacity: ${(props) => (props.isOpened ? "1" : "0")};
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPinContainer = styled("div")`
  @media (max-width: 850px) {
    display: none;
  }
`;

export const Textbook = () => {
  const { hasPriviledgedPersonEnabledEditorMode } = useAuth();
  const { getLessonData } = useLessonContext();
  const isTextbookPinned = useSelector((state) => state.isTextbookPinned);
  const textbookRef = useRef();
  const [isTextbookOpened, setIsTextbookOpened] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 850);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 850);
    };

    const handleClickOutside = (event) => {
      if (window.innerWidth <= 850 && textbookRef.current && !textbookRef.current.contains(event.target)) {
        setIsTextbookOpened(false);
      }
    };
    getLessonData();

    window.addEventListener("resize", handleResize);
    window.addEventListener("click", handleClickOutside);

    // Clean up function
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return hasPriviledgedPersonEnabledEditorMode() ? (
    <TextbookEditor
      ref={textbookRef}
      isTextbookOpened={isTextbookOpened}
      setIsTextbookOpened={setIsTextbookOpened}
      isTextbookPinned={isTextbookPinned}
      setIsTextbookPinned={setIsTextbookPinned}
      isDesktop={isDesktop}
    />
  ) : (
    <TextbookContent
      ref={textbookRef}
      isTextbookOpened={isTextbookOpened}
      setIsTextbookOpened={setIsTextbookOpened}
      isTextbookPinned={isTextbookPinned}
      setIsTextbookPinned={setIsTextbookPinned}
      isDesktop={isDesktop}
    />
  );
};

import axios from "axios";
import { tokenApi } from "./tokenApi";
import { BASE_URL } from "../constants/constants";

const axiosInstanceWithInterceptor = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstanceWithInterceptor.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
  return config;
});

axiosInstanceWithInterceptor.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && (error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        await tokenApi.verify();
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
        return axiosInstanceWithInterceptor(originalRequest);
      } catch (refreshError) {
        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);
export default axiosInstanceWithInterceptor;

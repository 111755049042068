import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ThemeContext } from "./feature/ThemeProvider";

import { SignUp } from "./pages/SignUp";
import { Intro } from "./pages/Intro";
import { Login } from "./pages/Login";

import { LANGUAGES, ROUTES } from "./constants/constants";

import { ProtectedRoutes } from "./utils/ProtectedRoutes";

import classNames from "classnames";

import "./assets/style/fonts/fonts.css";
import "./assets/style/main.css";
import { useAuth } from "./context/AuthContext";
import { Main } from "./pages/Main";
import { Study } from "./pages/Study";
import { Lesson } from "./pages/Lesson";
import { Account } from "./pages/Account";
import { FAQ } from "./pages/FAQ";
import { ResetPassword } from "./pages/ResetPassword";
import { useTranslation } from "react-i18next";
import { NotFound } from "./feature/NotFound";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { USER_ROLES } from "./constants/constants";
import { setEditorStatus, setUserRole } from "./slices/mainSlice";

export const App = () => {
  const [isBugReportFormVisible, toggleBugReportFormVisibility] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { isAuthenticated } = useAuth();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.userRole);
  const { user } = useAuth();

  return (
    <div
      className={classNames("app", { lightTheme: theme === "light" }, { darkTheme: theme === "dark" })}
      // onKeyDown={handleCombinationPress}
    >
      {/*{isBugReportFormVisible && (*/}
      {/*  <BugReportForm*/}
      {/*    toggleBugReportFormVisibility={toggleBugReportFormVisibility}*/}
      {/*  />*/}
      {/*)}*/}
      <Routes>
        <Route path={ROUTES.index} element={isAuthenticated() ? <Navigate to={ROUTES.main} /> : <Intro />} />
        <Route element={<ProtectedRoutes />}>
          <Route path={ROUTES.main} element={<Main />} />
          <Route path={ROUTES.study} element={<Study />} />
          <Route path={ROUTES.lesson} element={<Lesson />} />
          <Route path={ROUTES.account} element={<Account />} />
          <Route path={ROUTES.faq} element={<FAQ />} />
        </Route>
        <Route path={ROUTES.reset} element={<ResetPassword />} />
        <Route path={ROUTES.signUp} element={<SignUp />} />
        <Route path={ROUTES.login} element={isAuthenticated() ? <Navigate to={ROUTES.main} /> : <Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

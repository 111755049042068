import { styled } from "@mui/system";

const StyledContentBlock = styled("div")`
  width: ${(props) => props.width || "auto"};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  padding: 24px;
  background: var(--white);
  // box-shadow: 0 0 10px 2px var(--content-block-shadow-color);
`;

const StyledContentBlockTitle = styled("div")`
  color: var(--black);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

export const ContentBlock = ({ title, width, children }) => {
  return (
    <StyledContentBlock width={width}>
      {title && <StyledContentBlockTitle>{title}</StyledContentBlockTitle>}
      {children}
    </StyledContentBlock>
  );
};

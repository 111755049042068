export const ROUTES = {
  index: "/",
  main: "/main",
  study: "/study",
  course: "/study/:course",
  lesson: "/study/:course/:lesson/*",
  theory: "/study/:course/:lesson/theory",
  homework: "/study/:course/:lesson/homework",
  tests: "/study/:course/:lesson/tests",
  signUp: "/sign-up",
  login: "/login",
  reset: "/reset-password",
  faq: "/faq",
  account: "/account/*",
  accountGeneral: "/account",
  accountAdditional: "/account/additional",
  accountManagement: "/account/management",
};
export const LANGUAGES = {
  uk: "uk",
  en: "en",
};
export const BASE_URL = process.env.REACT_APP_BASE_URL || "http://165.22.78.20/api/v1";

export const BUTTON_STATUSES = {
  save: "save",
  saved: "saved",
  error: "error",
};
export const COURSE_STATUSES = {
  draft: "draft",
  completed: "completed",
};

export const USER_ROLES = {
  teacher: "teacher",
  author: "author",
  student: "student",
  admin: "admin",
};

import { Calendar as IconCalendar } from "../icons/Calendar";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { Input } from "../Input";
import { useTranslation } from "react-i18next";

const StyledDateInput = styled('input')`
  position: absolute;
  left: -1px;
  top: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
  box-sizing: border-box;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`;

const StyledIconCalendarContainerWrapper = styled('div')`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;


const StyledIconCalendarContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;


const StyledValueLine = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

`;


export const DatePicker = ({initialValue, onChange}) => {
    const [value, setValue] = useState(initialValue);
    const {t} = useTranslation();

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleChange = (e) => {
        setValue(e.target.value);
        onChange(e);
    }
    return (
        <StyledValueLine>
            <Input name="birthday" placeholder={t("yyyy-mm-dd")} value={value} onChange={onChange}/>
            <StyledIconCalendarContainerWrapper>
                <StyledIconCalendarContainer>
                    <IconCalendar size={20}/>
                    <StyledDateInput type="date" name="birthday" onChange={handleChange} value={value}/>
                </StyledIconCalendarContainer>
            </StyledIconCalendarContainerWrapper>
        </StyledValueLine>
    );
};


import { Board } from "../../feature/Main/Board";
import { Wrapper } from "../../components/Wrapper";
import { CenteredContainer } from "../../components/CenteredContainer";

export const Main = () => {
  return (
    <Wrapper>
      <CenteredContainer>
        <Board/>
      </CenteredContainer>
    </Wrapper>
  );
};

import { styled } from "@mui/system";

const StyledIntroContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const IntroContainer = ({children}) => {
  return (
    <StyledIntroContainer>
      {children}
    </StyledIntroContainer>
  );
};


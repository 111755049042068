import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useLessonContext } from "../../../context/LessonContext";
import { ROUTES } from "../../../constants/constants";
import { setLessonEducationId, setSelectedLesson } from "../../../slices/mainSlice";

import { Pin as IconPin } from "../../icons/Pin";
import { PinFilled as IconPinFilled } from "../../icons/PinFilled";

import { useTranslation } from "react-i18next";
import { Textbook as IconTextbook } from "../../icons/Textbook";

import {
  StyledTextbook,
  StyledTextbookIconContainer,
  StyledTextbookContent,
  StyledNavLink,
  StyledTextbookHeaderTitle,
  StyledTextbookHeader,
  StyledNavLinkTitle,
  StyledPinContainer,
} from "../Textbook";
import { styled } from "@mui/system";

const StyledDisabledNavLink = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 5px 10px;
  margin: 0 6px 4px;
  width: calc(100% - 12px);
  transition: background 0.3s;
  border-radius: 5px;
  flex-wrap: nowrap;
  opacity: 0.5;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  &.active {
    background: rgba(255, 255, 255, 0.6);
  }
`;

export const TextbookContent = forwardRef(
  ({ isTextbookOpened, setIsTextbookOpened, isTextbookPinned, setIsTextbookPinned, isDesktop }, ref) => {
    const dispatch = useDispatch();
    const [isTextbookHovered, setIsTextbookHovered] = useState(false);

    const [lessonEducationArray, setLessonEducationArray] = useState([]);

    const selectedCourse = useSelector((state) => state.selectedCourse);
    const selectedLesson = useSelector((state) => state.selectedLesson);
    const courseEducationId = useSelector((state) => state.courseEducationId);
    const { getLessonEducationId } = useLessonContext();
    const navigate = useNavigate();
    const { lessons } = useLessonContext();
    const { t } = useTranslation();

    const fetchLessonEducationIdAndArray = async (lesson_id) => {
      const { newLessonEducationId, newLessonEducationArray } = await getLessonEducationId({
        lesson_id,
        course_education_id: courseEducationId,
      });
      console.log(newLessonEducationArray);
      setLessonEducationArray(newLessonEducationArray);
      dispatch(setLessonEducationId(newLessonEducationId));
    };

    const handleLessonSelect = async (event, lesson_id) => {
      event.preventDefault();
      event.stopPropagation();
      if (selectedLesson !== lesson_id) {
        dispatch(setSelectedLesson(lesson_id));
        navigate(ROUTES.study + "/" + selectedCourse + "/" + lesson_id + "/theory");
      }
    };

    const handleMouseOver = () => {
      if (window.innerWidth > 850) {
        setIsTextbookHovered(true);
      }
    };

    const handleMouseOut = () => {
      if (window.innerWidth > 850) {
        setIsTextbookHovered(false);
      }
    };

    useEffect(() => {
      fetchLessonEducationIdAndArray(selectedLesson);
    }, [selectedLesson]);

    return (
      <StyledTextbook
        isOpened={isTextbookHovered || isTextbookPinned}
        ref={ref}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <StyledTextbookContent>
          <StyledTextbookIconContainer
            isOpened={isDesktop ? isTextbookHovered || isTextbookPinned : isTextbookOpened}
            onClick={() => !isDesktop && setIsTextbookOpened(!isTextbookOpened)}
          >
            <IconTextbook />
          </StyledTextbookIconContainer>
          <StyledTextbookHeader isOpened={isDesktop ? isTextbookHovered || isTextbookPinned : isTextbookOpened}>
            <StyledTextbookHeaderTitle>{t("textbook.title")}</StyledTextbookHeaderTitle>
            <StyledPinContainer
              onClick={() => {
                if (isDesktop) {
                  dispatch(setIsTextbookPinned(!isTextbookPinned));
                } else {
                  setIsTextbookOpened(!isTextbookOpened);
                }
              }}
            >
              {isTextbookPinned ? <IconPinFilled /> : <IconPin />}
            </StyledPinContainer>
          </StyledTextbookHeader>
          {lessons.length > 0 &&
            lessons.map((lesson, idx) => {
              if (lessonEducationArray?.find((lessonEducation) => lessonEducation.lesson_id === lesson.id)) {
                return (
                  <StyledNavLink
                    key={lesson.id}
                    onClick={(event) => handleLessonSelect(event, lesson.id, lesson.title)}
                    to={ROUTES.study + "/" + selectedCourse + "/" + lesson.id}
                  >
                    <StyledNavLinkTitle isOpened={isDesktop ? isTextbookHovered || isTextbookPinned : isTextbookOpened}>
                      {lesson.title}
                    </StyledNavLinkTitle>
                  </StyledNavLink>
                );
              } else {
                return (
                  <StyledDisabledNavLink key={idx}>
                    <StyledNavLinkTitle isOpened={isDesktop ? isTextbookHovered || isTextbookPinned : isTextbookOpened}>
                      {lesson.title}
                    </StyledNavLinkTitle>
                  </StyledDisabledNavLink>
                );
              }
            })}
        </StyledTextbookContent>
      </StyledTextbook>
    );
  }
);

export const Instagram = ({size = 30}) => {
  return (
    <svg
      style={{
        height: `${size}px`,
        width: `${size}px`
      }}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9175 0H21.8325C26.7525 0 30.75 4.03 30.75 8.99V22.01C30.75 24.3943 29.8105 26.6809 28.1381 28.3669C26.4658 30.0528 24.1976 31 21.8325 31H8.9175C3.9975 31 0 26.97 0 22.01V8.99C0 6.6057 0.93952 4.31906 2.61188 2.63311C4.28423 0.947158 6.55243 0 8.9175 0ZM8.61 3.1C7.14203 3.1 5.73418 3.68789 4.69616 4.73434C3.65815 5.7808 3.075 7.20009 3.075 8.68V22.32C3.075 25.4045 5.55037 27.9 8.61 27.9H22.14C23.608 27.9 25.0158 27.3121 26.0538 26.2657C27.0919 25.2192 27.675 23.7999 27.675 22.32V8.68C27.675 5.5955 25.1996 3.1 22.14 3.1H8.61ZM23.4469 5.425C23.9566 5.425 24.4454 5.62913 24.8058 5.99248C25.1663 6.35583 25.3687 6.84864 25.3687 7.3625C25.3687 7.87636 25.1663 8.36917 24.8058 8.73252C24.4454 9.09587 23.9566 9.3 23.4469 9.3C22.9372 9.3 22.4483 9.09587 22.0879 8.73252C21.7275 8.36917 21.525 7.87636 21.525 7.3625C21.525 6.84864 21.7275 6.35583 22.0879 5.99248C22.4483 5.62913 22.9372 5.425 23.4469 5.425ZM15.375 7.75C17.4139 7.75 19.3692 8.56651 20.8109 10.0199C22.2526 11.4733 23.0625 13.4446 23.0625 15.5C23.0625 17.5554 22.2526 19.5267 20.8109 20.9801C19.3692 22.4335 17.4139 23.25 15.375 23.25C13.3361 23.25 11.3808 22.4335 9.93912 20.9801C8.49743 19.5267 7.6875 17.5554 7.6875 15.5C7.6875 13.4446 8.49743 11.4733 9.93912 10.0199C11.3808 8.56651 13.3361 7.75 15.375 7.75ZM15.375 10.85C14.1517 10.85 12.9785 11.3399 12.1135 12.212C11.2485 13.084 10.7625 14.2667 10.7625 15.5C10.7625 16.7333 11.2485 17.916 12.1135 18.788C12.9785 19.6601 14.1517 20.15 15.375 20.15C16.5983 20.15 17.7715 19.6601 18.6365 18.788C19.5015 17.916 19.9875 16.7333 19.9875 15.5C19.9875 14.2667 19.5015 13.084 18.6365 12.212C17.7715 11.3399 16.5983 10.85 15.375 10.85Z"
        fill="black"
      />
    </svg>
  );
};


import { useState } from "react";
import { Button } from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { QuizResult } from "./QuizResult";
import { QuizContent } from "./QuizContent";
import { studyApi } from "../../../api/studyApi";
import { setSelectedLesson } from "../../../slices/mainSlice";
import { ROUTES } from "../../../constants/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Error } from "../../../components/Error";

const StyledQuiz = styled("div")`
  width: 100%;
  background: var(--white);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 0 10px 2px var(--content-block-shadow-color);
`;

const StyledButtons = styled("div")`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  button:last-child {
    margin-left: auto;
  }
`;

export const Quiz = ({
  courseEducationId,
  lessonEducationId,
  questions,
  answers,
  lessons,
  selectedLesson,
  selectedCourse,
}) => {
  const [userAnswers, setUserAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isQuizFinished, setIsQuizFinished] = useState(false);

  const { t } = useTranslation();

  const allQuestionsAnswered = () => {
    for (let question of questions) {
      if (!userAnswers[question.id]?.length) {
        return false;
      }
    }
    return true;
  };

  const calculateScore = async () => {
    if (!allQuestionsAnswered()) {
      setError("lesson.tests.result.pleaseAnswerAllQuestionsBeforeSubmittingTheQuiz");
      return;
    }
    setError(null);
    setCurrentQuestionIndex((prev) => prev + 1);
    let score = 0;
    let mistakes = [];
    for (let questionId in userAnswers) {
      const userGivenAnswers = userAnswers[questionId];
      const correctAnswers = answers[questionId].filter((answer) => answer.is_correct).map((a) => a.id);

      const arraysEqual =
        userGivenAnswers.every((val) => correctAnswers.includes(val)) &&
        correctAnswers.every((val) => userGivenAnswers.includes(val));

      if (arraysEqual) {
        score += 1;
      } else {
        mistakes.push(questionId);
      }
    }
    setScore(score);
    setIsQuizFinished(true);
    const testScore = score / questions.length;
    if (testScore > 0.8) {
      studyApi.updateLessonEducation({
        course_education_id: courseEducationId,
        lesson_education_id: lessonEducationId,
        test_max_score: testScore,
        homework_completed: true,
        scroll_completed: true,
      });
    }

    console.log(userAnswers);
    console.log(answers);
  };

  const handleRestart = () => {
    setUserAnswers(0);
    setScore(0);
    setIsQuizFinished(false);
    setCurrentQuestionIndex(0);
  };

  const handleNext = () => {
    const currentLessonIndex = lessons.findIndex((lesson) => lesson.id === selectedLesson);
    const nextLesson = lessons[currentLessonIndex + 1];
    if (nextLesson) {
      navigate(ROUTES.study + "/" + selectedCourse + "/" + nextLesson.id + "/theory");
      dispatch(setSelectedLesson(nextLesson.id));
    } else {
      alert("Course has been completed!");
      navigate(ROUTES.study);
    }
  };

  const RenderButtons = () => {
    if (currentQuestionIndex < questions.length - 1) {
      return <Button title={t("button.next")} onClick={() => setCurrentQuestionIndex((prev) => prev + 1)} isFilled />;
    }
    if (!isQuizFinished && currentQuestionIndex === questions.length - 1) {
      return <Button title={t("button.finish")} onClick={calculateScore} isFilled />;
    }
    if (isQuizFinished && score > 0.8) {
      const currentLessonIndex = lessons.findIndex((lesson) => lesson.id === selectedLesson);

      return (
        <>
          <Button title={t("button.retry")} onClick={handleRestart} isFilled={score !== questions.length} />
          <Button
            title={lessons[currentLessonIndex + 1] ? t("lesson.next") : t("course.finishCourse")}
            isFilled
            onClick={handleNext}
          />
        </>
      );
    } else {
      return (
        <>
          <Button title={t("button.retry")} onClick={handleRestart} isFilled={score !== questions.length} />
          <Button title={t("lesson.next")} disabled />
        </>
      );
    }
  };

  // if (isLoading) return <Loader />;

  return (
    <>
      <StyledQuiz>
        {isQuizFinished ? (
          <QuizResult questions={questions} userAnswers={userAnswers} answers={answers} score={score} />
        ) : (
          <QuizContent
            score={score}
            questions={questions}
            answers={answers}
            currentQuestionIndex={currentQuestionIndex}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
            userAnswers={userAnswers}
            setUserAnswers={setUserAnswers}
            error={error}
            setError={setError}
          />
        )}
        {error && <Error>{t(error)}</Error>}
      </StyledQuiz>
      <StyledButtons>
        <RenderButtons />
      </StyledButtons>
    </>
  );
};

import { styled } from "@mui/system";
import { forwardRef } from "react";

const StyledForm = styled("form")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  background: #f9f8f8;
  padding: 24px;
  background: var(--white);
  min-width: ${(props) => (props.width ? props.width + "px" : "400px")};

  @media (max-width: 850px) {
    min-width: ${(props) => (props.width ? props.width + "px" : "320px")};
  }

  @media (max-width: 360px) {
    min-width: unset;
    width: 100%;
    margin: 0 20px;
  }
`;

export const Form = forwardRef(({ width, children, handleSubmit }, ref) => {
  return (
    <StyledForm ref={ref} onSubmit={handleSubmit} width={width}>
      {children}
    </StyledForm>
  );
});

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/constants";
import { coursesApi } from "../../../api/coursesApi";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { styled } from "@mui/system";
import { Tests } from "../Tests";
import { Tabs } from "../../../components/Tabs";
import { EditableField } from "../../../components/EditableField";
import { TheoryEditor } from "../Theory/TheoryEditor";
import { useLessonContext } from "../../../context/LessonContext";
import { Loader } from "../../../components/Loader";
import { TheoryContent } from "../Theory/TheoryContent";
import { HomeworkContent } from "../Homework/HomeworkContent";
import { HomeworkEditor } from "../Homework/HomeworkEditor/HomeworkEditor";
import { TestsEditor } from "../Editors/TestsEditor/TestsEditor";
import { Quiz } from "../Quiz";

const StyledLessonTabsContainer = styled("div")`
  width: 100%;
  height: 100%;
`;

export const LessonTabs = () => {
  const { hasPriviledgedPersonEnabledEditorMode } = useAuth();
  const selectedCourse = useSelector((state) => state.selectedCourse);
  const selectedLesson = useSelector((state) => state.selectedLesson);
  const courseEducationId = useSelector((state) => state.courseEducationId);
  const lessonEducationId = useSelector((state) => state.lessonEducationId);
  const isEditorModeEnabled = useSelector((state) => state.isEditorModeEnabled);
  const [lessonTitle, setLessonTitle] = useState(null);
  const [areTestsEnabled, setAreTestsEnabled] = useState(false);
  const [isHomeworkEnabled, setIsHomeworkEnabled] = useState(false);
  const { t, i18n } = useTranslation();
  const current_lang = i18n.language;
  const { lessons } = useLessonContext();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  const [theoryContent, setTheoryContent] = useState(null);
  const [currentLessonTranslation, setCurrentLessonTranslation] = useState(null);

  const [homeworkContent, setHomeworkContent] = useState(null);
  const [currentHomeworkId, setCurrentHomeworkId] = useState(null);
  const [currentHomeworkTranslation, setCurrentHomeworkTranslation] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const location = useLocation();

  const tabsContent = [
    {
      link: ROUTES.study + "/" + selectedCourse + "/" + selectedLesson + "/theory",
      title: t("lesson.theory"),
    },
    {
      link: ROUTES.study + "/" + selectedCourse + "/" + selectedLesson + "/homework",
      title: t("lesson.homework"),
      enabled: hasPriviledgedPersonEnabledEditorMode() || isHomeworkEnabled,
    },

    {
      link: ROUTES.study + "/" + selectedCourse + "/" + selectedLesson + "/tests",
      title: t("lesson.tests.title"),
      enabled: hasPriviledgedPersonEnabledEditorMode() || areTestsEnabled,
    },
  ];

  const getLessonData = async () => {
    setIsLoading(true);
    try {
      // lesson translation (theory)
      await fetchTheory();

      // homework
      await fetchHomework();

      // questions
      await fetchQuestions();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to decode HTML entities
  function decodeHTMLEntities(text) {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  }

  const fetchTheory = async () => {
    const newCourseTranslationData = await coursesApi.getCourseTranslations({ course_id: selectedCourse });
    const current_course_translation = newCourseTranslationData.find((course) => course.lang === current_lang);
    if (!current_course_translation) {
      alert("There are no course translation for selected language. Please create one or switch the language");
      navigate(ROUTES.study);
    }

    const newLessonTranslationData = await coursesApi.getLessonTranslations({
      course_id: selectedCourse,
      lesson_id: selectedLesson,
    });
    const current_lesson_translation = newLessonTranslationData.find((lesson) => lesson.lang === current_lang);
    if (!current_lesson_translation && hasPriviledgedPersonEnabledEditorMode()) {
      await coursesApi.addLessonTranslation({
        course_id: selectedCourse,
        lesson_id: selectedLesson,
        lang: current_lang,
        title: t("lesson.new"),
        text: JSON.stringify(t("changeThisText")),
      });
    }
    const decodedString = decodeHTMLEntities(current_lesson_translation.text);
    setTheoryContent(JSON.parse(decodedString));
    setCurrentLessonTranslation(current_lesson_translation);
    const current_lesson_translation_title = current_lesson_translation?.title;
    setLessonTitle(current_lesson_translation_title);
  };

  const fetchHomework = async () => {
    const newHomeworkData = await coursesApi.getHomework({
      course_id: selectedCourse,
      lesson_id: selectedLesson,
    });
    let homework_id = newHomeworkData[0]?.id;
    if (!homework_id && hasPriviledgedPersonEnabledEditorMode()) {
      const newHomework = await coursesApi.addHomework({
        course_id: selectedCourse,
        lesson_id: selectedLesson,
        title: "Homework",
        lang: current_lang,
        text: JSON.stringify(t("changeThisText")),
      });
      homework_id = newHomework.id;
    }
    const homeworkTranslations = await coursesApi.getHomeworkTranslations({
      course_id: selectedCourse,
      lesson_id: selectedLesson,
      independent_work_id: homework_id,
    });

    const current_homework_translation = homeworkTranslations.find((translation) => translation.lang === current_lang);
    const decodedString = decodeHTMLEntities(current_homework_translation.text);
    setIsHomeworkEnabled(!!decodedString.trim().length);

    setHomeworkContent(JSON.parse(decodedString));
    setCurrentHomeworkId(homework_id);
    setCurrentHomeworkTranslation(current_homework_translation);
  };

  const fetchQuestions = async () => {
    const fetchedQuestions = await coursesApi.getQuestions({ course_id: selectedCourse, lesson_id: selectedLesson });
    const newAnswers = {};

    if (fetchedQuestions.length) {
      setAreTestsEnabled(true);

      const answerPromises = fetchedQuestions.map(async (question) => {
        const answers = await coursesApi.getAnswers({
          course_id: selectedCourse,
          lesson_id: selectedLesson,
          question_id: question.id,
        });
        newAnswers[question.id] = answers;
      });

      await Promise.all(answerPromises);
    } else {
      setAreTestsEnabled(false);
    }

    setQuestions(fetchedQuestions);
    setAnswers(newAnswers);
  };

  useEffect(() => {
    if (selectedCourse && selectedLesson) {
      getLessonData();
    }
  }, [selectedLesson, selectedCourse, isEditorModeEnabled]);

  const handleTitleChange = async (value) => {
    try {
      await coursesApi.updateLessonTranslation({
        course_id: selectedCourse,
        lesson_id: selectedLesson,
        translation_id: currentLessonTranslation.id,
        title: value,
        lang: current_lang,
        text: currentLessonTranslation.text,
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <StyledLessonTabsContainer>
      {hasPriviledgedPersonEnabledEditorMode() ? (
        <Tabs
          tabsContent={tabsContent}
          title={<EditableField fontSize={24} onTextChange={handleTitleChange} initialText={lessonTitle} />}
        >
          <Routes>
            <Route
              path="/theory"
              element={
                <TheoryEditor
                  editorContent={theoryContent}
                  setEditorContent={setTheoryContent}
                  selectedCourse={selectedCourse}
                  selectedLesson={selectedLesson}
                />
              }
            />
            <Route
              path="/homework"
              element={
                <HomeworkEditor
                  editorContent={homeworkContent}
                  setEditorContent={setHomeworkContent}
                  currentHomeworkId={currentHomeworkId}
                  currentHomeworkTranslation={currentHomeworkTranslation}
                  selectedCourse={selectedCourse}
                  selectedLesson={selectedLesson}
                />
              }
            />
            <Route
              path="/tests"
              element={
                <TestsEditor
                  questions={questions}
                  setQuestions={setQuestions}
                  answers={answers}
                  setAnswers={setAnswers}
                />
              }
            />
          </Routes>
        </Tabs>
      ) : (
        <Tabs tabsContent={tabsContent} title={lessonTitle}>
          <Routes>
            <Route
              path="/theory"
              element={
                <TheoryContent
                  content={theoryContent}
                  lessons={lessons}
                  areTestsEnabled={areTestsEnabled}
                  courseEducationId={courseEducationId}
                  lessonEducationId={lessonEducationId}
                  selectedCourse={selectedCourse}
                  selectedLesson={selectedLesson}
                  isHomeworkEnabled={isHomeworkEnabled}
                />
              }
            />
            {isHomeworkEnabled && (
              <Route
                path="/homework"
                element={
                  <HomeworkContent
                    lessons={lessons}
                    content={homeworkContent}
                    courseEducationId={courseEducationId}
                    lessonEducationId={lessonEducationId}
                    selectedCourse={selectedCourse}
                    selectedLesson={selectedLesson}
                    areTestsEnabled={areTestsEnabled}
                  />
                }
              />
            )}
            {areTestsEnabled && (
              <Route
                path="/tests"
                element={
                  <Quiz
                    lessons={lessons}
                    selectedCourse={selectedCourse}
                    selectedLesson={selectedLesson}
                    questions={questions}
                    answers={answers}
                    courseEducationId={courseEducationId}
                    lessonEducationId={lessonEducationId}
                  />
                }
              />
            )}
          </Routes>
        </Tabs>
      )}
    </StyledLessonTabsContainer>
  );
};

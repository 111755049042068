import { Wrapper } from "../../components/Wrapper";
import { AvailableCourses } from "../../feature/Study/AvailableCourses";
import { CenteredContainer } from "../../components/CenteredContainer";

export const Study = () => {
  return (
    <Wrapper>
      <CenteredContainer>
        <AvailableCourses/>
      </CenteredContainer>
    </Wrapper>
  );
};

import axiosInstance from "./axiosInstance";
import axiosInstanceWithInterceptor from "./axiosInstanceWithInterceptor";

export const studyApi = {
  createCourseEducation: async ({ course_id, study_language }) => {
    const payload = {
      course_id,
      study_language,
    };
    return await axiosInstanceWithInterceptor.post(`/study/course_education/`, payload);
  },
  getCourseEducation: async ({ course_education_id }) => {
    return await axiosInstanceWithInterceptor.get(`/study/course_education/${course_education_id}/`);
  },
  getCourseEducationList: async () => {
    return await axiosInstanceWithInterceptor.get(`/study/course_education/`);
  },
  createLessonEducation: async ({ course_education_id }) => {
    return await axiosInstanceWithInterceptor.post(`/study/course_education/${course_education_id}/lesson_education/`);
  },
  getLessonEducation: async ({ course_education_id, lesson_education_id }) => {
    return await axiosInstanceWithInterceptor.get(
      `/study/course_education/${course_education_id}/lesson_education/${lesson_education_id}/`
    );
  },
  getLessonEducationList: async ({ course_education_id }) => {
    return await axiosInstanceWithInterceptor.get(`/study/course_education/${course_education_id}/lesson_education/`);
  },

  updateLessonEducation: async ({
    course_education_id,
    lesson_education_id,
    scroll_completed,
    test_max_score,
    homework_completed,
  }) => {
    const payload = {
      scroll_completed,
      test_max_score,
      homework_completed,
    };
    return await axiosInstanceWithInterceptor.put(
      `/study/course_education/${course_education_id}/lesson_education/${lesson_education_id}/`,
      payload
    );
  },
};

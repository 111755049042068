import { styled } from "@mui/system";

const StyledFlex = styled("div")`
  display: flex;
  gap: ${(props) => (props.gap ? props.gap + 'px' : '0')};
`;

export const Flex = ({children, ...props}) => {
  return (
    <StyledFlex {...props}>
      {children}
    </StyledFlex>
  );
};



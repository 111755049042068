import { styled } from "@mui/system";

const StyledWrapper = styled("div")`
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 0 60px 0 132px;
  transition: 0.3s;
  margin-left: -72px;

  @media (max-width: 850px) {
    padding: 60px 15px 0;
    margin-left: 0;
  }
`;
export const Wrapper = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};

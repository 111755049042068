import React, { useEffect, useState } from "react";
import { Button } from "../../../../components/Button";
import { EditableField } from "../../../../components/EditableField";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/system";
import { PlusButton } from "../../../../components/PlusButton";
import { coursesApi } from "../../../../api/coursesApi";
import { AnswersBlock } from "../../AnswersBlock";
import { Delete } from "../../../../components/icons/Delete";
import { Loader } from "../../../../components/Loader";
import { Error } from "../../../../components/Error";

const StyledContainer = styled("div")`
  //border: var(--base-border);
  box-shadow: 0 0 5px 2px var(--content-block-shadow-color);
  background: var(--white);
  padding: 16px;
  border-radius: var(--border-radius);
  width: 100%;
`;

const StyledLine = styled("div")`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledText = styled("div")`
  font-weight: 500;
  padding: 6px 0;
`;

const StyledButtonContainer = styled("div")`
  margin-top: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

const StyledError = styled("div")`
  color: red;
  margin-top: 2px;
  margin-bottom: 5px;
  font-size: 14px;
`;

export const AddQuestionEditor = ({ questions, setQuestions, answers, setAnswers, course_id, lesson_id }) => {
  const { t, i18n } = useTranslation();

  const initialQuestionPlaceholder = t("lesson.tests.question.placeholder");
  const initialAnswerPlaceholder = t("lesson.tests.answer.placeholder");
  const initialAnswers = [
    { text: "", is_correct: false, id: uuidv4(), is_new: true },
    { text: "", is_correct: false, id: uuidv4(), is_new: true },
  ];
  const [question, setQuestion] = useState("");
  const [editorAnswers, setEditorAnswers] = useState(initialAnswers);

  const current_lang = i18n.language;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [questionError, setQuestionError] = useState(null);
  const [answersError, setAnswersError] = useState(null);

  const [isQuestionEditorVisible, setIsQuestionEditorVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleQuestionTextChange = (value) => {
    setQuestion(value);
  };

  const handleAnswerTextChange = (value, id) => {
    const newAnswers = [...editorAnswers];
    const index = newAnswers.findIndex((answer) => answer.id === id);
    newAnswers[index].text = value;
    setEditorAnswers(newAnswers);
  };

  const handleAnswerDelete = (id) => {
    const newAnswers = editorAnswers.filter((answer) => answer.id !== id);
    setEditorAnswers(newAnswers);
  };

  const handleCheckboxClick = (id) => {
    const newAnswers = [...editorAnswers];
    const index = newAnswers.findIndex((answer) => answer.id === id);
    newAnswers[index].is_correct = !newAnswers[index].is_correct;
    setEditorAnswers(newAnswers);
  };

  const handleSave = async () => {
    if (!question) {
      setQuestionError("Питання не може бути порожнім");
      return;
    } else {
      setQuestionError(null);
    }
    if (editorAnswers.length < 2) {
      setAnswersError("Кількість відповідей має бути більше або дорівнювати двом");
      return;
    }
    if (editorAnswers.some((answer) => !answer.text)) {
      setAnswersError("Текст відповіді не може бути порожнім");
      return;
    }
    if (editorAnswers.some((answer) => !answer.is_correct)) {
      setAnswersError("Виберіть хоча б одну правильну відповідь");
      return;
    }
    setAnswersError(null);

    setIsButtonLoading(true);
    setIsLoading(true);
    try {
      const newQuestion = await coursesApi.addQuestion({
        course_id,
        lesson_id,
        lang: current_lang,
        text: question,
      });
      const newAnswers = editorAnswers.filter((answer) => answer?.is_new);
      const answersToAppend = [];
      const answerPromises = newAnswers.map(async (answer) => {
        const newAnswer = await coursesApi.addAnswer({
          course_id,
          lesson_id,
          question_id: newQuestion.id,
          lang: current_lang,
          text: answer.text,
          is_correct: answer.is_correct,
        });
        answersToAppend.push(newAnswer);
      });
      await Promise.all(answerPromises);
      setQuestions([...questions, { text: newQuestion.text, id: newQuestion.id }]);
      setAnswers((prev) => ({ ...prev, [newQuestion.id]: answersToAppend }));

      setQuestion("");
      setEditorAnswers(initialAnswers);
      setIsQuestionEditorVisible(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsButtonLoading(false);
      setIsLoading(false);
    }
  };

  const handleAddAnswer = () => {
    if (editorAnswers.length >= 2) {
      setAnswersError(null);
    }
    setEditorAnswers((prev) => [...prev, { text: "", is_correct: false, id: uuidv4(), is_new: true }]);
  };

  const handleCancel = () => {
    setIsQuestionEditorVisible(false);
    setQuestion("");
    setEditorAnswers(initialAnswers);
    setAnswersError(null);
    setQuestionError(null);
  };

  if (isLoading) return <Loader />;

  return (
    <div>
      {!isQuestionEditorVisible && (
        <Button title={t("lesson.tests.question.add")} onClick={() => setIsQuestionEditorVisible(true)} isFilled />
      )}
      {isQuestionEditorVisible && (
        <StyledContainer>
          <StyledLine>
            <StyledText>{t(`lesson.tests.question.title`)}:</StyledText>
            <Delete size={27} onClick={() => setIsQuestionEditorVisible(false)} />
          </StyledLine>

          <EditableField
            placeholder={initialQuestionPlaceholder}
            onTextChange={handleQuestionTextChange}
            initialText={question}
          />
          {questionError && <Error>{questionError}</Error>}
          <StyledText>{t(`lesson.tests.answer.answers`)}:</StyledText>
          {editorAnswers?.map((answer) => (
            <AnswersBlock
              key={answer.id}
              placeholder={initialAnswerPlaceholder}
              handleCheckboxClick={() => handleCheckboxClick(answer.id)}
              handleAnswerTextChange={(value) => handleAnswerTextChange(value, answer.id)}
              handleAnswerDelete={() => handleAnswerDelete(answer.id)}
              answer={answer}
            />
          ))}
          {answersError && <Error>{answersError}</Error>}
          <PlusButton title={t("lesson.tests.answer.add")} onClick={handleAddAnswer} />
          <StyledButtonContainer>
            <Button onClick={handleSave} title={t(`button.save`)} isLoading={isButtonLoading} isFilled />
            <Button title={t("button.cancel")} onClick={handleCancel} />
          </StyledButtonContainer>
        </StyledContainer>
      )}
    </div>
  );
};

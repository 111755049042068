import { styled } from "@mui/system";

const StyledTextarea = styled("textarea")`
  padding: 6px 8px;
  color: var(--black);
  //box-shadow: var(--black-shadow);
  //border: 1px solid var(--input-shadow);
  min-height: 2rem;
  box-shadow: var(--input-shadow);
  border-radius: 4px;
  font-weight: 400;
  width: 100%;
  font-size: ${(props) => props.fontSize + "px"};
  background: var(--white);
  word-break: break-all;
  outline: none;
  border: none;
  resize: vertical;

  &:focus {
    outline: none;
    //border: 1px solid var(--input-shadow);
  }

  &::placeholder {
    color: #9a98a0;
    font-size: 1rem;
  }

  @media (max-width: 850px) {
    font-size: 0.9rem;
    &::placeholder {
      color: #9a98a0;
      font-size: 0.9rem;
    }
  }
`;

export const Textarea = ({
  fontSize = 16,
  autoFocus,
  type,
  value,
  onChange,
  onClick,
  onBlur,
  onFocus,
  onKeyDown,
  name,
  placeholder,
}) => {
  return (
    <StyledTextarea
      rows={1}
      autoFocus={autoFocus}
      type={type}
      fontSize={fontSize}
      value={value}
      onChange={onChange}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      placeholder={placeholder}
    />
  );
};

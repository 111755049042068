export const Edit = ({ fill = "black", size = 20, onClick }) => {
  return (
    <svg
      style={{
        height: `${size}px`,
        width: `${size}px`,
        cursor: "pointer",
      }}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 443"
      fill="none"
    >
      <path
        d="M212.5 42.999H50C36.1929 42.999 25 54.1919 25 67.999V392.999C25 406.806 36.1929 417.999 50 417.999H375C388.807 417.999 400 406.806 400 392.999V230.499"
        stroke="black"
        stroke-width="50"
      />
      <path
        d="M166.114 225.574L216.188 277.696L154.421 292.927C150.684 293.848 147.35 290.378 148.421 286.681L166.114 225.574Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M316.1 81.7153L166.353 225.828L216.306 277.734L366.053 133.622L316.1 81.7153ZM394.901 105.86L437.51 64.8541C449.448 53.3651 449.813 34.3736 438.324 22.4355L429.975 13.7605C418.486 1.82236 399.495 1.45823 387.557 12.9472L344.947 53.9535L394.901 105.86Z"
        fill="black"
      />
    </svg>
  );
};

import { PlusButton } from "../PlusButton";
import { useTranslation } from "react-i18next";
import { CreateLessonForm } from "../../feature/Forms/CreateLessonForm";
import { useLessonContext } from "../../context/LessonContext";

export const CreateLessonBlock = ({
  isButtonVisible = false,
  onClick,
  isCreateLessonFormVisible,
  toggleCreateLessonFormVisibility,
}) => {
  const { getLessonData } = useLessonContext();

  const { t } = useTranslation();

  return (
    <>
      {isCreateLessonFormVisible && (
        <CreateLessonForm
          getLessonData={getLessonData}
          toggleCreateLessonFormVisibility={toggleCreateLessonFormVisibility}
        />
      )}
      {isButtonVisible && <PlusButton title={t("course.create")} onClick={onClick} />}
    </>
  );
};

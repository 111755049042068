import { useState } from "react";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import { userApi } from "../../../api/userApi";
import { FormContainer } from "../../../components/Form/Container";
import { Form } from "../../../components/Form/Form";
import { PrimaryTitle } from "../../../components/PrimaryTitle";
import { InputContainer } from "../../../components/InputContainer";
import { styled } from "@mui/system";
import { Eye as EyeIcon } from "../../../components/icons/Eye";

const StyledButtonContainer = styled("div")`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 260px;
  width: 100%;

  @media (max-width: 850px) {
    margin: 6px 0 0 0;
  }
`;

const StyledEyeWrapper = styled("div")`
  position: absolute;
  right: 6px;
  bottom: 7px;
  cursor: pointer;
  height: 22px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &::after {
    position: absolute;
    content: "";
    right: 1px;
    bottom: 10px;
    height: 1px;
    transform: rotate(45deg);
    width: 26px;
    background: var(--black);
    transition: opacity 0.3s;
    opacity: ${(props) => (props.pressed ? "1" : "0")};
  }
`;

export const SignUpForm = () => {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    password_confirm: "",
  });
  const [errors, setErrors] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    try {
      const data = await userApi.register({
        username: formValues.username,
        email: formValues.email,
        password: formValues.password,
        password_confirm: formValues.password_confirm,
        language_default: i18n.language,
      });
      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      navigate(ROUTES.main);
    } catch (errors) {
      console.log(errors);
      setErrors(errors);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const passwordInputTypes = {
    text: "text",
    password: "password",
  };

  const [passwordInputType, setPasswordInputType] = useState(passwordInputTypes.password);

  const handleInputTypeChange = () => {
    setPasswordInputType(
      passwordInputType === passwordInputTypes.password ? passwordInputTypes.text : passwordInputTypes.password
    );
  };

  return (
    <FormContainer>
      <Form>
        <PrimaryTitle value={t("form.register")} />
        <InputContainer title={t("form.emailOrPhone.title")} error={errors?.email}>
          <Input
            type="email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            placeholder={t("form.emailOrPhone.placeholder")}
          />
        </InputContainer>
        <InputContainer title={t("form.password")} error={errors?.password || errors?.non_field_errors}>
          <Input
            type={passwordInputType}
            name="password"
            value={formValues.password}
            onChange={handleInputChange}
            placeholder={t("form.password")}
          />
          <StyledEyeWrapper pressed={passwordInputType === passwordInputTypes.text} onClick={handleInputTypeChange}>
            <EyeIcon size={24} />
          </StyledEyeWrapper>
        </InputContainer>

        <InputContainer title={t("form.repeatPassword")} error={errors?.password_confirm}>
          <Input
            type={passwordInputType}
            name="password_confirm"
            value={formValues.password_confirm}
            onChange={handleInputChange}
            placeholder={t("form.repeatPassword")}
          />
          <StyledEyeWrapper pressed={passwordInputType === passwordInputTypes.text} onClick={handleInputTypeChange}>
            <EyeIcon size={24} />
          </StyledEyeWrapper>
        </InputContainer>
        <StyledButtonContainer>
          <Button title={t("form.register")} onClick={handleSubmit} isLoading={isButtonLoading} isFilled />
          <Button title={t("button.cancel")} onClick={() => navigate(ROUTES.index)} />
        </StyledButtonContainer>
        <div>
          <span>{t("form.alreadyHaveAnAccount")}&nbsp;</span>
          <Link to={ROUTES.login} style={{ color: "#185fe9" }}>
            {t("form.login")}
          </Link>
        </div>
      </Form>
    </FormContainer>
  );
};

export const PinFilled = ({ fill = "black", size = 30 }) => {
  return (
    <svg
      style={{ width: `${size}px`, height: `${size}px`, flexShrink: 0, cursor: "pointer" }}
      id="_Шар_1"
      data-name="Шар 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 159.83 159.83"
    >
      <path
        strokeWidth={0}
        fill={fill}
        d="M79.91,0C35.77,0,0,35.77,0,79.91s35.77,79.92,79.91,79.92,79.92-35.79,79.92-79.92S124.05,0,79.91,0ZM119.22,76.54l-8.02-8.03-12.09,12.1c4.49,12.05,1.9,26.14-7.79,35.82l-20.14-20.14-32.51,22.56,23.78-31.29-19.06-19.06c9.68-9.69,23.77-12.28,35.83-7.79l12.09-12.09-8.03-8.02,10.43-10.43,35.94,35.94-10.43,10.43Z"
      />
    </svg>
  );
};

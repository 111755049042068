export const Help = ({size = 28, fill = "black"}) => {
  return (
    <svg style={{
      height: `${size}px`,
      width: `${size}px`,
    }} id="_Шар_1" data-name="Шар 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 161.26 159.83">
      <path fill={fill} strokeWidth={0}
            d="m79.91,0C35.77,0,0,35.77,0,79.91s35.77,79.92,79.91,79.92c26.99,0,50.84-13.4,65.31-33.9,10.04-12.64,16.04-28.62,16.04-46.02s-5.97-33.29-15.96-45.91C130.84,13.44,106.95,0,79.91,0Zm54.35,117.23l-.25.31-.22.32c-12.37,17.51-32.51,27.97-53.88,27.97-36.34,0-65.91-29.57-65.91-65.92S43.57,14,79.91,14c21.42,0,41.59,10.49,53.94,28.05l.23.32.24.31c8.47,10.71,12.94,23.58,12.94,37.23s-4.5,26.6-13,37.32Z"/>
      <rect fill={fill} strokeWidth={0} x="70.16" y="107.83" width="18.07" height="17.4"/>
      <path fill={fill} strokeWidth={0}
            d="m82.18,34.6c-15.59,0-31.36,9.72-32.56,28.32h16.44c1.17-9.15,6.1-13.79,14.68-13.79s13.37,5.34,13.37,13.94c0,7.55-4.86,13.49-13.83,21.81-5.44,5.16-8.73,9.78-10.11,14.27h18.07c1.87-5.12,6.14-9.7,12.56-15.52,10.08-8.9,10.86-15.39,10.86-23.58,0-12.23-11.27-25.44-29.47-25.44Z"/>
    </svg>
  )
};


import { styled } from "@mui/system";
import { Error } from "../Error/Error";

const StyledInputContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 4px;
  width: 100%;
  max-width: ${(props) => (props.fullWidth ? "100%" : "260px")};
  user-select: none;
  position: relative;

  @media (max-width: 850px) {
    font-size: 0.9rem;
  }
`;

export const InputContainer = ({ title, children, error, fullWidth }) => {
  return (
    <StyledInputContainer fullWidth={fullWidth}>
      <div>{title}</div>
      {children}
      {error && <Error>{error}</Error>}
    </StyledInputContainer>
  );
};

import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "../../../constants/constants";
import { setIsNavMenuPinned } from "../../../slices/mainSlice";

import { Home as IconHome } from "../../icons/Home";
import { HomeFilled as IconHomeFilled } from "../../icons/HomeFilled";

import { Pin as IconPin } from "../../icons/Pin";
import { PinFilled as IconPinFilled } from "../../icons/PinFilled";

import { Study as IconStudy } from "../../icons/Study";
import { StudyFilled as IconStudyFilled } from "../../icons/StudyFilled";

import { Account as IconAccount } from "../../icons/Account";
import { AccountFilled as IconAccountFilled } from "../../icons/AccountFilled";

import { Help as IconHelp } from "../../icons/Help";
import { HelpFilled as IconHelpFilled } from "../../icons/HelpFilled";

import { Settings as IconSettings } from "../../icons/Settings";
import { SettingsFilled as IconSettingsFilled } from "../../icons/SettingsFilled";

import { Email as IconEmail } from "../../icons/Email";
import { EmailFilled as IconEmailFilled } from "../../icons/EmailFilled";

import { Donate as DonateIcon } from "../../icons/Donate";
import { DonateFilled as DonateIconFilled } from "../../icons/DonateFilled";

import { Bug as IconBug } from "../../icons/Bug";

import { Logout as IconLogout } from "../../icons/Logout";

import { styled } from "@mui/system";
import { NavMenuLink } from "../../NavMenuLink";
import { Logo, LogoWithText } from "../../icons/LogoWithText";
import { useSelector } from "react-redux";

const StyledNavMenu = styled("div")`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: ${(props) => (props.isOpened ? "270px" : "72px")};
  flex-shrink: 0;
  transition: 0.3s;
  overflow: hidden;
  background: var(--base-color);
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
`;

const StyledNavMenuInner = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledNavMenuSections = styled("div")`
  margin-top: 40px;
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const StyledPinContainer = styled("div")`
  position: absolute;
  top: 10px;
  right: 10px;
  appearance: ${(props) => (props.isOpened ? "unset" : "none")};
  user-select: ${(props) => (props.isOpened ? "unset" : "none")};
  opacity: ${(props) => (props.isOpened ? "1" : "0")};
  cursor: pointer;
  transition: 0.2s;
  @media (max-width: 850px) {
    display: none;
  }
`;

const StyledLogoContainer = styled("div")`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 10px;
  padding: 10px;
  transition: 0.3s;

  svg {
    flex-shrink: 0;
  }
`;

const StyledLogoutContainer = styled("div")`
  margin-top: auto;
  margin-bottom: 6px;
`;

export const NavigationDesktop = ({
  setWasNavMenuPinned,
  t,
  dispatch,
  location,
  areSettingsVisible,
  areContactsVisible,
  isSupportProjectPopupVisible,
  handleOpenSettings,
  handleOpenContacts,
  handleOpenSupportProjectPopup,
  handleLogout,
  arePopupsActive,
}) => {
  const navMenuRef = useRef();

  const isNavMenuPinned = useSelector((state) => state.isNavMenuPinned);
  const [isNavMenuHovered, setIsNavMenuHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsNavMenuHovered(true);
  };

  const handleMouseLeave = () => {
    setIsNavMenuHovered(false);
  };

  return (
    <>
      <StyledNavMenu
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isOpened={isNavMenuHovered || isNavMenuPinned}
        ref={navMenuRef}
      >
        <StyledNavMenuInner>
          <StyledPinContainer
            isOpened={isNavMenuHovered || isNavMenuPinned}
            onClick={() => {
              dispatch(setIsNavMenuPinned(!isNavMenuPinned));
              setWasNavMenuPinned(!isNavMenuPinned);
            }}
          >
            {isNavMenuPinned ? <IconPinFilled /> : <IconPin />}
          </StyledPinContainer>
          <Link to={ROUTES.main}>
            <StyledLogoContainer isOpened={isNavMenuHovered || isNavMenuPinned}>
              <LogoWithText isTextVisible={isNavMenuHovered || isNavMenuPinned} height={50} />
            </StyledLogoContainer>
          </Link>

          <StyledNavMenuSections>
            <NavMenuLink
              title={t("pages.home")}
              to={ROUTES.main}
              active={!arePopupsActive() && location.pathname.includes(ROUTES.main)}
              icon={{ active: <IconHomeFilled />, inactive: <IconHome /> }}
              isOpened={isNavMenuHovered || isNavMenuPinned}
            />
            <NavMenuLink
              title={t("pages.study")}
              active={!arePopupsActive() && location.pathname.includes(ROUTES.study)}
              to={ROUTES.study}
              icon={{ active: <IconStudyFilled />, inactive: <IconStudy /> }}
              isOpened={isNavMenuHovered || isNavMenuPinned}
            />

            <NavMenuLink
              active={!arePopupsActive() && location.pathname.includes(ROUTES.accountGeneral)}
              title={t("pages.account")}
              to={ROUTES.accountGeneral}
              icon={{ active: <IconAccountFilled />, inactive: <IconAccount /> }}
              isOpened={isNavMenuHovered || isNavMenuPinned}
            />
            <NavMenuLink
              title={t("settings.title")}
              active={areSettingsVisible}
              icon={{ active: <IconSettingsFilled />, inactive: <IconSettings /> }}
              onClick={(e) => {
                handleOpenSettings(e);
                dispatch(setIsNavMenuPinned(true));
              }}
              isOpened={isNavMenuHovered || isNavMenuPinned}
            />
            <NavMenuLink
              title={t("pages.faq")}
              active={location.pathname.includes(ROUTES.faq)}
              to={ROUTES.faq}
              icon={{ active: <IconHelpFilled />, inactive: <IconHelp /> }}
              isOpened={isNavMenuHovered || isNavMenuPinned}
            />
            <NavMenuLink
              title={t("pages.contacts")}
              active={areContactsVisible}
              icon={{ active: <IconEmailFilled />, inactive: <IconEmail /> }}
              onClick={(e) => {
                handleOpenContacts(e);
                dispatch(setIsNavMenuPinned(true));
              }}
              isOpened={isNavMenuHovered || isNavMenuPinned}
            />
            <NavMenuLink
              title={t("bugReport")}
              icon={{ active: <IconBug />, inactive: <IconBug /> }}
              isOpened={isNavMenuHovered || isNavMenuPinned}
            />
            <NavMenuLink
              title={t("donate")}
              active={isSupportProjectPopupVisible}
              icon={{ active: <DonateIcon />, inactive: <DonateIconFilled /> }}
              onClick={(e) => {
                handleOpenSupportProjectPopup(e);
                dispatch(setIsNavMenuPinned(true));
              }}
              isOpened={isNavMenuHovered || isNavMenuPinned}
            />
          </StyledNavMenuSections>
          <StyledLogoutContainer>
            <NavMenuLink
              title={t("button.logout")}
              onClick={handleLogout}
              icon={{ active: <IconLogout />, inactive: <IconLogout /> }}
              isOpened={isNavMenuHovered || isNavMenuPinned}
            />
          </StyledLogoutContainer>
        </StyledNavMenuInner>
      </StyledNavMenu>
    </>
  );
};

import { Checkbox } from "../../../../components/Checkbox";
import { styled } from "@mui/system";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const StyledQuizHeader = styled("div")`
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 30px;
`;

const StyledDot = styled("div")`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: ${(props) => (props.isActive ? "0 0 0 2px #c0e1a5, inset 0 0 0 3px #c0e1a5" : "0 0 0 2px #c0e1a5")};
  cursor: pointer;
  background: ${(props) => props.isCompleted && "#c0e1a5"};
  background: ${(props) => props.isActive && "var(--white) !important"};
`;

const StyledQuestion = styled("div")`
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 500;
`;

const StyledAnswer = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  user-select: none;
`;

export const QuizContent = ({
  currentQuestionIndex,
  setCurrentQuestionIndex,
  questions,
  answers,
  userAnswers,
  setUserAnswers,
  error,
  setError,
}) => {
  const { t } = useTranslation();

  const handleAnswer = (questionId, answerId) => {
    if (error) {
      setError(null);
    }
    setUserAnswers((prevAnswers) => {
      const currentAnswers = prevAnswers[questionId] || [];

      if (currentAnswers.includes(answerId)) {
        return {
          ...prevAnswers,
          [questionId]: currentAnswers.filter((id) => id !== answerId),
        };
      } else {
        return {
          ...prevAnswers,
          [questionId]: [...currentAnswers, answerId],
        };
      }
    });
  };

  return (
    <>
      <StyledQuizHeader>
        {questions.map((question, index) => (
          <StyledDot
            key={question.id}
            isCompleted={userAnswers[question.id]?.length > 0}
            isActive={index === currentQuestionIndex}
            onClick={() => setCurrentQuestionIndex(index)}
          />
        ))}
      </StyledQuizHeader>
      <StyledQuestion>{questions[currentQuestionIndex]?.text}</StyledQuestion>
      {answers[questions[currentQuestionIndex]?.id]?.map((answer) => (
        <StyledAnswer
          id={answer.id}
          key={answer.id}
          onClick={() => handleAnswer(questions[currentQuestionIndex]?.id, answer.id)}
        >
          <Checkbox
            onChange={() => handleAnswer(questions[currentQuestionIndex]?.id, answer.id)}
            checked={!!userAnswers[questions[currentQuestionIndex]?.id]?.includes(answer.id)}
          />
          {answer.text}
        </StyledAnswer>
      ))}
    </>
  );
};

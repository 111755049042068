import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { ROUTES } from "../../../constants/constants";
import { useTranslation } from "react-i18next";
import { Logo } from "../../../components/icons/Logo";
import { styled } from "@mui/system";

const StyledHeader = styled('header')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  padding: 30px 80px;
  background: var(--base-color);
  box-shadow: var(--black-shadow);

  @media (max-width: 1100px) {
    padding: 20px 30px;
  }
`;

const StyledLogoContainer = styled('div')`
  width: 240px;
  height: 60px;

  @media (max-width: 850px) {
    height: 50px;
    width: 50px;
    g:nth-of-type(2) {
      display: none;
    }
  }

`;

const StyledButtons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  & button {
    box-shadow: none;
  }
`;

export const IntroHeader = () => {
  const {t} = useTranslation();
  return (
    <StyledHeader>
      <StyledLogoContainer>
        <Logo isTextVisible/>
      </StyledLogoContainer>
      <StyledButtons>
        <Link to={ROUTES.signUp}>
          <Button title={t("form.register")}/>
        </Link>
        <Link to={ROUTES.login}>
          <Button title={t("form.login")}/>
        </Link>
      </StyledButtons>
    </StyledHeader>
  );
};

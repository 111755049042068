import { useEffect, useState } from "react";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { Select } from "../../../components/Select";
import { ConfirmationForm } from "../../Forms/ConfirmationForm";
import { InputContainer } from "../../../components/InputContainer";
import { ContentBlock } from "../../../components/ContentBlock";
import { Title } from "../../../components/Title";
import { styled } from "@mui/system";
import { userApi } from "../../../api/userApi";
import { useNavigate } from "react-router-dom";
import { BUTTON_STATUSES, ROUTES } from "../../../constants/constants";
import { DatePicker } from "../../../components/DatePicker";

const StyledButtonContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 1100px) {
    & > * {
      width: 100%;
    }
  }
`;

const StyledPersonalInfo = styled("div")`
  position: relative;
  max-width: 420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PersonalInfo = ({ user, areObjectsSimilar }) => {
  const [statusText, setStatusText] = useState(`button.${BUTTON_STATUSES.save}`);
  const { i18n } = useTranslation();

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    username: "",
    age: "",
    birthday: "",
    sex: "",
  });

  const [initialFormValues, setInitialFormValues] = useState({
    first_name: "",
    last_name: "",
    username: "",
    age: "",
    birthday: "",
    sex: "",
  });

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmationFormVisible, toggleConfirmationFormVisibility] = useState(false);
  const navigate = useNavigate();

  const genderOptions = [
    { value: "M", label: t("account.gender.m") },
    {
      value: "F",
      label: t("account.gender.f"),
    },
    { value: "", label: t("account.gender.other") },
  ];

  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleConfirmationFormVisibility(true);
  };

  const handleLogoutSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      userApi.logout();
      navigate(ROUTES.index);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSelect = (value) => {
    setFormValues({
      ...formValues,
      sex: value,
    });

    setErrors({
      ...errors,
      sex: "",
    });
  };

  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  const handleSave = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      setErrors({});
      await userApi.updateUserInfo({ ...formValues, language_default: i18n.language });
      setStatusText(`button.${BUTTON_STATUSES.saved}`);
      setTimeout(() => {
        setStatusText(`button.${BUTTON_STATUSES.save}`);
      }, 2000);
    } catch (error) {
      setStatusText(`button.${BUTTON_STATUSES.error}`);
      setTimeout(() => {
        setStatusText(`button.${BUTTON_STATUSES.save}`);
      }, 2000);
      if (error.response.data) {
        setErrors(error.response.data);
      }
      if (error.response.data.profile) {
        setErrors(error.response.data.profile);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setFormValues({
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        age: user?.profile?.age || "",
        birthday: user?.profile?.birthday || "",
        sex: user?.profile?.sex || "",
        username: user?.username || "",
      });
      setInitialFormValues({
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        age: user?.profile?.age || "",
        birthday: user?.profile?.birthday || "",
        sex: user?.profile?.sex || "",
        username: user?.username || "",
      });
    }
  }, []);

  return (
    <>
      {isConfirmationFormVisible && (
        <ConfirmationForm
          handleSubmit={handleLogoutSubmit}
          isLoading={isLoading}
          toggleConfirmationFormVisibility={toggleConfirmationFormVisibility}
        />
      )}

      <StyledPersonalInfo>
        <ContentBlock>
          <Title value={t("account.title")} />
          <InputContainer title={t("account.username")} error={errors?.username} fullWidth>
            <Input
              type="text"
              name="username"
              value={formValues.username}
              onChange={handleInputChange}
              placeholder="john.doe"
            />
          </InputContainer>
          <InputContainer title={t("account.name")} error={errors?.first_name} fullWidth>
            <Input
              type="text"
              name="first_name"
              value={formValues.first_name}
              onChange={handleInputChange}
              placeholder="John"
            />
          </InputContainer>
          <InputContainer title={t("account.surname")} error={errors?.last_name} fullWidth>
            <Input
              type="text"
              name="last_name"
              value={formValues.last_name}
              onChange={handleInputChange}
              placeholder="Gult"
            />
          </InputContainer>
          <InputContainer title={t("account.age")} error={errors?.age} fullWidth>
            <Input type="number" name="age" value={formValues.age} onChange={handleInputChange} placeholder="18" />
          </InputContainer>
          <InputContainer title={t("account.birthday")} error={errors?.birthday} fullWidth>
            <DatePicker initialValue={formValues.birthday} onChange={handleInputChange} />
          </InputContainer>
          <InputContainer title={t("account.gender.title")} error={errors?.sex} fullWidth>
            <Select
              defaultValue={{
                value: formValues.sex,
                label: genderOptions.find((option) => option.value === formValues?.sex)?.label,
              }}
              options={genderOptions}
              onClick={handleSelect}
            />
          </InputContainer>
        </ContentBlock>
        <StyledButtonContainer>
          <Button
            isLoading={isLoading}
            title={t(statusText)}
            disabled={areObjectsSimilar(formValues, initialFormValues)}
            onClick={handleSave}
            isFilled
          />
          <Button title={t("button.logout")} onClick={handleLogout} />
        </StyledButtonContainer>
      </StyledPersonalInfo>
    </>
  );
};

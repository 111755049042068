import { userApi } from "./userApi";
import axiosInstance from "./axiosInstance";
import { setItem } from "../utils/storage";

export const tokenApi = {
  verify: async () => {
    try {
      const payload = {
        token: localStorage.getItem("accessToken"),
      };
      await axiosInstance.post(`/token/verify/`, payload);
    } catch (error) {
      const status = error.response.status;
      if (status === 401 || status === 403) {
        await tokenApi.refresh();
      } else if (status === 400) {
        await userApi.logout();
      }
    }
  },

  refresh: async () => {
    try {
      const payload = {
        refresh: localStorage.getItem("refreshToken"),
      };
      const response = await axiosInstance.post(`/token/refresh/`, payload);
      setItem("accessToken", response.data.access);
    } catch (error) {
      console.log("Error : " + error);
      const status = error.response.status;
      if (status === 400 || status === 401) {
        await userApi.logout();
      }
    }
  },
};

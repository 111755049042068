import { style, styled } from "@mui/system";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/constants";
import { useTranslation } from "react-i18next";

const StyledWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledContent = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled("h1")`
  font-weight: 700;
  font-size: clamp(5rem, 9rem, 9vw);
`;

const StyledText = styled("div")`
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
`;
export const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledContent>
        <StyledTitle>404</StyledTitle>
        <StyledText>{t("notFound.thePageYouAreLookingForDoesNotExist")}</StyledText>
        <Button title={t("button.returnToHomepage")} isFilled onClick={() => navigate(ROUTES.main)} />
      </StyledContent>
    </StyledWrapper>
  );
};

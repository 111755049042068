export const DonateFilled = ({ fill = "black", size = 28 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        height: `${size}px`,
        width: `${size}px`,
        flexShrink: 0,
      }}
      viewBox="0 0 185 160"
      fill="none"
    >
      <g clipPath="url(#clip0_1546_1093)">
        <path
          d="M50.8 5C62.5161 5 74.2395 9.47956 83.1862 18.4173L83.9245 19.1555L87.5088 22.7398L91.0436 19.1068L91.7382 18.3929C100.682 9.47141 112.395 5 124.1 5C135.817 5 147.549 9.48 156.495 18.4165C163.466 25.3947 167.72 34.0505 169.255 43.0801C160.532 38.3732 150.553 35.7 139.97 35.7C105.859 35.7 78.12 63.4386 78.12 97.55C78.12 115.167 85.5194 131.078 97.3571 142.342L87.4597 152.239L19.1598 83.9489L19.1475 83.9364L18.4398 83.2189L18.4273 83.2062L18.4146 83.1936C0.530353 65.3183 0.526874 36.3162 18.4137 18.4174C27.372 9.47805 39.0861 5 50.8 5Z"
          stroke={fill}
          strokeWidth="10"
        />
        <path
          d="M169.506 69.3417L169.511 69.3467C176.524 76.6693 180.82 86.6079 180.82 97.55C180.82 120.111 162.531 138.4 139.97 138.4C130.829 138.4 122.402 135.405 115.61 130.343L115.608 130.341C105.589 122.884 99.1201 110.972 99.1201 97.55C99.1201 74.9892 117.409 56.7 139.97 56.7C151.592 56.7 162.07 61.5496 169.506 69.3417ZM132.12 65.61V69.9823C128.861 71.2679 126.097 73.1545 124.044 75.7118C121.503 78.8754 120.3 82.7559 120.3 87.03C120.3 89.7625 120.544 93.3188 122.905 96.7744C125.185 100.111 129.041 102.789 134.957 104.96H134.91H123.89H119.664L119.896 109.18C120.166 114.087 122.043 118.432 125.478 121.558L125.478 121.558C127.359 123.27 129.61 124.526 132.12 125.325V129.48V133.48H136.12H144.46H148.46V129.48V124.703C155.449 121.53 160.05 115.205 160.05 107.56C160.05 101.705 157.301 96.7773 152.552 93.3247L152.546 93.3202C150.433 91.7898 147.962 90.5593 145.194 89.6215L145.188 89.6197C145.05 89.5732 144.914 89.5266 144.78 89.48H144.86H154.7H158.862L158.697 85.3214C158.54 81.3785 157.402 77.7702 155.169 74.8779C153.429 72.6253 151.141 70.9579 148.46 69.9133V65.61V61.61H144.46H136.12H132.12V65.61ZM138.883 108.493L138.647 106.49C139.222 106.79 139.686 107.083 140.058 107.375L140.069 107.383L140.08 107.392C140.911 108.036 141.05 108.469 141.05 109.03C141.05 109.784 140.479 110.2 140.03 110.2C139.759 110.2 139.626 110.134 139.551 110.085C139.468 110.029 139.314 109.894 139.165 109.57L139.16 109.56C139.047 109.315 138.939 108.971 138.883 108.493ZM140.892 85.9862L141.137 87.9083C140.846 87.7394 140.579 87.5666 140.339 87.389C139.348 86.654 139.23 86.1725 139.23 85.77C139.23 85.2742 139.389 85.0564 139.451 84.9912C139.488 84.9528 139.534 84.9183 139.604 84.8892C139.674 84.8599 139.808 84.82 140.03 84.82C140.223 84.82 140.316 84.8549 140.35 84.8699C140.388 84.8864 140.434 84.9143 140.493 84.9769C140.629 85.1219 140.825 85.4575 140.892 85.9862Z"
          stroke={fill}
          strokeWidth="8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1546_1093">
          <rect width="184.82" height="159.31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CopyCheck = ({size = 18, fill = 'black'}) => {
  return (
    <svg
      style={{
        width: `${size}px`,
        height: `${size}px`
      }}
      stroke={fill}
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
  )
}
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { useRef } from "react";
import { Title } from "../../components/Title";
import { FormContainer } from "../../components/Form/Container";
import { Form } from "../../components/Form/Form";

import { Facebook as IconFacebook } from "../../components/icons/Facebook";
import { Linked as IconLinked } from "../../components/icons/Linked";
import { EmailFilled as IconEmailFilled } from "../../components/icons/EmailFilled";
import { CopyButton } from "../../components/CopyButton";
import { useDispatch } from "react-redux";
import { setIsNavMenuPinned } from "../../slices/mainSlice";

const StyledIcon = styled("a")`
  position: relative;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledRow = styled("div")`
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
`;

const StyledEmail = styled("a")`
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
`;

const StyledText = styled("div")`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  color: var(--black);
  background: var(--base-color);
  border-radius: 4px;
  text-transform: capitalize;
  z-index: 2;
  font-weight: 500;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--base-color);
    z-index: 2;
  }
`;

export const Contacts = ({ toggleContactsVisibility, wasNavMenuPinned }) => {
  const { t } = useTranslation();
  const contactsRef = useRef();
  const dispatch = useDispatch();

  const handleClickOutside = (event) => {
    if (contactsRef.current && !contactsRef.current.contains(event.target)) {
      if (!wasNavMenuPinned) {
        dispatch(setIsNavMenuPinned(false));
      }
      toggleContactsVisibility(false);
    }
  };

  const facebookUrl = "https://www.facebook.com/";
  const linkedInUrl = "https://www.linkedin.com/";
  const email = "acadevo@example.com";

  return (
    <FormContainer onClick={handleClickOutside} onMouseOver={(e) => e.preventDefault()} isPopup>
      <Form ref={contactsRef}>
        <Title value={t("contacts")} />
        <StyledRow>
          <StyledIcon href={facebookUrl}>
            <IconFacebook size={40} />
          </StyledIcon>
          <StyledIcon href={linkedInUrl}>
            <IconLinked size={40} />
          </StyledIcon>
        </StyledRow>
        <StyledRow>
          <StyledIcon href={`mailto:${email}`}>
            <IconEmailFilled size={35} />
          </StyledIcon>
          <StyledEmail>
            {email}
            <CopyButton size={24} textToCopy={email} />
          </StyledEmail>
        </StyledRow>
      </Form>
    </FormContainer>
  );
};

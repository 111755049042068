import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "../../../constants/constants";

import { Home as IconHome } from "../../icons/Home";
import { HomeFilled as IconHomeFilled } from "../../icons/HomeFilled";

import { Study as IconStudy } from "../../icons/Study";
import { StudyFilled as IconStudyFilled } from "../../icons/StudyFilled";

import { Account as IconAccount } from "../../icons/Account";
import { AccountFilled as IconAccountFilled } from "../../icons/AccountFilled";

import { Help as IconHelp } from "../../icons/Help";
import { HelpFilled as IconHelpFilled } from "../../icons/HelpFilled";

import { Settings as IconSettings } from "../../icons/Settings";
import { SettingsFilled as IconSettingsFilled } from "../../icons/SettingsFilled";

import { Email as IconEmail } from "../../icons/Email";
import { EmailFilled as IconEmailFilled } from "../../icons/EmailFilled";

import { Donate as DonateIcon } from "../../icons/Donate";
import { DonateFilled as DonateIconFilled } from "../../icons/DonateFilled";

import { Bug as IconBug } from "../../icons/Bug";

import { Logout as IconLogout } from "../../icons/Logout";

import { styled } from "@mui/system";
import { NavMenuLink } from "../../NavMenuLink";
import { Logo } from "../../icons/Logo";

import { Button } from "../../Button";

const StyledNavMenu = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  flex-shrink: 0;
  transition: 0.3s;
  overflow: hidden;
  background: var(--base-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transform: ${(props) => (props.isOpened ? "translateX(0)" : "translateX(-110%)")};
  width: 100%;
`;

const StyledNavMenuInner = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledNavMenuSections = styled("div")`
  margin-top: 40px;
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const StyledLogoContainer = styled("div")`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 10px;
  padding: 10px;
  transition: 0.3s;
  display: none;
  svg {
    flex-shrink: 0;
  }
`;

const StyledMobileLogoContainer = styled("div")`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 10px;
  width: 70px;
  padding: 10px;
  transition: 0.3s;
  transform: translateX(-50%);
  position: absolute;
  top: -15px;
  left: 50%;
  svg {
    flex-shrink: 0;
  }
`;

const StyledLogoutContainer = styled("div")`
  margin-top: auto;
  margin-bottom: 6px;
`;

const StyledMobileHeader = styled("div")`
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  padding: 20px 10px;
  z-index: 10001;
  background: var(--base-color);
  max-height: 80px;
`;

const StyledMobileHeaderInner = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const NavigationMobile = ({
  t,
  location,
  areSettingsVisible,
  areContactsVisible,
  isSupportProjectPopupVisible,
  handleOpenSettings,
  handleOpenContacts,
  handleOpenSupportProjectPopup,
  handleLogout,
  arePopupsActive,
}) => {
  const navMenuRef = useRef();
  const mobileHeaderRef = useRef();

  const [isNavMenuOpened, setIsNavMenuOpened] = useState(false);

  return (
    <>
      <StyledMobileHeader ref={mobileHeaderRef}>
        <StyledMobileHeaderInner>
          <Button
            title="Menu"
            onClick={() => {
              setIsNavMenuOpened(!isNavMenuOpened);
            }}
          />
          <Link to={ROUTES.main} onClick={() => setIsNavMenuOpened(false)}>
            <StyledMobileLogoContainer>
              <Logo height={30} />
            </StyledMobileLogoContainer>
          </Link>
        </StyledMobileHeaderInner>
      </StyledMobileHeader>

      <StyledNavMenu isOpened={isNavMenuOpened} ref={navMenuRef}>
        <StyledNavMenuInner>
          <Link to={ROUTES.main}>
            <StyledLogoContainer isOpened={isNavMenuOpened}>
              <Logo isTextVisible={isNavMenuOpened} height={50} />
            </StyledLogoContainer>
          </Link>

          <StyledNavMenuSections>
            <NavMenuLink
              onClick={() => setIsNavMenuOpened(false)}
              title={t("pages.home")}
              to={ROUTES.main}
              active={!arePopupsActive() && location.pathname.includes(ROUTES.main)}
              icon={{ active: <IconHomeFilled />, inactive: <IconHome /> }}
              isOpened={isNavMenuOpened}
            />
            <NavMenuLink
              onClick={() => setIsNavMenuOpened(false)}
              title={t("pages.study")}
              active={!arePopupsActive() && location.pathname.includes(ROUTES.study)}
              to={ROUTES.study}
              icon={{ active: <IconStudyFilled />, inactive: <IconStudy /> }}
              isOpened={isNavMenuOpened}
            />

            <NavMenuLink
              onClick={() => setIsNavMenuOpened(false)}
              active={!arePopupsActive() && location.pathname.includes(ROUTES.accountGeneral)}
              title={t("pages.account")}
              to={ROUTES.accountGeneral}
              icon={{ active: <IconAccountFilled />, inactive: <IconAccount /> }}
              isOpened={isNavMenuOpened}
            />
            <NavMenuLink
              title={t("settings.title")}
              active={areSettingsVisible}
              icon={{ active: <IconSettingsFilled />, inactive: <IconSettings /> }}
              onClick={handleOpenSettings}
              isOpened={isNavMenuOpened}
            />
            <NavMenuLink
              onClick={() => setIsNavMenuOpened(false)}
              title={t("pages.faq")}
              active={location.pathname.includes(ROUTES.faq)}
              to={ROUTES.faq}
              icon={{ active: <IconHelpFilled />, inactive: <IconHelp /> }}
              isOpened={isNavMenuOpened}
            />
            <NavMenuLink
              title={t("pages.contacts")}
              active={areContactsVisible}
              icon={{ active: <IconEmailFilled />, inactive: <IconEmail /> }}
              onClick={handleOpenContacts}
              isOpened={isNavMenuOpened}
            />
            <NavMenuLink
              title={t("bugReport")}
              icon={{ active: <IconBug />, inactive: <IconBug /> }}
              isOpened={isNavMenuOpened}
            />
            <NavMenuLink
              title={t("donate")}
              active={isSupportProjectPopupVisible}
              icon={{ active: <DonateIcon />, inactive: <DonateIconFilled /> }}
              onClick={handleOpenSupportProjectPopup}
              isOpened={isNavMenuOpened}
            />
          </StyledNavMenuSections>
          <StyledLogoutContainer>
            <NavMenuLink
              title={t("button.logout")}
              onClick={handleLogout}
              icon={{ active: <IconLogout />, inactive: <IconLogout /> }}
              isOpened={isNavMenuOpened}
            />
          </StyledLogoutContainer>
        </StyledNavMenuInner>
      </StyledNavMenu>
    </>
  );
};

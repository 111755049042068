import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { LessonProvider } from "./context/LessonContext";
import { CourseProvider } from "./context/CourseContext";
import { AuthProvider } from "./context/AuthContext";

import { ThemeProvider } from "./feature/ThemeProvider";

import { persistor, store } from "./store/store";

import { App } from "./App";

import "./i18n/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <CourseProvider>
            <LessonProvider>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </LessonProvider>
          </CourseProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);

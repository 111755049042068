import { CenteredContainer } from "../../components/CenteredContainer";
import { Wrapper } from "../../components/Wrapper";
import { AccountInfo } from "../../feature/Account/AccountInfo";

export const Account = () => {
  return (
    <Wrapper>
      <CenteredContainer>
        <AccountInfo/>
      </CenteredContainer>
    </Wrapper>
  );
};

import { CenteredContainer } from "../../components/CenteredContainer";
import { Wrapper } from "../../components/Wrapper";
import { FAQList } from "../../feature/FAQ/FAQList";

export const FAQ = () => {
  return (
    <Wrapper>
      <CenteredContainer>
        <FAQList />
      </CenteredContainer>
    </Wrapper>
  );
};

export const Bitcoin = ({ fill = "black", size = 50 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        flexShrink: 0,
      }}
      viewBox="0 0 88 88"
      fill="none"
    >
      <path
        d="M53.7067 37.7601C52.3634 43.1334 44 40.4034 41.4 39.6667L43.7834 30.2201C46.47 31.0001 55.0934 32.1267 53.7067 37.7601ZM40.23 44.5201L37.63 54.9634C40.8367 55.7867 50.76 58.9501 52.2334 53.0567C53.7934 46.9034 43.4367 45.3001 40.23 44.5201ZM86.0334 54.4867C80.2267 77.7134 56.74 91.8401 33.5134 86.0334C10.2867 80.2267 -3.82697 56.7401 1.96669 33.5134C3.34314 27.9931 5.7936 22.7981 9.17809 18.2249C12.5626 13.6518 16.8148 9.79027 21.6919 6.86078C26.5691 3.93129 31.9755 1.99128 37.6025 1.15155C43.2295 0.311817 48.9668 0.588816 54.4867 1.96672C77.67 7.77339 91.7967 31.2601 86.0334 54.4867ZM53.5767 26.8834L55.5267 19.0834L50.76 18.0001L48.8534 25.4967C47.5967 25.1934 46.34 24.8901 45.04 24.6301L46.9467 16.9601L42.2234 15.8334L40.2734 23.5901C39.2334 23.3301 38.1934 23.1134 37.24 22.8534L30.6967 21.2067L29.3967 26.2767C29.3967 26.2767 32.95 27.1001 32.8634 27.1434C34.8134 27.6201 35.16 28.8334 35.0734 29.9167L29.7434 51.2801C29.5267 51.8867 28.8334 52.6667 27.5767 52.4501C27.62 52.4934 24.11 51.5834 24.11 51.5834L21.77 57.0001L27.9234 58.5601C29.0934 58.8634 30.22 59.1667 31.3467 59.4267L29.3534 67.3134L34.12 68.5267L36.07 60.6834C37.37 61.0301 38.6267 61.3334 39.84 61.6801L37.89 69.4367L42.6567 70.6501L44.65 62.7634C52.6667 64.2801 58.82 63.6734 61.3334 56.3501C63.5 50.5001 61.3334 47.0334 57 44.8234C60.12 44.0001 62.46 42.0501 63.11 37.8034C63.9767 32.0401 59.5567 28.9634 53.5767 26.8834Z"
        fill={fill}
      />
    </svg>
  );
};

import React, { useState } from "react";
import { Button } from "../../../../components/Button";
import { EditableField } from "../../../../components/EditableField";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { Delete } from "../../../../components/icons/Delete";
import { Loader } from "../../../../components/Loader";
import { faqApi } from "../../../../api/faqApi";
import { Error } from "../../../../components/Error";

const StyledContainer = styled("div")`
  //border: var(--base-border);
  box-shadow: 0 0 5px 2px var(--content-block-shadow-color);
  background: var(--white);
  padding: 16px;
  border-radius: var(--border-radius);
  width: 100%;
`;

const StyledLine = styled("div")`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledText = styled("div")`
  font-weight: 500;
  padding: 6px 0;
`;

const StyledButtonContainer = styled("div")`
  margin-top: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const AddFAQEditor = ({ faqs, setFAQs, category_id }) => {
  const { t, i18n } = useTranslation();

  const initialQuestionPlaceholder = t("faq.question.placeholder");
  const initialAnswerPlaceholder = t("faq.answer.placeholder");

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const current_lang = i18n.language;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errors, setErrors] = useState({
    question: "",
    answer: "",
  });
  const [isAddFAQEditorVisible, setIsAddFAQEditorVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleQuestionTextChange = (value) => {
    setQuestion(value);
  };

  const handleAnswerTextChange = (value) => {
    setAnswer(value);
  };

  const handleSave = async () => {
    if (!question) {
      setErrors({ ...errors, question: "Питання не може бути порожнім" });
      return;
    } else {
      setErrors({ errors, question: "" });
    }

    if (!answer) {
      setErrors({ ...errors, answer: "Відповідь не може бути порожньою" });
      return;
    } else {
      setErrors({ errors, answer: "" });
    }

    setIsButtonLoading(true);
    setIsLoading(true);
    try {
      const newFAQ = await faqApi.addFAQ({
        category_id,
        lang: current_lang,
        question,
        answer,
      });
      console.log(newFAQ);

      // setFAQs([...faqs, { text: newQuestion.text, id: newQuestion.id }]);

      setQuestion("");
      setAnswer("");
      setIsAddFAQEditorVisible(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsButtonLoading(false);
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {!isAddFAQEditorVisible ? (
        <Button title={t("faq.question.add")} onClick={() => setIsAddFAQEditorVisible(true)} isFilled />
      ) : (
        <StyledContainer>
          {/* <StyledLine> */}
          <StyledText>{t(`faq.question.title`)}:</StyledText>
          {/* <Delete size={24} onClick={() => setIsAddFAQEditorVisible(false)} /> */}
          {/* </StyledLine> */}

          <EditableField
            placeholder={initialQuestionPlaceholder}
            onTextChange={handleQuestionTextChange}
            initialText={question}
          />
          {errors?.question && <Error>{errors.question}</Error>}

          <StyledText>{t(`faq.answer.title`)}:</StyledText>
          <EditableField
            placeholder={initialAnswerPlaceholder}
            onTextChange={handleAnswerTextChange}
            initialText={answer}
          />
          {errors?.answer && <Error>{errors?.answer}</Error>}

          <StyledButtonContainer>
            <Button onClick={handleSave} title={t(`button.save`)} isLoading={isButtonLoading} isFilled />
            <Button title={t("button.cancel")} onClick={() => setIsAddFAQEditorVisible(false)} />
          </StyledButtonContainer>
        </StyledContainer>
      )}
    </>
  );
};

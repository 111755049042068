import React, { forwardRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../../../../assets/style/ckeditor-custom.css";
import { coursesApi } from "../../../../api/coursesApi";
import { useSelector } from "react-redux";
import Editor from "../../../../ckeditor5/build/ckeditor";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const TextEditor = forwardRef(({ editorContent, setEditorContent }, ref) => {
  const selectedCourse = useSelector((state) => state.selectedCourse);
  const selectedLesson = useSelector((state) => state.selectedLesson);
  const { t } = useTranslation();

  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader, selectedCourse, selectedLesson);
    };
  }

  const handleReady = (editor) => {
    // Store the editor reference when the editor is ready
    ref.current = editor;
  };

  return (
    <>
      <CKEditor
        ref={ref}
        editor={Editor}
        config={{
          extraPlugins: [CustomUploadAdapterPlugin],
          codeBlock: {
            languages: [
              { language: "plaintext", label: "Plain text" },
              { language: "javascript", label: "JavaScript" },
              { language: "python", label: "Python" },
            ],
          },
          placeholder: t("printHere"),
        }}
        disableWatchdog
        data={editorContent}
        onReady={handleReady}
      />
    </>
  );
});

class MyUploadAdapter {
  constructor(loader, selectedCourse, selectedLesson) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.selectedCourse = selectedCourse;
    this.selectedLesson = selectedLesson;
  }

  // Starts the upload process.
  async upload() {
    return this.loader.file.then(
      (file) =>
        new Promise(async (resolve, reject) => {
          const formData = new FormData();
          formData.append("image", file);

          try {
            const data = await coursesApi.addLessonImage({
              course_id: this.selectedCourse,
              lesson_id: this.selectedLesson,
              image: formData,
            });
            console.log(data);
            // Resolve the promise with the URL of the uploaded image.
            resolve({
              default: data.path,
            });
          } catch (err) {
            // Reject the promise if an error occurs.
            reject(err);
          }
        })
    );
  }
}

import placeholder from "../../../assets/images/intro/placeholder.png";
import { styled } from "@mui/system";

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 270px;

  @media (max-width: 1100px) {
    margin-top: 100px;
    margin-bottom: 130px;
  }
`;

const StyledTitle = styled('div')`
  font-size: 3rem;
  font-weight: 700;

  @media (max-width: 1100px) {
    font-size: 2.5rem;
  }

  @media (max-width: 850px) {
    font-size: 1.8rem;
  }
`;

const StyledReviews = styled('div')`
  margin-top: 70px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  padding: 0 30px;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    margin-top: 30px;
  }
`;

const StyledReview = styled('div')`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledReviewText = styled('div')`
  margin-top: 15px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 150%;
  text-align: center;

  @media (max-width: 850px) {
    margin-top: 5px;
    font-size: 0.9rem;
  }
`;

const StyledImageContainer = styled('div')`
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Reviews = () => {

  const reviews = [
    {image: placeholder, title: "Вау, ого, бімба, петарда, Дуже раджу тощо"},
    {image: placeholder, title: "Вау, ого, бімба, петарда, Дуже раджу тощо"},
    {image: placeholder, title: "Вау, ого, бімба, петарда, Дуже раджу тощо"},
    {image: placeholder, title: "Вау, ого, бімба, петарда, Дуже раджу тощо"},
    {image: placeholder, title: "Вау, ого, бімба, петарда, Дуже раджу тощо"}
  ];

  return (
    <StyledContainer>
      <StyledTitle>Відгуки студентів</StyledTitle>
      <StyledReviews>
        {reviews?.map((review, idx) => (
          <StyledReview key={idx}>
            <StyledImageContainer>
              <img src={review.image} alt="placeholder"/>
            </StyledImageContainer>
            <StyledReviewText>{review.title}</StyledReviewText>
          </StyledReview>
        ))}
      </StyledReviews>
    </StyledContainer>
  );
};

import axiosInstanceWithInterceptor from "./axiosInstanceWithInterceptor";

export const coursesApi = {
  createCourse: async ({ name, lang, description }) => {
    const payload = {
      translation: {
        name,
        description,
        lang,
      },
    };
    return await axiosInstanceWithInterceptor.post(`/courses/`, payload);
  },

  getCourseTranslation: async ({ course_id, translation_id }) => {
    return await axiosInstanceWithInterceptor.get(`/courses/${course_id}/translations/${translation_id}/`);
  },

  getCourseTranslations: async ({ course_id }) => {
    return await axiosInstanceWithInterceptor.get(`/courses/${course_id}/translations/`);
  },

  addCourseTranslation: async ({ name, description, lang, course_id }) => {
    const payload = {
      name,
      description,
      lang,
    };
    return await axiosInstanceWithInterceptor.post(`/courses/${course_id}/translations/`, payload);
  },

  updateCourseTranslation: async ({ name, description, lang, course_id, translation_id, userprofile_id }) => {
    const payload = {
      name,
      description,
      lang,
      userprofile_id,
    };
    return await axiosInstanceWithInterceptor.put(`/courses/${course_id}/translations/${translation_id}/`, payload);
  },

  getCourse: async (course_id) => {
    return await axiosInstanceWithInterceptor.get(`/courses/${course_id}/`);
  },

  updateCourseStatus: async ({ course_id, status }) => {
    const payload = {
      status,
    };
    return await axiosInstanceWithInterceptor.put(`/courses/${course_id}/`, payload);
  },

  translateCourse: async ({ course_id, target_language }) => {
    const payload = {
      target_language,
    };
    return await axiosInstanceWithInterceptor.post(`/courses/${course_id}/auto-translate/`, payload);
  },

  getCourses: async () => {
    return await axiosInstanceWithInterceptor.get(`/courses/`);
  },

  deleteCourse: async (course_id) => {
    return await axiosInstanceWithInterceptor.delete(`/courses/${course_id}/`);
  },

  createLesson: async ({ text, title, parent_id = null, lang, course_id, sequence_id = null }) => {
    const payload = {
      status: "draft",
      parent: parent_id,
      sequence_id: sequence_id,
      translation: {
        lang: lang,
        title: title,
        text: text,
      },
    };
    return await axiosInstanceWithInterceptor.post(`/courses/${course_id}/lessons/`, payload);
  },

  changeOrder: async ({ course_id, lesson_id, new_sequence_id }) => {
    return await axiosInstanceWithInterceptor.post(`/courses/${course_id}/lessons/${lesson_id}/move/`, {
      new_sequence_id,
    });
  },

  getLesson: async ({ course_id, lesson_id }) => {
    return await axiosInstanceWithInterceptor.get(`/courses/${course_id}/lessons/${lesson_id}/`);
  },

  getLessons: async ({ course_id }) => {
    return await axiosInstanceWithInterceptor.get(`/courses/${course_id}/lessons/`);
  },

  getLesson: async ({ course_id, lesson_id }) => {
    return await axiosInstanceWithInterceptor.get(`/courses/${course_id}/lessons/${lesson_id}/`);
  },

  deleteLesson: async ({ course_id, lesson_id }) => {
    return await axiosInstanceWithInterceptor.delete(`/courses/${course_id}/lessons/${lesson_id}/`);
  },

  addQuestion: async ({ lesson_id, lang, text, course_id }) => {
    const payload = {
      lesson_id,
      translation: {
        lang: lang,
        text: text,
      },
    };
    return await axiosInstanceWithInterceptor.post(`/courses/${course_id}/lessons/${lesson_id}/questions/`, payload);
  },

  getQuestions: async ({ course_id, lesson_id }) => {
    return await axiosInstanceWithInterceptor.get(`/courses/${course_id}/lessons/${lesson_id}/questions/`);
  },

  getQuestionTranslations: async ({ course_id, lesson_id, question_id }) => {
    return await axiosInstanceWithInterceptor.get(
      `/courses/${course_id}/lessons/${lesson_id}/questions/${question_id}/translations/`
    );
  },

  updateQuestionTranslation: async ({ course_id, translation_id, lesson_id, question_id, text, lang }) => {
    const payload = {
      question_id: question_id,
      lang: lang,
      text: text,
    };
    return await axiosInstanceWithInterceptor.put(
      `/courses/${course_id}/lessons/${lesson_id}/questions/${question_id}/translations/${translation_id}/`,
      payload
    );
  },

  deleteQuestion: async ({ course_id, lesson_id, question_id }) => {
    return await axiosInstanceWithInterceptor.delete(
      `/courses/${course_id}/lessons/${lesson_id}/questions/${question_id}/`
    );
  },

  addAnswer: async ({ course_id, lesson_id, question_id, lang, text, is_correct }) => {
    const payload = {
      question_id: question_id,
      translation: {
        lang: lang,
        text: text,
      },
      is_correct: is_correct,
    };
    return await axiosInstanceWithInterceptor.post(
      `/courses/${course_id}/lessons/${lesson_id}/questions/${question_id}/answers/`,
      payload
    );
  },

  getAnswers: async ({ course_id, lesson_id, question_id }) => {
    return await axiosInstanceWithInterceptor.get(
      `/courses/${course_id}/lessons/${lesson_id}/questions/${question_id}/answers/`
    );
  },

  getAnswerTranslations: async ({ course_id, lesson_id, question_id, answer_id }) => {
    return await axiosInstanceWithInterceptor.get(
      `/courses/${course_id}/lessons/${lesson_id}/questions/${question_id}/answers/${answer_id}/translations/`
    );
  },

  updateAnswer: async ({ course_id, lesson_id, question_id, answer_id, is_correct }) => {
    const payload = {
      question_id: question_id,
      is_correct: is_correct,
    };

    return await axiosInstanceWithInterceptor.put(
      `/courses/${course_id}/lessons/${lesson_id}/questions/${question_id}/answers/${answer_id}/`,
      payload
    );
  },

  updateAnswerTranslation: async ({ course_id, lesson_id, question_id, answer_id, translation_id, lang, text }) => {
    const payload = {
      question_id: question_id,
      lang: lang,
      text: text,
    };
    return await axiosInstanceWithInterceptor.put(
      `/courses/${course_id}/lessons/${lesson_id}/questions/${question_id}/answers/${answer_id}/translations/${translation_id}/`,
      payload
    );
  },

  deleteAnswer: async ({ course_id, lesson_id, question_id, answer_id }) => {
    return await axiosInstanceWithInterceptor.delete(
      `/courses/${course_id}/lessons/${lesson_id}/questions/${question_id}/answers/${answer_id}/`
    );
  },

  addLessonTranslation: async ({ course_id, lesson_id, lang, text, title }) => {
    const payload = {
      lesson_id: lesson_id,
      lang: lang,
      text: text,
      title: title,
    };
    return await axiosInstanceWithInterceptor.post(`/courses/${course_id}/lessons/${lesson_id}/translations/`, payload);
  },

  getLessonTranslations: async ({ course_id, lesson_id }) => {
    return await axiosInstanceWithInterceptor.get(`/courses/${course_id}/lessons/${lesson_id}/translations/`);
  },

  updateLessonTranslation: async ({ course_id, lesson_id, translation_id, lang, title, text }) => {
    const payload = {
      lesson_id: lesson_id,
      lang: lang,
      title: title,
      text: text,
    };

    return await axiosInstanceWithInterceptor.put(
      `/courses/${course_id}/lessons/${lesson_id}/translations/${translation_id}/`,
      payload
    );
  },

  addLessonImage: async ({ course_id, lesson_id, image }) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return await axiosInstanceWithInterceptor.post(
      `/courses/${course_id}/lessons/${lesson_id}/lesson-images/`,
      image,
      config
    );
  },

  deleteLessonTranslation: async ({ course_id, lesson_id, translation_id }) => {
    return await axiosInstanceWithInterceptor.delete(
      `/courses/${course_id}/lessons/${lesson_id}/translations/${translation_id}/`
    );
  },

  // homework

  addHomework: async ({ course_id, lesson_id, lang, text, title }) => {
    const payload = {
      translation: {
        title: title,
        lang: lang,
        text: text,
      },
    };
    return await axiosInstanceWithInterceptor.post(
      `/courses/${course_id}/lessons/${lesson_id}/independent_work/`,
      payload
    );
  },

  addHomeworkTranslation: async ({ course_id, lesson_id, independent_work_id, lang, text, title }) => {
    const payload = {
      title: title,
      lang: lang,
      text: text,
    };

    return await axiosInstanceWithInterceptor.post(
      `/courses/${course_id}/lessons/${lesson_id}/independent_work/${independent_work_id}/translations/`,
      payload
    );
  },

  getHomework: async ({ course_id, lesson_id }) => {
    return await axiosInstanceWithInterceptor.get(`/courses/${course_id}/lessons/${lesson_id}/independent_work/`);
  },

  getHomeworkTranslations: async ({ course_id, lesson_id, independent_work_id }) => {
    return await axiosInstanceWithInterceptor.get(
      `/courses/${course_id}/lessons/${lesson_id}/independent_work/${independent_work_id}/translations/`
    );
  },

  updateHomeworkTranslation: async ({
    course_id,
    lesson_id,
    independent_work_id,
    independent_work_translation_id,
    title,
    lang,
    text,
  }) => {
    const payload = {
      title: title,
      lang: lang,
      text: text,
    };

    return await axiosInstanceWithInterceptor.put(
      `/courses/${course_id}/lessons/${lesson_id}/independent_work/${independent_work_id}/translations/${independent_work_translation_id}/`,
      payload
    );
  },

  deleteHomeworkTranslation: async ({ course_id, lesson_id, independent_work_id, independent_work_translation_id }) => {
    return await axiosInstanceWithInterceptor.delete(
      `/courses/${course_id}/lessons/${lesson_id}/independent-work/${independent_work_id}/translations/${independent_work_translation_id}/`
    );
  },
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material";
import { faqApi } from "../../../api/faqApi";
import { Loader } from "../../../components/Loader";
import { AddFAQEditor } from "../Editors/AddFAQEditor/AddFAQEditor";
import { FAQItemEditor } from "../Editors/FAQItemEditor";
import { useAuth } from "../../../context/AuthContext";
import { FAQItemContent } from "../FAQItemContent";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../../constants/constants";

const StyledFAQContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  gap: 30px;
`;

const StyledFAQList = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FAQHeader = styled("h1")`
  font-size: 2rem;
`;

const Category = styled("div")`
  margin: 0 0 1rem;
`;

export const FAQList = () => {
  const { i18n, t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [FAQs, setFAQs] = useState([]);
  const [FAQTranslations, setFAQTranslations] = useState({});
  const [mainCategoryId, setMainCategoryId] = useState(null);
  const { isAdmin, hasPriviledgedPersonEnabledEditorMode } = useAuth();
  const userRole = useSelector((state) => state.userRole);

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      let newFAQTranslationsObject = {};

      const newCategories = await faqApi.getCategories();
      const newFAQs = await faqApi.getFAQs({ category_id: newCategories[0] });
      setMainCategoryId(newCategories[0]?.id);

      const translationPromises = newFAQs.map(async (faq) => {
        const newFAQTranslation = await faqApi.getFAQTranslations({ category_id: newCategories[0].id, faq_id: faq.id });
        newFAQTranslationsObject[faq.id] = newFAQTranslation.find((translation) => translation.lang === i18n.language);
      });

      await Promise.all(translationPromises);

      setFAQs(newFAQs);
      console.log(newFAQs);
      setFAQTranslations(newFAQTranslationsObject);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <StyledFAQContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <FAQHeader>{t("faq.header")}</FAQHeader>
          <StyledFAQList>
            {FAQs.map((FAQ) =>
              isAdmin() && userRole === USER_ROLES.admin ? (
                <FAQItemEditor
                  key={FAQ.id}
                  faq_id={FAQ.id}
                  translation={FAQTranslations[FAQ.id]}
                  category_id={mainCategoryId}
                />
              ) : (
                <FAQItemContent
                  key={FAQ.id}
                  question={FAQTranslations[FAQ.id]?.question}
                  answer={FAQTranslations[FAQ.id]?.answer}
                />
              )
            )}
            {isAdmin() && hasPriviledgedPersonEnabledEditorMode() && <AddFAQEditor category_id={mainCategoryId} />}
          </StyledFAQList>
        </>
      )}
    </StyledFAQContainer>
  );
};

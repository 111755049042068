import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import "../Forms.css";
import { Button } from "../../../components/Button";
import { coursesApi } from "../../../api/coursesApi";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import { setSelectedLesson } from "../../../slices/mainSlice";
import { FormContainer } from "../../../components/Form/Container";
import { Form } from "../../../components/Form/Form";
import { Flex } from "../../../components/Flex";
import { InputContainer } from "../../../components/InputContainer";
import { Title } from "../../../components/Title";

export const CreateLessonForm = ({ toggleCreateLessonFormVisibility, getLessonData }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const selectedCourse = useSelector((state) => state.selectedCourse);
  const current_lang = i18n.language;
  const [lessonName, setLessonName] = useState("");
  const formRef = useRef();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLessonName(value);
    if (error) {
      setError(null);
    }
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      toggleCreateLessonFormVisibility(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await coursesApi.createLesson({
        course_id: selectedCourse,
        title: lessonName,
        text: JSON.stringify(t("changeThisText")),
        lang: current_lang,
      });
      console.log(response);
      const newSelectedLessonId = response.id;
      const newSelectedLessonName = response.title;
      await getLessonData();
      toggleCreateLessonFormVisibility(false);
      navigate(ROUTES.study + "/" + selectedCourse + "/" + newSelectedLessonId + "/theory");
      dispatch(setSelectedLesson(newSelectedLessonId));
    } catch (error) {
      console.log(error);
      if (error.response?.data?.translation?.title) {
        setError(error.response.data?.translation?.title);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <FormContainer onClick={handleClickOutside} isPopup>
      <Form ref={formRef}>
        <Title value={t("lesson.create")} />

        <InputContainer error={error} fullWidth>
          <Input value={lessonName} name="lessonName" onChange={handleChange} placeholder={t("form.lessonName")} />
        </InputContainer>

        <Flex gap={16}>
          <Button isLoading={isLoading} title={t("button.create")} onClick={handleSubmit} isFilled />
          <Button title={t("button.cancel")} onClick={() => toggleCreateLessonFormVisibility(false)} />
        </Flex>
      </Form>
    </FormContainer>
  );
};

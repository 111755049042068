import { useEffect, useRef, useState } from "react";
import { FormContainer } from "../../../components/Form/Container";
import { Form } from "../../../components/Form/Form";
import { PrimaryTitle } from "../../../components/PrimaryTitle";
import { Button } from "../../../components/Button";
import { styled } from "@mui/system";
import { coursesApi } from "../../../api/coursesApi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../components/Checkbox";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import { Loader } from "../../../components/Loader";
import { Input } from "../../../components/Input";

const StyledAuthorList = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  height: 400px;
  overflow-y: auto;
  background: #f1f3f5;
  padding: 0.5rem;
  border-radius: var(--border-radius);
`;

const StyledAuthorListItem = styled("div")`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--input-shadow);
  border-radius: var(--border-radius);
  background: var(--white);
  box-shadow: 0 0 10px 2px var(--content-block-shadow-color);
  cursor: pointer;
`;

const StyledAuthorListItemEmail = styled("div")`
  width: 100%;
`;

const StyledSearch = styled("input")`
  width: 100%;
  border-bottom: 2px solid black;
  font-size: 15px;
  padding: 5px 0;
`;

export const AuthorManagementForm = ({ toggleAuthorManagementFormVisibility }) => {
  const [currentTranslation, setCurrentTranslation] = useState(null);
  const [filterQuery, setFilterQuery] = useState("");
  const [filteredAuthors, setFilteredAuthors] = useState([]);
  const authorManagementFormRef = useRef();
  const selectedCourse = useSelector((state) => state.selectedCourse);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const getCurrentCourseTranslationInfo = async () => {
    setIsLoading(true);
    try {
      const newCourseTranslationData = await coursesApi.getCourseTranslations({ course_id: selectedCourse });
      const current_course_translation = newCourseTranslationData.find((course) => course.lang === i18n.language);
      if (current_course_translation) {
        const current_course_translation_full = await coursesApi.getCourseTranslation({
          course_id: selectedCourse,
          translation_id: current_course_translation.id,
        });
        console.log(current_course_translation_full);
        setCurrentTranslation(current_course_translation_full);
        setFilteredAuthors(current_course_translation_full.available_authors);
      } else {
        alert("There are no course translation for selected language. Please create one or switch the language");
        toggleAuthorManagementFormVisibility(false);
        navigate(ROUTES.study);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAuthorSelect = async ({ author_id }) => {
    let newUserProfileIdArray = [...currentTranslation.userprofile_id];
    console.log(newUserProfileIdArray);
    if (currentTranslation?.userprofile_id?.includes(author_id)) {
      newUserProfileIdArray = newUserProfileIdArray.filter((userprofile_id) => userprofile_id !== author_id);
    } else {
      newUserProfileIdArray.push(author_id);
    }
    try {
      const newTranslation = await coursesApi.updateCourseTranslation({
        name: currentTranslation.name,
        course_id: selectedCourse,
        translation_id: currentTranslation.id,
        lang: i18n.language,
        userprofile_id: newUserProfileIdArray,
      });
      setCurrentTranslation(newTranslation);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (authorManagementFormRef.current && !authorManagementFormRef.current.contains(event.target)) {
        toggleAuthorManagementFormVisibility(false);
      }
    }

    getCurrentCourseTranslationInfo();

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleFilter = (e) => {
    const newValue = e.target.value;
    setFilterQuery(newValue);
    if (newValue === "") {
      setFilteredAuthors(currentTranslation?.available_authors);
    } else {
      const filteredAvailableAuthors = currentTranslation?.available_authors?.filter((author) =>
        author.user__email.toLowerCase().includes(newValue.toLowerCase())
      );
      setFilteredAuthors(filteredAvailableAuthors);
    }
  };
  return (
    <FormContainer isPopup>
      <Form ref={authorManagementFormRef}>
        <PrimaryTitle value={t("form.authorManagement")} />
        <Input type="text" value={filterQuery} onChange={handleFilter} placeholder={t("search")} />
        <StyledAuthorList>
          {isLoading ? (
            <Loader />
          ) : (
            filteredAuthors?.map((author) => {
              return (
                <StyledAuthorListItem key={author.id} onClick={() => handleAuthorSelect({ author_id: author.id })}>
                  <StyledAuthorListItemEmail>{author.user__email}</StyledAuthorListItemEmail>
                  <Checkbox
                    size={12}
                    onChange={() => {}}
                    checked={currentTranslation?.userprofile_id?.some(
                      (currentAuthorId) => author.id === currentAuthorId
                    )}
                  />
                </StyledAuthorListItem>
              );
            })
          )}
        </StyledAuthorList>
        {/* <Flex gap={16}> */}
        {/* <Button title={t("button.confirm")} onClick={handleSubmit} isFilled /> */}
        <Button isFilled title={t("button.cancel")} onClick={() => toggleAuthorManagementFormVisibility(false)} />
        {/* </Flex> */}
      </Form>
    </FormContainer>
  );
};

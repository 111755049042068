import { Wrapper } from "../../components/Wrapper";
import { Textbook } from "../../components/Textbook";
import { LessonTabs } from "../../feature/Lesson/LessonTabs";
import { CenteredContainer } from "../../components/CenteredContainer";

export const Lesson = () => {
  return (
    <>
      <Wrapper>
        <CenteredContainer>
          <LessonTabs/>
        </CenteredContainer>
      </Wrapper>
      <Textbook/>
    </>
  );
};

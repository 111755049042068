import { EditableField } from "../../../components/EditableField";
import { Delete } from "../../../components/icons/Delete";
import React from "react";
import { styled } from "@mui/system";
import { Checkbox } from "../../../components/Checkbox";

const StyledAnswersBlock = styled("li")`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  transition: 0.2s;
  margin-bottom: 8px;
`;

const StyledDeleteButtonContainer = styled("div")`
  margin-left: auto;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s;

  &:hover {
    background: #f1f6fa;
  }
`;

export const AnswersBlock = ({
  answer,
  handleCheckboxClick,
  placeholder,
  handleAnswerTextChange,
  handleAnswerDelete,
}) => {
  return (
    <StyledAnswersBlock>
      <Checkbox size={26} onChange={handleCheckboxClick} checked={answer.is_correct} />
      <EditableField placeholder={placeholder} initialText={answer.text} onTextChange={handleAnswerTextChange} />
      <StyledDeleteButtonContainer>
        <Delete size={27} onClick={handleAnswerDelete} />
      </StyledDeleteButtonContainer>
    </StyledAnswersBlock>
  );
};

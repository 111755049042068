import { styled } from "@mui/system";

const StyledCheckbox = styled("label")`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  outline: 3px solid var(--base-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  box-shadow: var(--black-shadow);
  cursor: pointer;
  flex-shrink: 0;
`;

const StyledCheckboxArrow = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.1s;

  &::before {
    position: absolute;
    content: "";
    top: ${(props) => props.size * 0.1}px;
    left: ${(props) => props.size * 0.29}px;
    width: ${(props) => props.size * 0.29}px;
    height: ${(props) => props.size * 0.56}px;
    border-top: 2px solid var(--black);
    border-left: 2px solid var(--black);
    transform: rotate(-135deg);
    opacity: 0;
    transition: 0.1s;
  }

  .answer__block__checkbox__input:checked ~ &::before {
    opacity: 1;
  }

  .answer__block__checkbox__input:checked ~ & {
    background: var(--base-color);
  }
`;

const StyledCheckboxInput = styled("input")`
  display: none;
`;

export const Checkbox = ({ onChange, checked, readOnly, size = 18 }) => {
  return (
    <StyledCheckbox size={size}>
      <StyledCheckboxInput
        type="checkbox"
        onChange={onChange}
        checked={checked}
        readOnly={readOnly}
        className="answer__block__checkbox__input"
      />
      <StyledCheckboxArrow size={size} />
    </StyledCheckbox>
  );
};

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ROUTES } from "../../constants/constants";
import { setIsNavMenuPinned } from "../../slices/mainSlice";
import { ConfirmationForm } from "../../feature/Forms/ConfirmationForm";
import { useCourseContext } from "../../context/CourseContext";
import { useAuth } from "../../context/AuthContext";

import { Settings } from "../../feature/Settings";
import { styled } from "@mui/system";
import { SupportProjectPopup } from "../SupportProject/SupportProject";
import { CreateLessonBlock } from "../CreateLessonBlock";
import { userApi } from "../../api/userApi";
import { Contacts } from "../../feature/Contacts";
import { NavigationDesktop } from "./NavigationDesktop";
import { NavigationMobile } from "./NavigationMobile";

export const StyledNavMenuInner = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 100%;
`;

export const StyledNavMenuSections = styled("div")`
  // position: absolute;
  // top: 35%;
  // left: 0;
  margin-top: 40px;
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDropdownIconContainer = styled("div")`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isVisible || props.isPinned ? "1" : "0")};
  visibility: ${(props) => (props.isVisible || props.isPinned ? "visible" : "hidden")};
  transform: ${(props) => (props.isCoursesListVisible ? "rotate(0deg)" : "rotate(180deg)")};
  transition: 0.2s;
`;

const StyledDropdownWrapper = styled("div")`
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.6);
  }
`;

export const StyledLogoutContainer = styled("div")`
  margin-top: auto;
  margin-bottom: 6px;
`;

export const Navigation = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const selectedCourse = useSelector((state) => state.selectedCourse);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [wasNavMenuPinned, setWasNavMenuPinned] = useState(false);

  const [isCreateLessonFormVisible, toggleCreateLessonFormVisibility] = useState(false);
  const [areSettingsVisible, toggleSettingsVisibility] = useState(false);
  const [areContactsVisible, toggleContactsVisibility] = useState(false);
  const [isSupportProjectPopupVisible, toggleSupportProjectPopupVisibility] = useState(false);
  const [isLogoutConfirmationFormVisible, toggleLogoutConfirmationFormVisibility] = useState(false);

  const isNavMenuPinned = useSelector((state) => state.isNavMenuPinned);
  const [isNavMenuHovered, setIsNavMenuHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { hasPriviledgedPersonEnabledEditorMode } = useAuth();
  const { getCourseData } = useCourseContext();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 850);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 850);
    };

    window.addEventListener("resize", handleResize);

    // Clean up function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchCourseData = async () => {
    setIsLoading(true);
    try {
      await getCourseData();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, [selectedCourse]);

  const handleOpenSettings = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setIsNavMenuPinned(true));
    toggleSettingsVisibility(true);
  };

  const handleOpenContacts = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setIsNavMenuPinned(true));
    toggleContactsVisibility(true);
  };

  const handleOpenSupportProjectPopup = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setIsNavMenuPinned(true));
    toggleSupportProjectPopupVisibility(true);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleLogoutConfirmationFormVisibility(true);
  };

  const handleLogoutSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      userApi.logout();
      navigate(ROUTES.index);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const arePopupsActive = () => {
    return areSettingsVisible || areContactsVisible;
  };

  return (
    <>
      {isCreateLessonFormVisible && hasPriviledgedPersonEnabledEditorMode() && (
        <CreateLessonBlock
          isCreateLessonFormVisible={isCreateLessonFormVisible}
          toggleCreateLessonFormVisibility={toggleCreateLessonFormVisibility}
        />
      )}

      {isLogoutConfirmationFormVisible && (
        <ConfirmationForm
          handleSubmit={handleLogoutSubmit}
          isLoading={isLoading}
          toggleConfirmationFormVisibility={toggleLogoutConfirmationFormVisibility}
        />
      )}
      {isDesktop ? (
        <NavigationDesktop
          setWasNavMenuPinned={setWasNavMenuPinned}
          t={t}
          dispatch={dispatch}
          location={location}
          areSettingsVisible={areSettingsVisible}
          areContactsVisible={areContactsVisible}
          isSupportProjectPopupVisible={isSupportProjectPopupVisible}
          handleOpenSettings={handleOpenSettings}
          handleOpenContacts={handleOpenContacts}
          handleOpenSupportProjectPopup={handleOpenSupportProjectPopup}
          handleLogout={handleLogout}
          arePopupsActive={arePopupsActive}
        />
      ) : (
        <NavigationMobile
          t={t}
          location={location}
          areSettingsVisible={areSettingsVisible}
          areContactsVisible={areContactsVisible}
          isSupportProjectPopupVisible={isSupportProjectPopupVisible}
          handleOpenSettings={handleOpenSettings}
          handleOpenContacts={handleOpenContacts}
          handleOpenSupportProjectPopup={handleOpenSupportProjectPopup}
          handleLogout={handleLogout}
          arePopupsActive={arePopupsActive}
        />
      )}
      {areSettingsVisible && (
        <Settings wasNavMenuPinned={wasNavMenuPinned} toggleSettingsVisibility={toggleSettingsVisibility} />
      )}
      {areContactsVisible && (
        <Contacts wasNavMenuPinned={wasNavMenuPinned} toggleContactsVisibility={toggleContactsVisibility} />
      )}
      {isSupportProjectPopupVisible && (
        <SupportProjectPopup
          isHovered={isNavMenuHovered}
          toggleSupportProjectPopupVisibility={toggleSupportProjectPopupVisibility}
          isPinned={isNavMenuPinned}
        />
      )}
    </>
  );
};

import { createContext, useContext, useEffect, useState } from "react";
import { coursesApi } from "../api/coursesApi";
import { setCourseEducationId } from "../slices/mainSlice";
import { studyApi } from "../api/studyApi";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useAuth } from "./AuthContext";

const CourseContext = createContext();

export const CourseProvider = ({ children }) => {
  const [courses, setCourses] = useState([]);
  const { i18n } = useTranslation();
  const selectedCourse = useSelector((state) => state.selectedCourse);
  const dispatch = useDispatch();
  const { isAuthenticated, hasPriviledgedPersonEnabledEditorMode } = useAuth();

  const getCourseData = async () => {
    const newCourses = await coursesApi.getCourses();
    setCourses(newCourses);
  };

  const getCourseEducationId = async ({ course_id }) => {
    try {
      let newCourseEducationId;
      const courseEducationList = await studyApi.getCourseEducationList();
      const targetCourse = courseEducationList.find((item) => item.course_id === course_id);
      if (!targetCourse) {
        const newCourseEducation = await studyApi.createCourseEducation({ course_id, study_language: i18n.language });
        console.log(newCourseEducation);
        newCourseEducationId = newCourseEducation.course_education_id;
      } else {
        newCourseEducationId = targetCourse.course_education_id;
      }
      return newCourseEducationId;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated() && !hasPriviledgedPersonEnabledEditorMode() && selectedCourse) {
        const newCourseEducationId = await getCourseEducationId({
          course_id: selectedCourse,
        });
        dispatch(setCourseEducationId(newCourseEducationId));
      }
    };

    // fetchData();
  }, [selectedCourse]);

  return (
    <CourseContext.Provider value={{ courses, setCourses, getCourseData, getCourseEducationId }}>
      {children}
    </CourseContext.Provider>
  );
};

export const useCourseContext = () => {
  return useContext(CourseContext);
};

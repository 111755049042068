import { ContentBlock } from "../../../components/ContentBlock";
import { Title } from "../../../components/Title";
import { InputContainer } from "../../../components/InputContainer";
import { Input } from "../../../components/Input";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { useEffect, useState } from "react";
import { BUTTON_STATUSES } from "../../../constants/constants";
import { userApi } from "../../../api/userApi";

const StyledAdditionalInfo = styled("div")`
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  flex-direction: column-reverse;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    gap: 16px;
  }
`;

const StyledButtonContainer = styled("div")`
  margin-top: 16px;
`;

export const Additional = ({ user, areObjectsSimilar }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [statusText, setStatusText] = useState(`button.${BUTTON_STATUSES.save}`);
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({
    link_github: "",
    link_linkedin: "",
  });

  const [initialFormValues, setInitialFormValues] = useState({
    link_linkedin: "",
    link_github: "",
  });

  useEffect(() => {
    if (user) {
      setFormValues({
        link_linkedin: user?.profile?.link_linkedin || "",
        link_github: user?.profile?.link_github || "",
      });
      setInitialFormValues({
        link_linkedin: user?.profile?.link_linkedin || "",
        link_github: user?.profile?.link_github || "",
      });
    }
  }, []);

  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  const handleSave = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      setErrors({});
      await userApi.updateUserInfo(formValues);
      setStatusText(`button.${BUTTON_STATUSES.saved}`);
      setTimeout(() => {
        setStatusText(`button.${BUTTON_STATUSES.save}`);
      }, 2000);
    } catch (error) {
      setStatusText(`button.${BUTTON_STATUSES.error}`);
      setTimeout(() => {
        setStatusText(`button.${BUTTON_STATUSES.save}`);
      }, 2000);
      if (error.response.data.profile) {
        setErrors(error.response.data.profile);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  return (
    <>
      <StyledAdditionalInfo>
        <ContentBlock width="100%">
          <Title value={t(`account.socialNetworks`)} />
          <InputContainer title="LinkedIn" error={errors?.link_linkedin} fullWidth>
            <Input
              placeholder="https://linkedin.com/Acadevo"
              name="link_linkedin"
              value={formValues.link_linkedin}
              onChange={handleInputChange}
            />
          </InputContainer>
        </ContentBlock>
        <ContentBlock width="100%">
          <Title value={t(`account.portfolio`)} />
          <InputContainer title="GitHub" error={errors?.link_github} fullWidth>
            <Input
              placeholder="https://github.com/Acadevo"
              name="link_github"
              value={formValues.link_github}
              onChange={handleInputChange}
            />
          </InputContainer>
        </ContentBlock>
      </StyledAdditionalInfo>
      <StyledButtonContainer>
        <Button
          title={t(statusText)}
          onClick={handleSave}
          disabled={areObjectsSimilar(formValues, initialFormValues)}
          isLoading={isLoading}
          isFilled
        />
      </StyledButtonContainer>
    </>
  );
};

import { styled } from "@mui/system";

const StyledFormContainer = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  padding: ${(props) => (props.isPopup ? "0 30px" : "0")};
  background: ${(props) => (props.isPopup ? "rgba(0, 0, 0, 0.3)" : "transparent")};
  box-shadow: ${(props) => (props.isPopup ? "" : "0 0 10px 2px var(--content-block-shadow-color);")};
`;
export const FormContainer = ({ onClick, isPopup, children, onMouseOver }) => {
  return (
    <StyledFormContainer isPopup={isPopup} onClick={onClick} onMouseOver={onMouseOver}>
      {children}
    </StyledFormContainer>
  );
};

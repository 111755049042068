import React, { forwardRef, useEffect } from "react";
import "../../../assets/style/ckeditor-client.css";

import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import python from "highlight.js/lib/languages/python";
import javascript from "highlight.js/lib/languages/javascript";
import java from "highlight.js/lib/languages/java";
import css from "highlight.js/lib/languages/css";
import { Loader } from "../../../components/Loader";
import { styled } from "@mui/system";

const StyledContent = styled("div")`
  width: 100%;
  background: var(--white);
  border-radius: var(--border-radius);
  padding: 20px;
  position: relative;
  z-index: 2;
  border-top-left-radius: 0;
`;

hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("java", java);
hljs.registerLanguage("css", css);
hljs.registerLanguage("python", python);

export const Content = forwardRef(({ isLoading, content }, ref) => {
  useEffect(() => {
    hljs.highlightAll();
  });

  if (isLoading) return <Loader />;

  // return <ReactQuill ref={ref} value={content} modules={studentModules} readOnly={true} />;
  // return <CKEditor editor={Editor} disabled={true} data={content} />;
  return <StyledContent className="ck-content" dangerouslySetInnerHTML={{ __html: content }} />;
});

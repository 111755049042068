import { styled } from "@mui/system";
import { Plus } from "../icons/Plus";

const StyledLine = styled("div")`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  padding: 5px;
  border-radius: 5px;
  margin-left: -5px;
  transition: 0.3s;

  &:hover {
    //background: #f1f6fa;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: var(--input-shadow);
  }
`;

const StyledIconContainer = styled("div")`
  width: 20px;
  height: 20px;
  display: flex;
  flex-shrink: 0;
`;

const StyledText = styled("p")`
  font-weight: 500;
`;

export const PlusButton = ({ title, onClick }) => {
  return (
    <StyledLine onClick={onClick}>
      <StyledIconContainer>
        <Plus size={20} />
      </StyledIconContainer>
      {title && <StyledText>{title}</StyledText>}
    </StyledLine>
  );
};

import { Link } from "react-router-dom";
import { Logo } from "../../../components/icons/Logo";
import "./IntroFooter.css";
import { styled } from "@mui/system";
import { Instagram as IconInstagram } from "../../../components/icons/Instagram";
import { Facebook as IconFacebook } from "../../../components/icons/Facebook";
import { Telegram as IconTelegram } from "../../../components/icons/Telegram";
import { ROUTES } from "../../../constants/constants";

const StyledFooter = styled("footer")`
  display: flex;
  justify-content: center;
  padding: 40px 80px;
  background: var(--base-color);
`;

const StyledFooterInner = styled("div")`
  max-width: 1176px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
  }
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  font-weight: 700;
  line-height: 150%; /* 24px */
`;

const StyledLeftPart = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

const StyledRightPart = styled("div")`
  display: flex;
  align-self: flex-end;
  gap: 24px;

  @media (max-width: 850px) {
    align-self: center;
  }
`;

export const IntroFooter = () => {
  return (
    <StyledFooter>
      <StyledFooterInner>
        <Logo isTextVisible />
        <StyledLeftPart>
          <StyledLink to={ROUTES.index}>Блог</StyledLink>
          <StyledLink to={ROUTES.index}>Контакти</StyledLink>
          <StyledLink to={ROUTES.index}>Про нас</StyledLink>
          <StyledLink to={ROUTES.index}>Умови користування</StyledLink>
        </StyledLeftPart>
        <StyledRightPart>
          <IconInstagram />
          <IconFacebook />
          <IconTelegram />
        </StyledRightPart>
      </StyledFooterInner>
    </StyledFooter>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import arrow from "../../assets/images/icons/select/arrow.svg";

const StyledSelect = styled("div")`
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 10px;
  color: var(--black);
  border-radius: var(--border-radius);
  font-weight: 400;
  transition: 0.2s;
  box-shadow: var(--input-shadow);
  background: var(--white);
  z-index: ${(props) => (props.isVisible ? "2" : "1")};

  &::after {
    position: absolute;
    content: url(${(props) => props.icon});
    width: 24px;
    height: 24px;
    top: 50%;
    transform: ${(props) => (props.isVisible ? "translateY(-50%) rotate(0)" : "translateY(-50%) rotate(180deg)")};
    right: 10px;
    transition: 0.3s;
  }

  &::placeholder {
    color: #9a98a0;
    font-size: 1rem;
`;

const StyledSelectedOption = styled("div")`
  position: relative;
`;

const StyledOptions = styled("div")`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  border-radius: var(--border-radius);
  background: var(--white);
  user-select: none;
  transform: ${(props) => (props.isVisible ? "scale(1) translateY(0)" : "scale(0.6) translateY(-50%)")};
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  transition: 0.3s;
  box-shadow: 0 0 0 1px var(--input-shadow-color);

  &:after {
    position: absolute;
    content: "";
    top: 1px;
    width: 10px;
    height: 10px;
    background: var(--white);
    left: 50%;
    z-index: -1;
    transform: rotate(45deg) translateX(-50%);
  }
`;

const StyledOption = styled("span")`
  padding: 6px 10px;
  transition: 0.3s;
  position: relative;
  display: flex;
  border-radius: var(--border-radius);
  align-items: center;

  &:hover {
    background: #f0f0f0;
  }
`;

const StyledIcon = styled("img")`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const Select = ({ options, defaultValue, onClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();
  const selectRef = useRef();

  const handleOptionClick = ({ e, option }) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(option.value);
    setSelectedOption(option);
    setIsVisible(false);
  };

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target) && isVisible) {
        setIsVisible(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVisible]);

  return (
    <StyledSelect ref={selectRef} isVisible={isVisible} onClick={() => setIsVisible(!isVisible)} icon={arrow}>
      {selectedOption?.icon && <StyledIcon src={selectedOption.icon} />}
      <StyledSelectedOption>{t(selectedOption?.label) || t(`option.select`)}</StyledSelectedOption>
      <StyledOptions isVisible={isVisible}>
        {options
          .filter((option) => option.value !== selectedOption?.value)
          .map((option, idx) => {
            return (
              <StyledOption key={idx} onClick={(e) => handleOptionClick({ e, option })}>
                {option?.icon && <StyledIcon src={option.icon} />}
                {t(option.label)}
              </StyledOption>
            );
          })}
      </StyledOptions>
    </StyledSelect>
  );
};

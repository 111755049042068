import { useState } from "react";
import { styled } from "@mui/system";
import { Copy as IconCopy } from "../icons/Copy";
import { CopyCheck as IconCopyCheck } from "../icons/CopyCheck";
import { useTranslation } from "react-i18next";

const StyledCopyCodeButton = styled("div")`
  font-size: 0.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  user-select: none;
  padding: 2px 5px;
  position: relative;
`;

const StyledText = styled("div")`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: ${(props) => (props.isCopied ? "translateX(-50%) scale(1)" : "translateX(-50%) scale(0.8)")};
  opacity: ${(props) => (props.isCopied ? "1" : "0")};
  appearance: ${(props) => props.isCopied && "none"};
  transition:
    transform 0.3s,
    opacity 0.3s;
  padding: 5px;
  color: var(--black);
  background: var(--white);
  box-shadow: var(--input-shadow);
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--base-color);
  }
`;

export const CopyButton = ({ textToCopy, size }) => {
  const { t } = useTranslation();
  const copyTextStatuses = {
    copied: t("button.copied"),
  };

  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = () => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy);
    } else {
      unsecuredCopyToClipboard(textToCopy);
    }
    function unsecuredCopyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
    <StyledCopyCodeButton onClick={handleCopy}>
      {isCopied ? <IconCopyCheck size={size} /> : <IconCopy size={size} />}
      <StyledText isCopied={isCopied}>{copyTextStatuses.copied}</StyledText>
    </StyledCopyCodeButton>
  );
};

import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import { QuestionEditor } from "../QuestionEditor";
import { AddQuestionEditor } from "../AddQuestionEditor";
import { useTranslation } from "react-i18next";

const StyledQuestionsContainer = styled("div")`
  width: 100%;
`;

const StyledQuestionsEditorContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background: var(--white);
  padding: 16px;
  margin-bottom: 12px;
  border-radius: var(--border-radius);
`;

export const TestsEditor = ({ questions, setQuestions, answers, setAnswers }) => {
  const lesson_id = useSelector((state) => state.selectedLesson);
  const course_id = useSelector((state) => state.selectedCourse);
  const { t } = useTranslation();

  // if (isLoading) return <Loader/>;

  return (
    <StyledQuestionsContainer>
      <StyledQuestionsEditorContainer>
        {questions.length
          ? questions?.map((question, idx) => (
              <QuestionEditor
                answers={answers}
                setAnswers={setAnswers}
                questions={questions}
                setQuestions={setQuestions}
                key={idx}
                lesson_id={lesson_id}
                course_id={course_id}
                question_id={question.id}
                initialQuestion={question.text}
                initialAnswers={[...answers[question.id]]}
              />
            ))
          : t(`lesson.tests.empty`)}
      </StyledQuestionsEditorContainer>
      <AddQuestionEditor
        lesson_id={lesson_id}
        course_id={course_id}
        questions={questions}
        setQuestions={setQuestions}
        answers={answers}
        setAnswers={setAnswers}
      />
    </StyledQuestionsContainer>
  );
};

import { Navigation } from "../components/Navigation";
import { useAuth } from "../context/AuthContext";
import { Loader } from "../components/Loader";
import { Navigate, Outlet } from "react-router-dom";
import { getItem } from "./storage";
import { ROUTES } from "../constants/constants";

export const ProtectedRoutes = () => {
  const {user, isFetchingUserInfo} = useAuth();
  const token = getItem('accessToken');

  if (!user && isFetchingUserInfo && token) return <Loader/>;

  if (user && !isFetchingUserInfo && token) return (
    <>
      <Navigation/>
      <Outlet/>
    </>
  );

  return <Navigate to={ROUTES.login} replace/>;
};
export const HelpFilled = ({ size = 28, fill = "black" }) => {
  return (
    <svg
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
      id="_Шар_1"
      data-name="Шар 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 161.26 159.83"
    >
      <path
        fill={fill}
        strokeWidth={0}
        xmlns="http://www.w3.org/2000/svg"
        d="M145.3,34C130.84,13.44,106.95,0,79.91,0,35.77,0,0,35.77,0,79.91s35.77,79.92,79.91,79.92c26.99,0,50.84-13.4,65.31-33.9,10.04-12.64,16.04-28.62,16.04-46.02s-5.97-33.29-15.96-45.91ZM88.23,125.23h-18.07v-17.39h18.07v17.39ZM100.78,83.62c-6.42,5.82-10.68,10.4-12.55,15.52h-18.07c1.38-4.49,4.66-9.11,10.11-14.27,8.97-8.32,13.83-14.26,13.83-21.81,0-8.6-5.12-13.94-13.37-13.94s-13.51,4.64-14.68,13.8h-16.44c1.21-18.6,16.98-28.32,32.57-28.32,18.19,0,29.46,13.21,29.46,25.44,0,8.2-.77,14.69-10.86,23.58Z"
      />
    </svg>
  );
};

import { createContext, useContext, useState } from "react";
import { coursesApi } from "../api/coursesApi";
import { useSelector } from "react-redux";
import { studyApi } from "../api/studyApi";

const LessonContext = createContext();

export const LessonProvider = ({ children }) => {
  const [lessons, setLessons] = useState([]);
  const selectedCourse = useSelector((state) => state.selectedCourse);
  const getLessonData = async () => {
    try {
      const newLessons = await coursesApi.getLessons({ course_id: selectedCourse });
      setLessons(newLessons);
    } catch (err) {
      console.log(err);
    }
  };

  const getLessonEducationId = async ({ lesson_id, course_education_id }) => {
    try {
      let newLessonEducationId;
      const lessonEducationList = await studyApi.getLessonEducationList({ course_education_id });
      const targetLesson = lessonEducationList.find((item) => item.lesson_id === lesson_id);
      if (!targetLesson) {
        const newLessonEducation = await studyApi.createLessonEducation({ course_education_id });
        newLessonEducationId = newLessonEducation.lesson_education_id;
        lessonEducationList.push(newLessonEducation);
      } else {
        newLessonEducationId = targetLesson.lesson_education_id;
      }
      return { newLessonEducationId, newLessonEducationArray: lessonEducationList };
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return (
    <LessonContext.Provider value={{ lessons, setLessons, getLessonData, getLessonEducationId }}>
      {children}
    </LessonContext.Provider>
  );
};

export const useLessonContext = () => {
  return useContext(LessonContext);
};

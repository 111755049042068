export const StudyFilled = ({ fill = "black", size = 28 }) => {
  return (
    <svg
      style={{ width: `${size}px`, height: `${size}px` }}
      id="_Шар_1"
      data-name="Шар 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 178.7 185.01"
    >
      <path
        strokeWidth={0}
        fill={fill}
        d="M172.48,2.31c-3.93-2.6-8.87-3.03-13.21-1.17l-13.11,5.63v115.9l-15.43,6.63V13.39l-41.38,17.77L19.42,1.14C15.08-.72,10.14-.29,6.21,2.31,2.32,4.87,0,9.16,0,13.8v124.32c0,5.51,3.29,10.48,8.38,12.66l77.71,33.37c.95.41,1.96.61,2.96.61.79,0,1.57-.13,2.32-.38h.03c.67-.1,1.33-.29,1.97-.56l76.94-33.04c5.09-2.18,8.38-7.15,8.38-12.66V13.8c0-4.64-2.32-8.93-6.21-11.49Z"
      />
    </svg>
  );
};

import { ContentBlock } from "../../../components/ContentBlock";
import { Title } from "../../../components/Title";
import { Button } from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";

const StyledRow = styled("div")`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 850px) {
    gap: 8px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 430px;
  width: 100%;
`;

const StyledButtonContainer = styled("div")`
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
`;

const StyledText = styled("span")`
  @media (max-width: 850px) {
    font-size: 0.9rem;
  }
`;

export const AccountManagement = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <ContentBlock width="100%">
        <Title value={t(`account.accountManagement`)} />
        <StyledRow>
          <StyledText>{t(`account.downloadMyData`)}</StyledText>
          <StyledButtonContainer>
            <Button title={t(`button.download`)} isFilled />
          </StyledButtonContainer>
        </StyledRow>
        <StyledRow>
          <StyledText>{t(`account.deleteMyAccount`)}</StyledText>
          <StyledButtonContainer>
            <Button title={t(`button.delete`)} />
          </StyledButtonContainer>
        </StyledRow>
      </ContentBlock>
    </StyledContainer>
  );
};

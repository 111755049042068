import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import enTranslations from "../locales/en.json";
import ukTranslations from "../locales/uk.json";
import { LANGUAGES } from "../constants/constants";

const resources = {
  en: {
    translation: enTranslations,
  },
  uk: {
    translation: ukTranslations,
  },
};

export const fallbackLng = LANGUAGES.en;

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .use(LanguageDetector) // Use the language detector
  .init({
    resources,
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"], // Cache the language in localStorage
      checkWhitelist: true, // Only check languages in the whitelist
    },
    fallbackLng,
    whitelist: Object.keys(resources),
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

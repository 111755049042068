import { styled } from "@mui/system";
import { Checkbox } from "../../../../components/Checkbox";
import { useTranslation } from "react-i18next";
import { Checkmark as IconCheckmark } from "../../../../components/icons/Checkmark";
import { Cross as IconCross } from "../../../../components/icons/Cross";

const StyledQuizHeaderTitle = styled("div")`
  font-size: 24px;
  font-weight: 500;
`;

const StyledQuizContent = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 30px;
`;

const StyledAnswer = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledQuestion = styled("div")`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  margin-left: -3px;
`;

const StyledQuizResult = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledQuestionBlock = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const QuizResult = ({ score, questions, userAnswers, answers }) => {
  const { t } = useTranslation();

  const RenderResultIcon = ({ question, answer }) => {
    if (!answer.is_correct && userAnswers[question.id].includes(answer.id)) return <IconCross />;
    if (answer.is_correct && userAnswers[question.id].includes(answer.id)) return <IconCheckmark />;
  };

  return (
    <>
      <StyledQuizHeaderTitle>
        {`${score === questions.length ? t("lesson.tests.result.success") : t("lesson.tests.result.fail")}. ${t(
          "lesson.tests.result.score"
        )} - ${score}/${questions.length}`}
      </StyledQuizHeaderTitle>
      <StyledQuizContent>
        {questions?.map((question) => (
          <StyledQuestionBlock key={question.id}>
            <StyledQuestion>{question.text}</StyledQuestion>
            <StyledQuizResult>
              {answers[question.id]?.map((answer) => (
                <StyledAnswer id={answer.id} key={answer.id}>
                  <Checkbox checked={userAnswers[question.id].includes(answer.id)} readOnly={true} />
                  <p id={answer.id}>{answer.text}</p>
                  <RenderResultIcon question={question} answer={answer} />
                </StyledAnswer>
              ))}
            </StyledQuizResult>
          </StyledQuestionBlock>
        ))}
      </StyledQuizContent>
    </>
  );
};

import { Button } from "../../../components/Button";
import { styled } from "@mui/system";

const StyledContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 160px;
  padding-top: 80px;

  @media (max-width: 850px) {
    gap: 80px;
  }
`;

const StyledSection = styled('div')`
  max-width: 1200px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;

  @media (max-width: 1100px) {
    gap: 50px;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    gap: 20px;

    &:first-of-type,
    &:last-of-type {
      flex-direction: column-reverse;
    }
  }
`;

const StyledSectionInfo = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  max-width: 520px;
  width: 100%;

  @media (max-width: 1100px) {
    gap: 15px;
  }

  @media (max-width: 850px) {
    align-items: center;
    text-align: center;
  }
`;

const StyledImageContainer = styled('div')`
  width: 416px;
  height: 416px;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  overflow: hidden;

  @media (max-width: 1100px) {
    width: 316px;
    height: 316px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const StyledTitle = styled('div')`
  font-size: 3rem;
  font-weight: 700;

  @media (max-width: 1100px) {
    font-size: 2.5rem;
  }

  @media (max-width: 850px) {
    font-size: 1.8rem;
  }
`;

const StyledDescription = styled('div')`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 150%; /* 36px */

  @media (max-width: 1100px) {
    font-size: 1.2rem;
  }

  @media (max-width: 850px) {
    font-size: 0.9rem;
  }
`;

export const IntroContent = () => {
  const tempImageUrl = "https://picsum.photos/seed/picsum/1000";

  return (
    <StyledContainer>
      <StyledSection>
        <StyledSectionInfo>
          <StyledTitle>Ефективний шлях опанування ІТ навичок</StyledTitle>
          <StyledDescription>
            Розроблено досвідченими ІТ спеціалістами. Ми також навчались, тож знаємо що тобі потрібно
          </StyledDescription>
          <Button title="Спробувати" isFilled/>
        </StyledSectionInfo>
        <StyledImageContainer>
          <img src={tempImageUrl} alt="placeholder"/>
        </StyledImageContainer>
      </StyledSection>

      <StyledSection>
        <StyledImageContainer>
          <img src={tempImageUrl} alt="placeholder"/>
        </StyledImageContainer>
        <StyledSectionInfo>
          <StyledTitle>
            Знайомство з Python для вашої цифрової трансформації
          </StyledTitle>
          <StyledDescription>
            Запустіть свій шлях до цифрової майстерності з курсом "Знайомство з Python".
          </StyledDescription>
          <Button title="Спробувати" isFilled/>
        </StyledSectionInfo>
      </StyledSection>

      <StyledSection>
        <StyledSectionInfo>
          <StyledTitle>Чекаєш на знак щоб розпочати? Це він!</StyledTitle>
          <StyledDescription>
            Великий шлях починається
            <br/> з першого кроку
          </StyledDescription>
          <Button title="Спробувати" isFilled/>
        </StyledSectionInfo>
        <StyledImageContainer>
          <img src={tempImageUrl} alt="placeholder"/>
        </StyledImageContainer>
      </StyledSection>

    </StyledContainer>
  );
};

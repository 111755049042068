import { coursesApi } from "../../../../api/coursesApi";
import { BUTTON_STATUSES } from "../../../../constants/constants";
import React, { useRef, useState } from "react";
import { TextEditor } from "../../Editors/TextEditor";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { Button } from "../../../../components/Button";

const StyledButtonContainer = styled("div")`
  margin-top: 12px;
  align-self: flex-start;
`;

export const TheoryEditor = ({ editorContent, setEditorContent, selectedCourse, selectedLesson }) => {
  const [statusText, setStatusText] = useState(`button.${BUTTON_STATUSES.save}`);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const editorRef = useRef();
  const { t, i18n } = useTranslation();
  const current_lang = i18n.language;

  const handleSave = async () => {
    setIsButtonLoading(true);
    const newLessonData = await coursesApi.getLessonTranslations({
      course_id: selectedCourse,
      lesson_id: selectedLesson,
    });
    const current_translation = newLessonData.find((data) => data.lang === current_lang);
    const translation_id = current_translation.id;
    const translation_title = current_translation.title;
    try {
      await coursesApi.updateLessonTranslation({
        course_id: selectedCourse,
        lesson_id: selectedLesson,
        translation_id,
        lang: current_lang,
        title: translation_title,
        text: JSON.stringify(editorRef.current.getData()),
      });
      setStatusText(`button.${BUTTON_STATUSES.saved}`);
      setTimeout(() => {
        setStatusText(`button.${BUTTON_STATUSES.save}`);
      }, 2000);
      setEditorContent(editorRef.current.getData());
    } catch (err) {
      console.log(err);
      setStatusText(`button.${BUTTON_STATUSES.error}`);
      setTimeout(() => {
        setStatusText(`button.${BUTTON_STATUSES.save}`);
      }, 2000);
    } finally {
      setIsButtonLoading(false);
    }
  };

  return (
    <>
      <TextEditor editorContent={editorContent} setEditorContent={setEditorContent} ref={editorRef} />
      <StyledButtonContainer>
        <Button isLoading={isButtonLoading} title={t(statusText)} isFilled onClick={handleSave} />
      </StyledButtonContainer>
    </>
  );
};

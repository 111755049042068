import { useTranslation } from "react-i18next";
import "../Forms.css";
import { Button } from "../../../components/Button";
import { FormContainer } from "../../../components/Form/Container";
import { Form } from "../../../components/Form/Form";
import { PrimaryTitle } from "../../../components/PrimaryTitle";
import { Flex } from "../../../components/Flex";
import { useRef } from "react";

export const ConfirmationForm = ({ isLoading, handleSubmit, toggleConfirmationFormVisibility }) => {
  const { t } = useTranslation();
  const formRef = useRef();

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      toggleConfirmationFormVisibility(false);
    }
  };
  return (
    <FormContainer isPopup onClick={handleClickOutside}>
      <Form ref={formRef}>
        <PrimaryTitle value={t("form.confirmQuestion")} />
        <Flex gap={16}>
          <Button isLoading={isLoading} title={t("button.confirm")} onClick={handleSubmit} isFilled />
          <Button title={t("button.cancel")} onClick={() => toggleConfirmationFormVisibility(false)} />
        </Flex>
      </Form>
    </FormContainer>
  );
};

import { useEffect, useRef, useState } from "react";
import { FormContainer } from "../../../components/Form/Container";
import { Form } from "../../../components/Form/Form";
import { PrimaryTitle } from "../../../components/PrimaryTitle";
import { Button } from "../../../components/Button";
import { styled } from "@mui/system";
import { coursesApi } from "../../../api/coursesApi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../components/Checkbox";
import { useNavigate } from "react-router-dom";
import { LANGUAGES, ROUTES } from "../../../constants/constants";
import { Loader } from "../../../components/Loader";
import { Input } from "../../../components/Input";
import ukrainianFlag from "../../../assets/images/icons/flags/ukrainian.png";
import englishFlag from "../../../assets/images/icons/flags/english.png";
import { Select } from "../../../components/Select";
import { InputContainer } from "../../../components/InputContainer";
import { Error } from "../../../components/Error";

const StyledButtonContainer = styled("div")`
  display: flex;
  gap: 1rem;
`;

const StyledMessage = styled("div")`
  color: green;
  font-size: 0.8rem;
  width: 100%;
  padding: 5px 8px;
  border-radius: calc(var(--border-radius) / 2);
  font-weight: 500;
  background: rgba(0, 128, 0, 0.2);
`;

export const CourseTranslationManagementForm = ({ toggleCourseTranslationManagementFormVisibility }) => {
  const courseTranslationManagementFormRef = useRef();
  const selectedCourse = useSelector((state) => state.selectedCourse);
  const { i18n, t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [targetLanguage, setTargetLanguage] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const languagesArray = [
    { value: LANGUAGES.uk, label: "Українська", icon: ukrainianFlag },
    { value: LANGUAGES.en, label: "English", icon: englishFlag },
  ];

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        courseTranslationManagementFormRef.current &&
        !courseTranslationManagementFormRef.current.contains(event.target)
      ) {
        toggleCourseTranslationManagementFormVisibility(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handeLanguageSelect = (value) => {
    setTargetLanguage(value);
  };

  const handeCourseTranslate = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    try {
      const result = await coursesApi.translateCourse({ course_id: selectedCourse, target_language: targetLanguage });
      console.log(result);
      setMessage(result);
    } catch (err) {
      console.log(err);
      const errorData = err.response.data;
      if (errorData.message) {
        setError(errorData.message);
      } else if (errorData.detail) {
        setError(errorData.detail);
      } else if (errorData) {
        setError(errorData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormContainer isPopup>
      <Form ref={courseTranslationManagementFormRef}>
        <PrimaryTitle value={t("form.courseTranslationManagement")} />
        <InputContainer fullWidth title={t("form.translateInto")}>
          <Select options={languagesArray} onClick={handeLanguageSelect} />
          {error && <Error>{error}</Error>}
          {message && <StyledMessage>{message}</StyledMessage>}
        </InputContainer>
        <StyledButtonContainer>
          <Button isLoading={isLoading} title={t("button.confirm")} onClick={handeCourseTranslate} isFilled />
          <Button title={t("button.close")} onClick={() => toggleCourseTranslationManagementFormVisibility(false)} />
        </StyledButtonContainer>
      </Form>
    </FormContainer>
  );
};

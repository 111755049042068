export const LogoWithText = ({ size = 50, fill = "black", isTextVisible }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="_Шар_1"
      data-name="Шар 1"
      viewBox="0 0 1366 325.16"
      style={{
        height: `${size}px`,
      }}
    >
      <g>
        <polygon
          strokeWidth="0px"
          points="189.16 325.16 246.25 325.16 246.25 137.39 189.16 80.29 57.09 80.29 0 137.39 189.16 137.39 189.16 325.16"
        />
        <polygon
          strokeWidth="0px"
          points="57.09 268.07 57.09 162.05 0 162.05 0 268.07 57.09 325.16 160.07 325.16 163.89 325.16 164.52 325.16 164.52 268.07 57.09 268.07"
        />
        <rect strokeWidth="0px" x="109.52" width="28.16" height="101.08" />
        <rect strokeWidth="0px" x="160.93" y="29.29" width="28.16" height="73.69" />
        <rect strokeWidth="0px" x="57.18" y="29.29" width="28.16" height="73.69" />
        <rect
          strokeWidth="0px"
          x="264.32"
          y="152.66"
          width="28.16"
          height="101.08"
          transform="translate(481.6 -75.21) rotate(90)"
        />
        <rect
          strokeWidth="0px"
          x="248.72"
          y="217.76"
          width="28.16"
          height="73.69"
          transform="translate(517.41 -8.21) rotate(90)"
        />
        <rect
          strokeWidth="0px"
          x="248.72"
          y="114.01"
          width="28.16"
          height="73.69"
          transform="translate(413.66 -111.95) rotate(90)"
        />
      </g>
      <g style={{ opacity: isTextVisible ? "1" : "0", transition: "0.3s" }}>
        <path
          strokeWidth="0px"
          d="m559.19,278c-26.67,0-45.75-22.11-45.75-50.07s18.55-49.82,46.27-49.82c19.06,0,31.77,9.91,39.64,26.44h29.24c-8.39-30.5-37.63-50.58-69.65-50.58-41.17,0-71.92,33.05-71.92,75.49,0,40.16,31.77,72.69,72.17,72.69,31.27,0,58.21-18.3,69.4-50.07h-29.49c-7.36,16.27-21.86,25.93-39.91,25.93Z"
        />
        <path
          strokeWidth="0px"
          d="m758,157.77v19.83h-.52c-10.92-16-28.2-23.64-48.8-23.64-41.42,0-73.69,31.52-73.69,76.5,0,40.68,33.55,71.68,74.72,71.68,20.08,0,37.86-8.64,47.78-25.41h.52v21.86h26.42v-140.81h-26.42Zm-48.55,120.23c-26.44,0-48.04-23.13-48.04-49.57s21.61-50.33,48.04-50.33c29.22,0,49.05,22.88,49.05,50.33s-21.61,49.57-49.05,49.57Z"
        />
        <path
          strokeWidth="0px"
          d="m916.38,110.5v64.57h-.52c-11.44-14.24-29.47-21.1-48.55-21.1-41.92,0-72.42,32.8-72.42,75.74s32.28,72.44,73.19,72.44c20.58,0,38.89-8.39,49.81-23.64h.52v20.08h24.39V110.5h-26.42Zm-47.02,167.5c-26.44,0-48.04-23.13-48.04-49.57s21.1-50.33,48.04-50.33c29.73,0,49.05,22.63,49.05,51.35,0,26.69-22.38,48.55-49.05,48.55Z"
        />
        <path
          strokeWidth="0px"
          d="m1087.48,190.68c-20.21-35-63.52-46.97-99.39-26.26-32.35,18.68-45.9,62.32-23.66,100.83,18.81,32.57,64.08,46.94,98.41,27.12,26.19-15.12,39.69-44.63,34.66-75.18l-22.88,13.21c.45,17.06-8.65,32.29-22.96,40.55-18.71,10.8-42.28,7.09-59.26-10.11l101.04-58.34c-1.76-4.56-3.79-8.08-5.96-11.83Zm-105.25,52.55c-10.3-21.93-.12-47.48,18.59-58.29,19.6-11.32,46.51-6.88,60.21,12.79l-78.8,45.5Z"
        />
        <path
          strokeWidth="0px"
          d="m1192,157.65l-36.08,100.65h-.5l-36.86-100.65h-28.22l55.91,140.81h18.55l55.41-140.81h-28.22Z"
        />
        <path
          strokeWidth="0px"
          d="m1291.03,154.09c-43.21,0-77.02,31.77-77.02,74.72,0,39.66,35.33,73.46,74.99,73.46,44.98,0,76.5-35.33,77-72.94.5-44.74-36.08-75.24-74.97-75.24Zm-1.78,124.04c-26.69,0-48.8-23.38-48.8-50.07s22.86-49.82,49.3-49.82,49.82,22.13,49.82,49.57-22.63,50.33-50.33,50.33Z"
        />
        <path
          strokeWidth="0px"
          d="m445.03,110.5l-126.3,188.09h32.28l34.31-52.1h62.02v52.1h27.44V110.5h-29.74Zm2.3,110.82h-43.97l43.2-70.14h.77v70.14Z"
        />
      </g>
    </svg>
  );
};

import { createContext, useContext, useEffect, useState } from "react";
import { userApi } from "../api/userApi";
import { tokenApi } from "../api/tokenApi";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getItem } from "../utils/storage";
import { setEditorStatus, setUserRole } from "../slices/mainSlice";
import { useDispatch } from "react-redux";

const AuthContext = createContext();

const USER_ROLES = {
  admin: "admin",
  author: "author",
  teacher: "teacher",
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const location = useLocation();
  const isEditorModeEnabled = useSelector((state) => state.isEditorModeEnabled);
  const [isFetchingUserInfo, setIsFetchingUserInfo] = useState(true);
  const userRole = useSelector((state) => state.userRole);
  const dispatch = useDispatch();

  const isTeacher = () => {
    return user?.profile?.role === USER_ROLES.teacher;
  };

  const isAuthor = () => {
    return user?.profile?.role === USER_ROLES.author;
  };

  const isAdmin = () => {
    return user?.profile?.role === USER_ROLES.admin;
  };

  const hasPriviledgedPersonEnabledEditorMode = () => {
    if (isAuthor() || isAdmin()) {
      return isEditorModeEnabled;
    } else {
      return false;
    }
  };

  const isAuthenticated = () => {
    return !!user?.id && !!getItem("accessToken");
  };

  const fetchCurrentUser = async () => {
    setIsFetchingUserInfo(true);
    try {
      await tokenApi.verify();
      const currentUser = await userApi.getUserInfo();
      setUser(currentUser);
      const fetchedUserRole = currentUser.profile.role;
      if (!user || (userRole === USER_ROLES.admin && fetchedUserRole !== USER_ROLES.admin)) {
        dispatch(setUserRole(fetchedUserRole));
      }
      dispatch(setEditorStatus(fetchedUserRole === USER_ROLES.author || fetchedUserRole === USER_ROLES.admin));

      console.log(currentUser);
    } catch (err) {
      console.error("Fetching user failed:", err);
    } finally {
      setIsFetchingUserInfo(false);
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!user && accessToken) {
      fetchCurrentUser();
    }
  }, [location]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isTeacher,
        hasPriviledgedPersonEnabledEditorMode,
        isAdmin,
        isAuthor,
        isFetchingUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

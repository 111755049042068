import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { useRef, useState } from "react";
import { Title } from "../Title";
import { FormContainer } from "../Form/Container";
import { Form } from "../Form/Form";

import { Tether as IconTether } from "../../components/icons/Tether";
import { TetherQR as IconTetherQR } from "../../components/icons/TetherQR";

import { Bitcoin as IconBitcoin } from "../../components/icons/Bitcoin";
import { BitcoinQR as IconBitcoinQR } from "../../components/icons/BitcoinQR";

import { Etherium as IconEtherium } from "../../components/icons/Etherium";
import { EtheriumQR as IconEtheriumQR } from "../../components/icons/EtheriumQR";

import { QR as IconQR } from "../../components/icons/QR";

import monobank from "../../assets/images/payment/monobank.png";
import { CopyButton } from "../CopyButton";
import { useDispatch } from "react-redux";
import { setIsNavMenuPinned } from "../../slices/mainSlice";

const StyledLine = styled("div")`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 2px 0;
  padding: 6px 10px;
  border: 1px solid transparent;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  transition: 0.3s;
  width: 100%;
  font-weight: 500;
  gap: 20px;

  a {
    color: rgb(24, 95, 233);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  &:hover::before {
    box-shadow: 0 0 0 0.5px var(--black);
  }
`;

const StyledIcons = styled("div")`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledIcon = styled("img")`
  widht: 50px;
  height: 50px;
  object-fit: contain;
  overflow: hidden;
  border-radius: 50%;
`;

const StyledLineText = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    font-weight: 600;
    font-size: 16px;
  }

  @media (max-width: 850px) {
    span {
      display: none;
    }
  }
`;

export const SupportProjectPopup = ({ wasNavMenuPinned, toggleSupportProjectPopupVisibility }) => {
  const { t } = useTranslation();
  const popupRef = useRef();
  const QRPopupRef = useRef();
  const [activeQR, setActiveQR] = useState(null);
  const dispatch = useDispatch();

  const QRs = {
    etherium: "etherium",
    bitcoin: "bitcoin",
    tether: "tether",
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      if (!wasNavMenuPinned) {
        dispatch(setIsNavMenuPinned(false));
      }
      toggleSupportProjectPopupVisibility(false);
    }
  };

  const handleClickOutsideQR = (event) => {
    if (QRPopupRef.current && !QRPopupRef.current.contains(event.target)) {
      setActiveQR(null);
    }
  };

  const RenderQR = () => {
    if (activeQR === QRs.etherium) {
      return <IconEtheriumQR size={300} />;
    }
    if (activeQR === QRs.bitcoin) {
      return <IconBitcoinQR size={300} />;
    }
    if (activeQR === QRs.tether) {
      return <IconTetherQR size={300} />;
    }
  };

  const etheriumWallet = "0x76f96648cA1661801BE549b29EB080043c98fB37";
  const bitcoinWallet = "bc1qvupj9nf0d9a499gjutfj3g5md03kcrtwvsnd5e";
  const tetherWallet = "TEyBGHbfJeUDXS4JEnfpjYjEFaf7rmzd1H";

  return (
    <>
      <FormContainer onClick={handleClickOutside} onMouseOver={(e) => e.preventDefault()} isPopup>
        <Form ref={popupRef}>
          <Title value={t("donate")} />

          <StyledLine>
            <IconEtherium size={50} />
            <StyledLineText>
              Etherium
              <br />
              <span>{etheriumWallet}</span>
            </StyledLineText>
            <StyledIcons>
              <CopyButton size={24} textToCopy={etheriumWallet} />
              <IconQR onClick={() => setActiveQR(QRs.etherium)} size={20} />
            </StyledIcons>
          </StyledLine>
          <StyledLine>
            <IconBitcoin size={50} />
            <StyledLineText>
              Bitcoin (Native SegWit)
              <br />
              <span>{bitcoinWallet}</span>
            </StyledLineText>
            <StyledIcons>
              <CopyButton size={24} textToCopy={bitcoinWallet} />
              <IconQR onClick={() => setActiveQR(QRs.bitcoin)} size={20} />
            </StyledIcons>
          </StyledLine>

          <StyledLine href={"#"} target="_blank">
            <IconTether size={50} />
            <StyledLineText>
              USDT (TRC20)
              <br />
              <span>{tetherWallet}</span>
            </StyledLineText>
            <StyledIcons>
              <CopyButton size={24} textToCopy={tetherWallet} />
              <IconQR onClick={() => setActiveQR(QRs.tether)} size={20} />
            </StyledIcons>
          </StyledLine>
          <StyledLine>
            <StyledIcon src={monobank} />
            <a href={"https://send.monobank.ua/jar/AMtXnYxWdA"} target="_blank">
              Mono банка
            </a>
          </StyledLine>
        </Form>
      </FormContainer>

      {activeQR && (
        <FormContainer onClick={handleClickOutsideQR} isPopup>
          <Form ref={QRPopupRef} width="unset">
            <RenderQR />
          </Form>
        </FormContainer>
      )}
    </>
  );
};

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { Content } from "../../Content";
import { ROUTES } from "../../../../constants/constants";
import { studyApi } from "../../../../api/studyApi";
import { Button } from "../../../../components/Button";
import { setSelectedLesson } from "../../../../slices/mainSlice";
import { useDispatch } from "react-redux";

const StyledButtonsContainer = styled("div")`
  display: flex;
  margin-top: 12px;
  align-self: flex-start;
  width: 100%;
  justify-content: space-between;
`;

export const HomeworkContent = ({
  lessons,
  content,
  courseEducationId,
  lessonEducationId,
  selectedCourse,
  selectedLesson,
  areTestsEnabled,
}) => {
  const editorRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleHomeworkComplete = async () => {
    await studyApi.updateLessonEducation({
      course_education_id: courseEducationId,
      lesson_education_id: lessonEducationId,
      homework_completed: true,
    });
  };

  const RenderButtons = () => {
    const handleNext = () => {
      const currentLessonIndex = lessons.findIndex((lesson) => lesson.id === selectedLesson);
      const nextLesson = lessons[currentLessonIndex + 1];
      if (nextLesson) {
        studyApi.updateLessonEducation({
          course_education_id: courseEducationId,
          lesson_education_id: lessonEducationId,
          test_max_score: 1,
          homework_completed: true,
          scroll_completed: true,
        });
        navigate(ROUTES.study + "/" + selectedCourse + "/" + nextLesson.id + "/theory");
        dispatch(setSelectedLesson(nextLesson.id));
      } else {
        alert("Course has been completed!");
        navigate(ROUTES.study);
      }
    };

    const currentLessonIndex = lessons.findIndex((lesson) => lesson.id === selectedLesson);

    if (areTestsEnabled) {
      return (
        <Link to={ROUTES.study + "/" + selectedCourse + "/" + selectedLesson + "/tests"}>
          <Button onClick={handleHomeworkComplete} title={t("lesson.tests.title")} isFilled />
        </Link>
      );
    }
    return (
      <Button
        title={lessons[currentLessonIndex + 1] ? t("lesson.next") : t("course.finishCourse")}
        isFilled
        onClick={handleNext}
      />
    );
  };

  return (
    <>
      <Content content={content} ref={editorRef} />
      <StyledButtonsContainer>
        <Link to={ROUTES.study + "/" + selectedCourse + "/" + selectedLesson + "/theory"}>
          <Button title={t("lesson.theory")} />
        </Link>
        <RenderButtons />
      </StyledButtonsContainer>
    </>
  );
};

import { styled } from "@mui/system";

const StyledPrimaryTitle = styled('h1')`
  color: var(--black);
  font-weight: 600;
  font-size: 1.8rem;
  margin: 20px 0;
  text-align: ${props => props.align || "center"};

  @media (max-width: 850px) {
    margin: 5px 0;
    font-size: 1.5rem;
  }
`;

export const PrimaryTitle = ({value, align}) => {
  return <StyledPrimaryTitle align={align}>{value}</StyledPrimaryTitle>;
};

export const AccountFilled = ({ fill = "black", size = 28 }) => {
  return (
    <svg
      style={{ width: `${size}px`, height: `${size}px` }}
      id="_Шар_1"
      data-name="Шар 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 159.83 177.39"
    >
      <path
        fill={fill}
        strokeWidth={0}
        d="M130.93,108.85c-13.42,12.4-31.35,19.98-51.01,19.98s-37.6-7.58-51.01-19.98C11.25,125.18,0,149.82,0,177.39h159.83c0-27.57-11.24-52.21-28.9-68.54Z"
      />
      <path
        fill={fill}
        strokeWidth={0}
        d="M47.32,96.08c9.02,6.95,20.33,11.07,32.6,11.07s23.58-4.13,32.61-11.07c12.75-9.79,20.96-25.18,20.96-42.5C133.49,23.99,109.51,0,79.92,0S26.34,23.99,26.34,53.58c0,17.32,8.22,32.71,20.98,42.5Z"
      />
    </svg>
  );
};

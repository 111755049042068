export const Telegram = ({size = 30}) => {
  return (
    <svg
      style={{
        height: `${size}px`,
        width: `${size}px`
      }}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.625 0C7.138 0 0.25 6.944 0.25 15.5C0.25 24.056 7.138 31 15.625 31C24.112 31 31 24.056 31 15.5C31 6.944 24.112 0 15.625 0ZM22.759 10.54C22.5284 12.989 21.529 18.941 21.0216 21.6845C20.8064 22.847 20.3759 23.2345 19.9761 23.281C19.0844 23.3585 18.4079 22.692 17.5469 22.1185C16.1939 21.2195 15.4251 20.6615 14.1183 19.7935C12.5961 18.786 13.5801 18.228 14.4565 17.329C14.6871 17.0965 18.6231 13.485 18.7 13.1595C18.7107 13.1102 18.7093 13.059 18.6959 13.0104C18.6825 12.9618 18.6575 12.9172 18.6231 12.8805C18.5309 12.803 18.4079 12.834 18.3002 12.8495C18.1619 12.8805 16.0094 14.322 11.812 17.174C11.197 17.5925 10.6435 17.8095 10.1515 17.794C9.598 17.7785 8.5525 17.484 7.76837 17.2205C6.79975 16.9105 6.04637 16.74 6.10787 16.1975C6.13863 15.9185 6.523 15.6395 7.24563 15.345C11.7351 13.3765 14.7179 12.0745 16.2093 11.4545C20.4835 9.6565 21.3599 9.3465 21.9441 9.3465C22.0671 9.3465 22.3593 9.3775 22.5438 9.5325C22.6975 9.6565 22.7436 9.827 22.759 9.951C22.7436 10.044 22.7744 10.323 22.759 10.54Z"
        fill="black"
      />
    </svg>
  )
}
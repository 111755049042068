import { styled } from "@mui/system";
import { useState } from "react";
import { Dropdown as IconDropdown } from "../../../components/icons/Dropdown";

const StyledFAQItem = styled("div")`
  margin: 0 0 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  box-shadow: 0 0 5px 2px var(--content-block-shadow-color);
  background: var(--white);
  padding: 16px;
  border-radius: var(--border-radius);
`;

const StyledQuestion = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const StyledAnswer = styled("div")`
  padding: 1rem 0.5rem 0 0.5rem;
  display: ${(props) => (props.isExpanded ? "block" : "none")};
`;

const StyledIconDropdownWrapper = styled("div")`
  padding: 0;
  transform: ${(props) => (props.isExpanded ? "rotate(0deg)" : "rotate(180deg)")};

  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FAQItemContent = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <StyledFAQItem>
      <StyledQuestion onClick={() => setIsExpanded(!isExpanded)}>
        <strong>{question}</strong>
        <StyledIconDropdownWrapper isExpanded={isExpanded}>
          <IconDropdown />
        </StyledIconDropdownWrapper>
      </StyledQuestion>
      <StyledAnswer isExpanded={isExpanded}>{answer}</StyledAnswer>
    </StyledFAQItem>
  );
};

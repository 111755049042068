export const Tether = ({ fill = "black", size = 50 }) => {
  return (
    <svg
      style={{
        width: `${size}px`,
        height: `${size}px`,
        flexShrink: 0,
      }}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 88C19.6983 88 0 68.3017 0 44C0 19.6983 19.6983 0 44 0C68.3017 0 88 19.6983 88 44C88 68.3017 68.3017 88 44 88ZM49.2855 37.9308V31.4243H64.174V21.5022H23.6363V31.4243H38.5247V37.928C26.4247 38.4835 17.325 40.8815 17.325 43.7525C17.325 46.6235 26.4247 49.0187 38.5247 49.577V70.4275H49.2855V49.5715C61.3663 49.016 70.444 46.6207 70.444 43.7525C70.444 40.8842 61.3663 38.489 49.2855 37.9308ZM49.2855 47.8032V47.7978C48.983 47.8198 47.4237 47.9133 43.945 47.9133C41.1675 47.9133 39.2122 47.8308 38.5247 47.7978V47.806C27.8327 47.3358 19.8522 45.474 19.8522 43.2465C19.8522 41.0217 27.8327 39.16 38.5247 38.6815V45.9525C39.2232 46.002 41.2252 46.1202 43.9917 46.1202C47.311 46.1202 48.9748 45.9828 49.2855 45.9553V38.687C59.9555 39.1627 67.9168 41.0245 67.9168 43.2465C67.9168 45.474 59.9555 47.3302 49.2855 47.8032Z"
        fill={fill}
      />
    </svg>
  );
};
